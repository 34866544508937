// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Case from "../../bs-case/src/case.mjs";
import * as List from "rescript/lib/es6/list.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as $$Buffer from "rescript/lib/es6/buffer.js";
import * as Format from "rescript/lib/es6/format.js";
import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Printf from "rescript/lib/es6/printf.js";
import * as AvoModel from "../../app/src/avoModel.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as LinkUrls from "../../shared/utils/LinkUrls.mjs";
import * as Printing from "./Printing.mjs";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as AvoFormat from "../../app/src/AvoFormat.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_array from "rescript/lib/es6/caml_array.js";
import * as ModelUtils from "../../app/src/ModelUtils.mjs";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.mjs";
import * as CodegenRules from "./CodegenRules.mjs";
import * as PropertyUtils from "../../app/src/PropertyUtils.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as QuillToPlainText from "../../shared/utils/QuillToPlainText.mjs";
import * as ModelUtils_mapped from "../../app/src/ModelUtils_mapped.mjs";
import * as TrackingPlanModel from "../../model/src/TrackingPlanModel.mjs";
import * as BeltListExtensions from "../../app/src/BeltListExtensions.mjs";
import * as FlattenVariantsUseCase from "../../model/src/variants/flattenVariantsUseCase.mjs";
import * as EventCodegenNameUseCase from "./EventCodegenNameUseCase.mjs";
import * as TrackingPlanMappedModel from "../../model/src/TrackingPlanMappedModel.mjs";
import * as GetRegexValidationUseCase from "../../app/src/regex/GetRegexValidationUseCase.mjs";
import * as PropertyCodegenNameUseCase from "./PropertyCodegenNameUseCase.mjs";
import * as GetMostSpecificRegexUseCase from "../../app/src/regex/GetMostSpecificRegexUseCase.mjs";
import * as ComputeUnionAllowedValuesUseCase from "../../model/src/eventSpecificPropertyValues/ComputeUnionAllowedValuesUseCase.mjs";
import * as CopyAllowedValuesToMatchesUseCase from "./CopyAllowedValuesToMatchesUseCase.mjs";
import * as FilterEmptyPropertyValidationsUseCase from "../../model/src/base/FilterEmptyPropertyValidationsUseCase.mjs";
import * as GetEventSpecificPropertyValuesUseCase from "../../model/src/eventSpecificPropertyValues/GetEventSpecificPropertyValuesUseCase.mjs";
import * as ValidatePinnedValueWithPropertyValuesUseCase from "../../model/src/eventSpecificPropertyValues/ValidatePinnedValueWithPropertyValuesUseCase.mjs";
import * as ClearAllowedValuesForPropertiesThatHaveRegexUseCase from "../../app/src/regex/ClearAllowedValuesForPropertiesThatHaveRegexUseCase.mjs";
import * as EnrichWithVirtualPropertiesWithMatchesForEventSpecificAllowedValuesUseCase from "./propertyAllowedValues/EnrichWithVirtualPropertiesWithMatchesForEventSpecificAllowedValuesUseCase.mjs";

function hasShapeValidation(property) {
  return Belt_List.some(property.validations, (function (validation) {
                if (typeof validation === "object" && validation.NAME === "Shape" && validation.VAL) {
                  return true;
                } else {
                  return false;
                }
              }));
}

function hasMatchValidation(property) {
  return Belt_List.some(property.validations, (function (validation) {
                if (typeof validation === "object" && validation.NAME === "Matches" && validation.VAL) {
                  return true;
                } else {
                  return false;
                }
              }));
}

function hasNestedPropertyValidation(property) {
  return Belt_List.some(property.validations, (function (validation) {
                if (typeof validation === "object" && validation.NAME === "NestedProperty" && validation.VAL) {
                  return true;
                } else {
                  return false;
                }
              }));
}

function nameMappingString(nameMaps) {
  if (nameMaps.length !== 0) {
    return "// Sent as [" + (Belt_Array.map(nameMaps, (function (param) {
                      return "\"" + (param[1] + ("\" to " + Case.pascal(param[0])));
                    })).join(", ") + "]");
  } else {
    return "";
  }
}

function shouldSendEventToSourceDestination($$event, source, destinationId) {
  return Belt_List.some($$event.includeDestinations, (function (sourceDestinationMap) {
                var match = sourceDestinationMap.VAL;
                if (match[0] === source.id) {
                  return Belt_List.some(match[1], (function (id) {
                                return id === destinationId;
                              }));
                } else {
                  return false;
                }
              }));
}

function shouldSendEventToDestinationType(model, $$event, destinationType) {
  var destinations = AvoModel.resolveDestinations(model, BeltListExtensions.dedupeString(BeltListExtensions.flatMap($$event.includeDestinations, (function (x) {
                  var match = x.VAL;
                  var sourceId = match[0];
                  if (Belt_List.some($$event.includeSources, (function (includedSource) {
                            return includedSource.id === sourceId;
                          }))) {
                    return match[1];
                  } else {
                    return /* [] */0;
                  }
                }))));
  var destinations$1 = AvoConfig.getValidDestinations(model.sources, destinations);
  return Belt_List.some(destinations$1, (function (destination) {
                return destination.type_ === destinationType;
              }));
}

function prefixWithWordIfFirstNumber(s) {
  var match = s.charAt(0);
  var prefix;
  switch (match) {
    case "0" :
        prefix = "Zero";
        break;
    case "1" :
        prefix = "One";
        break;
    case "2" :
        prefix = "Two";
        break;
    case "3" :
        prefix = "Three";
        break;
    case "4" :
        prefix = "Four";
        break;
    case "5" :
        prefix = "Five";
        break;
    case "6" :
        prefix = "Six";
        break;
    case "7" :
        prefix = "Seven";
        break;
    case "8" :
        prefix = "Eight";
        break;
    case "9" :
        prefix = "Nine";
        break;
    default:
      prefix = "";
  }
  if (prefix === "") {
    return s;
  } else {
    return prefix + s.substr(1);
  }
}

function prefixWithWordIfFirstNumberWithCase(s, $$case) {
  var newString = prefixWithWordIfFirstNumber(s);
  if (newString.charAt(0) !== s.charAt(0)) {
    return Curry._1($$case, newString.charAt(0)) + newString.slice(1);
  } else {
    return newString;
  }
}

function propertyCodegenName(property) {
  return prefixWithWordIfFirstNumber(PropertyCodegenNameUseCase.get(property));
}

function printApiKey(apiKey, language) {
  if (apiKey !== "") {
    if (language === "objc") {
      return "@\"" + (apiKey + "\"");
    } else if (language === "re" || language === "re_v2") {
      return "Some(\"" + (apiKey + "\")");
    } else if (language === "php") {
      return "'" + (apiKey + "'");
    } else {
      return "\"" + (apiKey + "\"");
    }
  } else if (language === "swift" || language === "rb" || language === "go" || language === "objc") {
    return "nil";
  } else if (language === "py3" || language === "re" || language === "py" || language === "re_v2") {
    return "None";
  } else {
    return "null";
  }
}

function prefixWithUnderscoreIfFirstNumber(s) {
  if (Caml_option.null_to_opt(s.match(/^\d/)) !== undefined) {
    return Curry._1(Printf.sprintf(/* Format */{
                    _0: {
                      TAG: /* Char_literal */12,
                      _0: /* '_' */95,
                      _1: {
                        TAG: /* String */2,
                        _0: /* No_padding */0,
                        _1: /* End_of_format */0
                      }
                    },
                    _1: "_%s"
                  }), s);
  } else {
    return s;
  }
}

function propertyDocsFormat(property) {
  return property.name;
}

function eventDocsFormat($$event) {
  return $$event.name;
}

function eventLink(schemaId, branchId, eventId) {
  if (branchId === "master") {
    return Curry._2(Printf.sprintf(/* Format */{
                    _0: {
                      TAG: /* String_literal */11,
                      _0: "https://www.avo.app/schemas/",
                      _1: {
                        TAG: /* String */2,
                        _0: /* No_padding */0,
                        _1: {
                          TAG: /* String_literal */11,
                          _0: "/events/",
                          _1: {
                            TAG: /* String */2,
                            _0: /* No_padding */0,
                            _1: /* End_of_format */0
                          }
                        }
                      }
                    },
                    _1: "https://www.avo.app/schemas/%s/events/%s"
                  }), schemaId, eventId);
  } else {
    return Curry._3(Printf.sprintf(/* Format */{
                    _0: {
                      TAG: /* String_literal */11,
                      _0: "https://www.avo.app/schemas/",
                      _1: {
                        TAG: /* String */2,
                        _0: /* No_padding */0,
                        _1: {
                          TAG: /* String_literal */11,
                          _0: "/branches/",
                          _1: {
                            TAG: /* String */2,
                            _0: /* No_padding */0,
                            _1: {
                              TAG: /* String_literal */11,
                              _0: "/events/",
                              _1: {
                                TAG: /* String */2,
                                _0: /* No_padding */0,
                                _1: /* End_of_format */0
                              }
                            }
                          }
                        }
                      }
                    },
                    _1: "https://www.avo.app/schemas/%s/branches/%s/events/%s"
                  }), schemaId, branchId, eventId);
  }
}

function propertyGroupLink(schemaId, branchId, propertyGroupId) {
  if (branchId === "master") {
    return Curry._2(Printf.sprintf(/* Format */{
                    _0: {
                      TAG: /* String_literal */11,
                      _0: "https://www.avo.app/schemas/",
                      _1: {
                        TAG: /* String */2,
                        _0: /* No_padding */0,
                        _1: {
                          TAG: /* String_literal */11,
                          _0: "/properties/group/",
                          _1: {
                            TAG: /* String */2,
                            _0: /* No_padding */0,
                            _1: /* End_of_format */0
                          }
                        }
                      }
                    },
                    _1: "https://www.avo.app/schemas/%s/properties/group/%s"
                  }), schemaId, propertyGroupId);
  } else {
    return Curry._3(Printf.sprintf(/* Format */{
                    _0: {
                      TAG: /* String_literal */11,
                      _0: "https://www.avo.app/schemas/",
                      _1: {
                        TAG: /* String */2,
                        _0: /* No_padding */0,
                        _1: {
                          TAG: /* String_literal */11,
                          _0: "/branches/",
                          _1: {
                            TAG: /* String */2,
                            _0: /* No_padding */0,
                            _1: {
                              TAG: /* String_literal */11,
                              _0: "/properties/group/",
                              _1: {
                                TAG: /* String */2,
                                _0: /* No_padding */0,
                                _1: /* End_of_format */0
                              }
                            }
                          }
                        }
                      }
                    },
                    _1: "https://www.avo.app/schemas/%s/branches/%s/properties/group/%s"
                  }), schemaId, branchId, propertyGroupId);
  }
}

function stringReplaceByRe(str, re, replacement) {
  return str.replace(re, replacement);
}

function stripTrailingWhiteSpace(str) {
  return str.replace(/[ \t]+$/gm, "");
}

function escapeSingleQuotes(str) {
  return str.replace(/'/g, "\\'");
}

function escapeDoubleQuotes(str) {
  return str.replace(/"/g, "\\\"");
}

function commentWithSpace(comment) {
  if (comment === "") {
    return "";
  } else {
    return " " + comment;
  }
}

function printModuleMap(moduleName) {
  return Curry._1(Printf.sprintf(/* Format */{
                  _0: {
                    TAG: /* String_literal */11,
                    _0: "AVOMODULEMAP:",
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: /* End_of_format */0
                    }
                  },
                  _1: "AVOMODULEMAP:%s"
                }), JSON.stringify(moduleName));
}

function printEventMap(format, events) {
  return Curry._1(Printf.sprintf(/* Format */{
                  _0: {
                    TAG: /* String_literal */11,
                    _0: "AVOEVENTMAP:[",
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* Char_literal */12,
                        _0: /* ']' */93,
                        _1: /* End_of_format */0
                      }
                    }
                  },
                  _1: "AVOEVENTMAP:[%s]"
                }), Belt_List.toArray(Belt_List.map(events, (function ($$event) {
                          return Curry._1(Printf.sprintf(/* Format */{
                                          _0: {
                                            TAG: /* Char_literal */12,
                                            _0: /* '"' */34,
                                            _1: {
                                              TAG: /* String */2,
                                              _0: /* No_padding */0,
                                              _1: {
                                                TAG: /* Char_literal */12,
                                                _0: /* '"' */34,
                                                _1: /* End_of_format */0
                                              }
                                            }
                                          },
                                          _1: "\"%s\""
                                        }), Curry._1(format, EventCodegenNameUseCase.get($$event)));
                        }))).join(","));
}

function classifyProperties(properties) {
  var eventProperties = Belt_List.keepU(properties, (function (prop) {
          return prop.sendAs === /* EventProperty */1;
        }));
  var userProperties = Belt_List.keepU(properties, (function (prop) {
          if (prop.sendAs === /* UserProperty */2) {
            return prop.operation === "Set";
          } else {
            return false;
          }
        }));
  var userPropertiesAlternateOps = Belt_List.keepU(properties, (function (prop) {
          if (prop.sendAs === /* UserProperty */2) {
            return prop.operation !== "Set";
          } else {
            return false;
          }
        }));
  var groupProperties = Belt_List.keepU(properties, (function (prop) {
          var match = prop.sendAs;
          if (typeof match === "number") {
            return false;
          } else {
            return true;
          }
        }));
  return [
          eventProperties,
          userProperties,
          userPropertiesAlternateOps,
          groupProperties
        ];
}

function filterOutGroupProperties(properties) {
  return Belt_List.keepU(properties, (function (prop) {
                var match = prop.sendAs;
                if (typeof match === "number") {
                  return true;
                } else {
                  return false;
                }
              }));
}

function classifyGroupPropertiesByGroupType(groupProperties) {
  return Belt_MapString.keepU(Belt_List.reduceU(groupProperties, undefined, (function (acc, groupProperty) {
                    var groupTypeId = groupProperty.sendAs;
                    if (typeof groupTypeId === "number") {
                      return acc;
                    } else {
                      return Belt_MapString.update(acc, groupTypeId._0, (function (maybeGroupProperties) {
                                    if (maybeGroupProperties !== undefined) {
                                      return Belt_Array.concat(maybeGroupProperties, [groupProperty]);
                                    } else {
                                      return [groupProperty];
                                    }
                                  }));
                    }
                  })), (function (_key, value) {
                return Caml_obj.caml_notequal(value, []);
              }));
}

function filterPinnedProperties(propertyRefs, globalProperties, model) {
  return Belt_List.keepU(propertyRefs, (function (propertyRef) {
                if (propertyRef.TAG !== /* PropertyRef */0) {
                  return true;
                }
                var match = propertyRef._0;
                var pinnedValue = match.pinnedValue;
                if (pinnedValue === undefined) {
                  return true;
                }
                var id = match.id;
                return Belt_Option.mapWithDefault(Belt_List.head(Belt_List.keepMapU(globalProperties, (function (property) {
                                      if (property.TAG === /* PropertyRef */0) {
                                        return ;
                                      }
                                      var property$1 = property._0;
                                      if (property$1.id === id) {
                                        return property$1;
                                      }
                                      
                                    }))), true, (function (property) {
                              return !ValidatePinnedValueWithPropertyValuesUseCase.validate(pinnedValue, property, ModelUtils.hasMigrated(model, "EventSpecificAllowedPropertyValues"));
                            }));
              }));
}

function getPinnedProperties(propertyRefs, globalProperties, model) {
  return Belt_List.partitionU(Belt_List.keepMapU(propertyRefs, (function (propertyRef) {
                    if (propertyRef.TAG !== /* PropertyRef */0) {
                      return ;
                    }
                    var propertyRef$1 = propertyRef._0;
                    var pinnedValue = propertyRef$1.pinnedValue;
                    if (pinnedValue === undefined) {
                      return ;
                    }
                    var id = propertyRef$1.id;
                    return Belt_Option.flatMap(Belt_List.head(Belt_List.keepMapU(globalProperties, (function (property) {
                                          if (property.TAG === /* PropertyRef */0) {
                                            return ;
                                          }
                                          var property$1 = property._0;
                                          if (property$1.id === id) {
                                            return property$1;
                                          }
                                          
                                        }))), (function (property) {
                                  if (ValidatePinnedValueWithPropertyValuesUseCase.validate(pinnedValue, property, ModelUtils.hasMigrated(model, "EventSpecificAllowedPropertyValues"))) {
                                    return [
                                            propertyRef$1,
                                            property
                                          ];
                                  }
                                  
                                }));
                  })), (function (param) {
                return param[1].sendAs !== /* UserProperty */2;
              }));
}

function printPinnedValueOption(pinnedValue) {
  if (pinnedValue === undefined) {
    return ;
  }
  var variant = pinnedValue.NAME;
  if (variant === "BooleanLit") {
    return Pervasives.string_of_bool(pinnedValue.VAL);
  } else if (variant === "StringLit") {
    return Curry._1(Printf.sprintf(/* Format */{
                    _0: {
                      TAG: /* Char_literal */12,
                      _0: /* '"' */34,
                      _1: {
                        TAG: /* String */2,
                        _0: /* No_padding */0,
                        _1: {
                          TAG: /* Char_literal */12,
                          _0: /* '"' */34,
                          _1: /* End_of_format */0
                        }
                      }
                    },
                    _1: "\"%s\""
                  }), pinnedValue.VAL);
  } else if (variant === "FloatLit") {
    return pinnedValue.VAL.toString();
  } else {
    return String(pinnedValue.VAL);
  }
}

function printPinnedValueExn(pinnedValue) {
  var value = printPinnedValueOption(pinnedValue);
  if (value !== undefined) {
    return value;
  } else {
    return Pervasives.failwith("Invalid pinned value");
  }
}

function enrichWithRef(properties, $$event) {
  return Belt_List.keepMap(properties, (function (property) {
                return Belt_Option.flatMap(Belt_List.getBy($$event.directPropertyRefs, (function (x) {
                                  if (x.TAG === /* PropertyRef */0) {
                                    return property.id === x._0.id;
                                  } else {
                                    return false;
                                  }
                                })), (function (x) {
                              if (x.TAG === /* PropertyRef */0) {
                                return [
                                        x._0,
                                        property
                                      ];
                              }
                              
                            }));
              }));
}

function getSnowplowSchemaPropertyRef(propsWithRefs) {
  return Belt_List.getByU(propsWithRefs, (function (param) {
                if (param[1].name === "schema") {
                  return param[0].pinnedValue !== undefined;
                } else {
                  return false;
                }
              }));
}

function unsafeGetSnowplowSchemaPropertyRef(eventName, propsWithRefs) {
  var match = getSnowplowSchemaPropertyRef(propsWithRefs);
  if (match !== undefined) {
    return match[0];
  } else {
    return Pervasives.failwith("Snowplow requires a pinned 'schema' property at the root of '" + (eventName + "'"));
  }
}

function printingFunctionToString(func) {
  var buf = $$Buffer.create(128);
  var testFormatter = Format.formatter_of_buffer(buf);
  Curry._1(func, testFormatter);
  return stripTrailingWhiteSpace(Printing.flush_str_formatter(buf, testFormatter));
}

function printingFunctionToStringAvoFormat(func) {
  var testFormatter = AvoFormat.newTextBuilder(undefined);
  Curry._1(func, testFormatter);
  return stripTrailingWhiteSpace(AvoFormat.buildText(testFormatter));
}

function enrich(serverTrackingWithDeviceIdOpt, eventSpecificPropertyValues, omitUserIdInSetUserPropertiesOnCustomDestinationOpt, skipImplementWithAvoFilteringOpt, removeUnusedEnumsOpt, model, source, destinations) {
  var serverTrackingWithDeviceId = serverTrackingWithDeviceIdOpt !== undefined ? serverTrackingWithDeviceIdOpt : false;
  var omitUserIdInSetUserPropertiesOnCustomDestination = omitUserIdInSetUserPropertiesOnCustomDestinationOpt !== undefined ? omitUserIdInSetUserPropertiesOnCustomDestinationOpt : false;
  var skipImplementWithAvoFiltering = skipImplementWithAvoFilteringOpt !== undefined ? skipImplementWithAvoFilteringOpt : false;
  var removeUnusedEnums = removeUnusedEnumsOpt !== undefined ? removeUnusedEnumsOpt : false;
  var model$1 = FlattenVariantsUseCase.flatten(model);
  var model$2 = FilterEmptyPropertyValidationsUseCase.filterEmptyValidations(model$1);
  var match = source.language;
  var match$1;
  if (match === "java") {
    var destinations$1 = AvoConfig.setDestinationModeForCodegen(source, destinations);
    var model$3 = AvoConfig.convertDestinationInterfacesToCustomInModel(model$2, source);
    var model$4 = AvoModel.filterExcludedEventsFromModel(model$3, source);
    var model$5 = AvoModel.filterUnusedPropertyBundles(model$4);
    var model$6 = AvoModel.enrichModel(model$5, {
          hd: source,
          tl: /* [] */0
        });
    var model$7 = AvoModel.enrichModelForCodegen(undefined, undefined, skipImplementWithAvoFiltering, model$6, source);
    var model$8 = AvoModel.enrichPropertyBundlesForCodegen(model$7, undefined, {
          hd: source.id,
          tl: /* [] */0
        });
    match$1 = [
      model$8,
      destinations$1
    ];
  } else if (match === "objc") {
    var destinations$2 = AvoConfig.setDestinationModeForCodegen(source, destinations);
    var model$9 = AvoConfig.convertDestinationInterfacesToCustomInModel(model$2, source);
    var model$10 = AvoModel.filterExcludedEventsFromModel(model$9, source);
    var model$11 = AvoModel.filterUnusedPropertyBundles(model$10);
    var model$12 = AvoModel.enrichModel(model$11, {
          hd: source,
          tl: /* [] */0
        });
    var model$13 = AvoModel.enrichModelForCodegen(undefined, undefined, skipImplementWithAvoFiltering, model$12, source);
    var model$14 = AvoModel.enrichPropertyBundlesForCodegen(model$13, undefined, {
          hd: source.id,
          tl: /* [] */0
        });
    match$1 = [
      model$14,
      destinations$2
    ];
  } else if (match === "js_v2") {
    var destinations$3 = AvoConfig.setDestinationModeForCodegen(source, destinations);
    var model$15 = AvoConfig.convertDestinationInterfacesToCustomInModel(model$2, source);
    var model$16 = AvoModel.filterExcludedEventsFromModel(model$15, source);
    var model$17 = AvoModel.filterUnusedPropertyBundles(model$16);
    var model$18 = AvoModel.enrichModel(model$17, {
          hd: source,
          tl: /* [] */0
        });
    var model$19 = AvoModel.enrichModelForCodegen(serverTrackingWithDeviceId, omitUserIdInSetUserPropertiesOnCustomDestination, skipImplementWithAvoFiltering, model$18, source);
    var model$20 = AvoModel.enrichPropertyBundlesForCodegen(model$19, undefined, {
          hd: source.id,
          tl: /* [] */0
        });
    match$1 = [
      model$20,
      destinations$3
    ];
  } else if (match === "re_v2") {
    var destinations$4 = AvoConfig.setDestinationModeForCodegen(source, destinations);
    var model$21 = AvoConfig.convertDestinationInterfacesToCustomInModel(model$2, source);
    var model$22 = AvoModel.filterExcludedEventsFromModel(model$21, source);
    var model$23 = AvoModel.filterUnusedPropertyBundles(model$22);
    var model$24 = AvoModel.enrichModel(model$23, {
          hd: source,
          tl: /* [] */0
        });
    var model$25 = AvoModel.enrichModelForCodegen(undefined, omitUserIdInSetUserPropertiesOnCustomDestination, skipImplementWithAvoFiltering, model$24, source);
    var model$26 = AvoModel.enrichPropertyBundlesForCodegen(model$25, undefined, {
          hd: source.id,
          tl: /* [] */0
        });
    match$1 = [
      model$26,
      destinations$4
    ];
  } else if (match === "cs") {
    var destinations$5 = AvoConfig.setDestinationModeForCodegen(source, destinations);
    var model$27 = AvoConfig.convertDestinationInterfacesToCustomInModel(model$2, source);
    var model$28 = AvoModel.filterExcludedEventsFromModel(model$27, source);
    var model$29 = AvoModel.filterUnusedPropertyBundles(model$28);
    var model$30 = AvoModel.enrichModel(model$29, {
          hd: source,
          tl: /* [] */0
        });
    var model$31 = AvoModel.enrichModelForCodegen(undefined, undefined, skipImplementWithAvoFiltering, model$30, source);
    var model$32 = AvoModel.enrichPropertyBundlesForCodegen(model$31, undefined, {
          hd: source.id,
          tl: /* [] */0
        });
    match$1 = [
      model$32,
      destinations$5
    ];
  } else if (match === "js") {
    var destinations$6 = AvoConfig.setDestinationModeForCodegen(source, destinations);
    var model$33 = AvoConfig.convertDestinationInterfacesToCustomInModel(model$2, source);
    var model$34 = AvoModel.filterExcludedEventsFromModel(model$33, source);
    var model$35 = AvoModel.filterUnusedPropertyBundles(model$34);
    var model$36 = AvoModel.enrichModel(model$35, {
          hd: source,
          tl: /* [] */0
        });
    var model$37 = AvoModel.enrichModelForCodegen(undefined, undefined, skipImplementWithAvoFiltering, model$36, source);
    var model$38 = AvoModel.enrichPropertyBundlesForCodegen(model$37, undefined, {
          hd: source.id,
          tl: /* [] */0
        });
    match$1 = [
      model$38,
      destinations$6
    ];
  } else if (match === "kt") {
    var destinations$7 = AvoConfig.setDestinationModeForCodegen(source, destinations);
    var model$39 = AvoConfig.convertDestinationInterfacesToCustomInModel(model$2, source);
    var model$40 = AvoModel.filterExcludedEventsFromModel(model$39, source);
    var model$41 = AvoModel.filterUnusedPropertyBundles(model$40);
    var model$42 = AvoModel.enrichModel(model$41, {
          hd: source,
          tl: /* [] */0
        });
    var model$43 = AvoModel.enrichModelForCodegen(undefined, undefined, skipImplementWithAvoFiltering, model$42, source);
    var model$44 = AvoModel.enrichPropertyBundlesForCodegen(model$43, undefined, {
          hd: source.id,
          tl: /* [] */0
        });
    match$1 = [
      model$44,
      destinations$7
    ];
  } else if (match === "py3" || match === "py") {
    var destinations$8 = AvoConfig.setDestinationModeForCodegen(source, destinations);
    var model$45 = AvoConfig.convertDestinationInterfacesToCustomInModel(model$2, source);
    var model$46 = AvoModel.filterExcludedEventsFromModel(model$45, source);
    var model$47 = AvoModel.filterUnusedPropertyBundles(model$46);
    var model$48 = AvoModel.enrichModel(model$47, {
          hd: source,
          tl: /* [] */0
        });
    var model$49 = AvoModel.enrichModelForCodegen(serverTrackingWithDeviceId, undefined, skipImplementWithAvoFiltering, model$48, source);
    var model$50 = AvoModel.enrichPropertyBundlesForCodegen(model$49, undefined, {
          hd: source.id,
          tl: /* [] */0
        });
    match$1 = [
      model$50,
      destinations$8
    ];
  } else if (match === "rb") {
    var destinations$9 = AvoConfig.setDestinationModeForCodegen(source, destinations);
    var model$51 = AvoConfig.convertDestinationInterfacesToCustomInModel(model$2, source);
    var model$52 = AvoModel.filterExcludedEventsFromModel(model$51, source);
    var model$53 = AvoModel.filterUnusedPropertyBundles(model$52);
    var model$54 = AvoModel.enrichModel(model$53, {
          hd: source,
          tl: /* [] */0
        });
    var model$55 = AvoModel.enrichModelForCodegen(undefined, undefined, skipImplementWithAvoFiltering, model$54, source);
    var model$56 = AvoModel.enrichPropertyBundlesForCodegen(model$55, undefined, {
          hd: source.id,
          tl: /* [] */0
        });
    match$1 = [
      model$56,
      destinations$9
    ];
  } else if (match === "re") {
    var destinations$10 = AvoConfig.setDestinationModeForCodegen(source, destinations);
    var model$57 = AvoConfig.convertDestinationInterfacesToCustomInModel(model$2, source);
    var model$58 = AvoModel.filterExcludedEventsFromModel(model$57, source);
    var model$59 = AvoModel.filterUnusedPropertyBundles(model$58);
    var model$60 = AvoModel.enrichModel(model$59, {
          hd: source,
          tl: /* [] */0
        });
    var model$61 = AvoModel.enrichModelForCodegen(undefined, undefined, skipImplementWithAvoFiltering, model$60, source);
    var model$62 = AvoModel.enrichPropertyBundlesForCodegen(model$61, undefined, {
          hd: source.id,
          tl: /* [] */0
        });
    match$1 = [
      model$62,
      destinations$10
    ];
  } else if (match === "ts") {
    var destinations$11 = AvoConfig.setDestinationModeForCodegen(source, destinations);
    var model$63 = AvoConfig.convertDestinationInterfacesToCustomInModel(model$2, source);
    var model$64 = AvoModel.filterExcludedEventsFromModel(model$63, source);
    var model$65 = AvoModel.filterUnusedPropertyBundles(model$64);
    var model$66 = AvoModel.enrichModel(model$65, {
          hd: source,
          tl: /* [] */0
        });
    var model$67 = AvoModel.enrichModelForCodegen(serverTrackingWithDeviceId, undefined, skipImplementWithAvoFiltering, model$66, source);
    var model$68 = AvoModel.enrichPropertyBundlesForCodegen(model$67, undefined, {
          hd: source.id,
          tl: /* [] */0
        });
    match$1 = [
      model$68,
      destinations$11
    ];
  } else if (match === "php") {
    var destinations$12 = AvoConfig.setDestinationModeForCodegen(source, destinations);
    var model$69 = AvoConfig.convertDestinationInterfacesToCustomInModel(model$2, source);
    var model$70 = AvoModel.filterExcludedEventsFromModel(model$69, source);
    var model$71 = AvoModel.filterUnusedPropertyBundles(model$70);
    var model$72 = AvoModel.enrichModel(model$71, {
          hd: source,
          tl: /* [] */0
        });
    var model$73 = AvoModel.enrichModelForCodegen(serverTrackingWithDeviceId, undefined, skipImplementWithAvoFiltering, model$72, source);
    var model$74 = AvoModel.enrichPropertyBundlesForCodegen(model$73, undefined, {
          hd: source.id,
          tl: /* [] */0
        });
    match$1 = [
      model$74,
      destinations$12
    ];
  } else if (match === "swift") {
    var destinations$13 = AvoConfig.setDestinationModeForCodegen(source, destinations);
    var model$75 = AvoConfig.convertDestinationInterfacesToCustomInModel(model$2, source);
    var model$76 = AvoModel.filterExcludedEventsFromModel(model$75, source);
    var model$77 = AvoModel.filterUnusedPropertyBundles(model$76);
    var model$78 = AvoModel.enrichModel(model$77, {
          hd: source,
          tl: /* [] */0
        });
    var model$79 = AvoModel.enrichModelForCodegen(undefined, undefined, skipImplementWithAvoFiltering, model$78, source);
    var model$80 = AvoModel.enrichPropertyBundlesForCodegen(model$79, undefined, {
          hd: source.id,
          tl: /* [] */0
        });
    match$1 = [
      model$80,
      destinations$13
    ];
  } else {
    match$1 = [
      model$2,
      destinations
    ];
  }
  var destinations$14 = match$1[1];
  var model$81 = ClearAllowedValuesForPropertiesThatHaveRegexUseCase.clear(match$1[0]);
  var model$82 = eventSpecificPropertyValues ? EnrichWithVirtualPropertiesWithMatchesForEventSpecificAllowedValuesUseCase.enrichWithVirtualPropertiesSlow(model$81, TrackingPlanMappedModel.fromOld(model$81), source.id) : CopyAllowedValuesToMatchesUseCase.copyAllowedValues(model$81, source.id);
  var model$83 = removeUnusedEnums ? AvoModel.filterUnusedPropertyMatches(model$82) : model$82;
  var model$84 = AvoConfig.areGroupPropertiesSupported(source, destinations$14) ? model$83 : ({
        types: model$83.types,
        properties: Belt_List.mapU(filterOutGroupProperties(AvoModel.resolveProperties(model$83, model$83.properties)), (function (prop) {
                return {
                        TAG: /* Property */1,
                        _0: prop
                      };
              })),
        propertyBundles: model$83.propertyBundles,
        events: Belt_List.mapU(model$83.events, (function ($$event) {
                return {
                        id: $$event.id,
                        name: $$event.name,
                        uniqueName: $$event.uniqueName,
                        description: $$event.description,
                        directPropertyRefs: $$event.directPropertyRefs,
                        propertyBundles: $$event.propertyBundles,
                        variants: $$event.variants,
                        types: $$event.types,
                        tags: $$event.tags,
                        excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                        includeSources: $$event.includeSources,
                        includeDestinations: $$event.includeDestinations,
                        hashes: $$event.hashes,
                        propertyWhitelist: $$event.propertyWhitelist,
                        eventGroupTypeIdsWithArchive: [],
                        userGroupTypeIdsWithArchive: [],
                        triggers: $$event.triggers,
                        globalRequirementsMetadata: $$event.globalRequirementsMetadata
                      };
              })),
        migrations: model$83.migrations,
        sources: model$83.sources,
        destinations: model$83.destinations,
        groupTypes: model$83.groupTypes,
        goals: model$83.goals,
        metrics: model$83.metrics,
        archive: model$83.archive,
        openBranches: model$83.openBranches,
        branchPointer: model$83.branchPointer,
        rules: model$83.rules,
        integrations: model$83.integrations,
        globalRequirements: model$83.globalRequirements
      });
  return [
          model$84,
          destinations$14
        ];
}

function printPropertyGroupArguments(avoFormat, params, commentOpen, commentClose) {
  if (params) {
    return Belt_List.forEach(params, (function (param) {
                  var comments = param[1];
                  AvoFormat.fprintF(avoFormat, "@,%s, %s", undefined, [
                        param[0],
                        commentOpen
                      ]);
                  Belt_List.forEachWithIndex(comments, (function (index, comment) {
                          if (index < (Belt_List.length(comments) - 1 | 0)) {
                            return AvoFormat.fprintF(avoFormat, "%s@,", undefined, [comment]);
                          } else {
                            return AvoFormat.fprintF(avoFormat, "%s", undefined, [comment]);
                          }
                        }));
                  return AvoFormat.fprintF(avoFormat, " %s", undefined, [commentClose]);
                }));
  }
  
}

function resolveFunctionArguments(model, inputProperties, $$event) {
  var inputPropertiesWithoutGroupProperties = filterOutGroupProperties(inputProperties);
  var match = classifyProperties(inputProperties);
  var groupPropertiesByGroupType = classifyGroupPropertiesByGroupType(match[3]);
  return Belt_Array.concatMany([
              Belt_Array.mapU(Belt_List.toArray(inputPropertiesWithoutGroupProperties), (function (prop) {
                      return {
                              TAG: /* Property */0,
                              _0: prop
                            };
                    })),
              Belt_Array.mapU(AvoModel.resolveGroupTypes($$event.userGroupTypeIdsWithArchive, model.groupTypes), (function (userGroupType) {
                      return {
                              TAG: /* AssociateUserGroup */2,
                              _0: userGroupType
                            };
                    })),
              Belt_Array.mapU(AvoModel.resolveGroupTypes($$event.eventGroupTypeIdsWithArchive, model.groupTypes), (function (eventGroupType) {
                      return {
                              TAG: /* AssociateEventGroup */3,
                              _0: eventGroupType
                            };
                    })),
              Belt_Array.concatMany(Belt_Array.mapU(Belt_MapString.toArray(groupPropertiesByGroupType), (function (param) {
                          var groupType = ModelUtils.getGroupTypeById(param[0], model);
                          if (groupType !== undefined) {
                            return Belt_Array.concat([{
                                          TAG: /* UpdateGroup */4,
                                          _0: groupType
                                        }], Belt_Array.mapU(param[1], (function (property) {
                                              return {
                                                      TAG: /* GroupProperty */1,
                                                      _0: property,
                                                      _1: groupType
                                                    };
                                            })));
                          } else {
                            return [];
                          }
                        })))
            ]);
}

function getPropertyOnEventRegexDocString(property, eventId) {
  var maybeRegexValidation = GetRegexValidationUseCase.get(property);
  var regex = GetMostSpecificRegexUseCase.getMostSpecificRegex(maybeRegexValidation, eventId);
  if (regex !== undefined) {
    return ", should match regex: " + regex;
  } else {
    return "";
  }
}

function printPropertyTypeInPseudocodeForCodegen(property, optionalOverwrite, identifier, model, $$event, propertyNameMaps, source, param) {
  var eventSpecificAllowedPropertyValues;
  if (ModelUtils.hasMigrated(model, "EventSpecificAllowedPropertyValues")) {
    var allowedValues = GetEventSpecificPropertyValuesUseCase.getAllowedValuesOnSource(property.eventSpecificAllowedPropertyValues, $$event, source.id);
    var allowedValueStrings = Belt_Array.map(allowedValues, (function (value) {
            return "\"" + value + "\"";
          }));
    eventSpecificAllowedPropertyValues = Caml_obj.caml_equal(allowedValueStrings, []) ? /* [] */0 : ({
          hd: "allowed values: " + allowedValueStrings.join(", "),
          tl: /* [] */0
        });
  } else {
    eventSpecificAllowedPropertyValues = /* [] */0;
  }
  var hasRegex = GetRegexValidationUseCase.get(property) !== undefined;
  var validations = property.validations;
  var validations$1 = validations ? Belt_List.toArray(Belt_List.concat(Belt_List.map(validations, (function (x) {
                      if (typeof x !== "object") {
                        return "";
                      }
                      var variant = x.NAME;
                      if (variant === "Matches") {
                        var matches = x.VAL;
                        if (ModelUtils.hasMigrated(model, "EventSpecificAllowedPropertyValues")) {
                          return "";
                        }
                        if (Belt_List.length(matches) <= 0) {
                          return "";
                        }
                        var matchesStr = Belt_List.toArray(Belt_List.map(matches, (function (param) {
                                      var match = param[0].VAL;
                                      if (typeof match === "object" && match.NAME === "StringLit") {
                                        return "\"" + (match.VAL + "\"");
                                      } else {
                                        return "";
                                      }
                                    }))).join(", ");
                        return "restricted to : " + matchesStr;
                      }
                      if (variant === "Regex") {
                        if (property.type_ === "string") {
                          return getPropertyOnEventRegexDocString(property, $$event.id).substring(1);
                        } else {
                          return "";
                        }
                      }
                      if (variant === "Max") {
                        var match = x.VAL;
                        if (typeof match !== "object") {
                          return "";
                        }
                        var variant$1 = match.NAME;
                        if (variant$1 === "IntLit") {
                          return "max " + String(match.VAL);
                        } else if (variant$1 === "FloatLit") {
                          return "max " + String(match.VAL);
                        } else {
                          return "";
                        }
                      }
                      if (variant === "Min") {
                        var match$1 = x.VAL;
                        if (typeof match$1 !== "object") {
                          return "";
                        }
                        var variant$2 = match$1.NAME;
                        if (variant$2 === "IntLit") {
                          return "min " + String(match$1.VAL);
                        } else if (variant$2 === "FloatLit") {
                          return "min " + String(match$1.VAL);
                        } else {
                          return "";
                        }
                      }
                      if (variant === "NestedProperty") {
                        var nestedPropertyRefs = x.VAL;
                        if (nestedPropertyRefs) {
                          return "{\n" + (Belt_List.toArray(Belt_List.keepMap(nestedPropertyRefs, (function (nestedPropertyRef) {
                                                var maybeNestedProperty = ModelUtils.resolvePropertyById(nestedPropertyRef.id, model);
                                                if (maybeNestedProperty === undefined) {
                                                  return ;
                                                }
                                                var type_ = Belt_Option.getWithDefault(undefined, printPropertyTypeInPseudocodeForCodegen(maybeNestedProperty, maybeNestedProperty.optionalWhenInObject, identifier, model, $$event, propertyNameMaps, source, undefined));
                                                var propertyNameMap = Belt_MapString.getWithDefault(propertyNameMaps, maybeNestedProperty.id, /* [] */0);
                                                if (!propertyNameMap) {
                                                  return Curry._1(identifier, maybeNestedProperty.name) + ": " + (type_ + ",");
                                                }
                                                var mainName = CodegenRules.getMainNameMappedName(maybeNestedProperty.name, propertyNameMap);
                                                var destinationSpecificMappings = CodegenRules.getDestinationSpecificRules(propertyNameMap);
                                                if (Caml_obj.caml_equal(destinationSpecificMappings, [])) {
                                                  return Curry._1(identifier, mainName) + ": " + (type_ + ",");
                                                } else {
                                                  return Curry._1(identifier, mainName) + ": " + (type_ + (", " + nameMappingString(destinationSpecificMappings)));
                                                }
                                              }))).join("\n") + "\n}");
                        } else {
                          return "";
                        }
                      }
                      if (variant !== "Shape") {
                        return "";
                      }
                      var items = x.VAL;
                      if (items) {
                        return "{" + (Belt_List.toArray(Belt_List.map(items, (function (item) {
                                              return item.key + PropertyUtils.printValue(item);
                                            }))).join(", ") + "}");
                      } else {
                        return "";
                      }
                    })), hasRegex ? /* [] */0 : eventSpecificAllowedPropertyValues)).join(" ") : "";
  var optionality = optionalOverwrite !== undefined ? (
      optionalOverwrite ? "nullable" : ""
    ) : (
      ModelUtils.isPropertyOptionalOnEventsAndSources({
            hd: $$event.id,
            tl: /* [] */0
          }, {
            hd: source.id,
            tl: /* [] */0
          }, model, property) ? "nullable" : ""
    );
  if (validations$1 === "") {
    return (
            optionality === "" ? "" : optionality + " "
          ) + ((
              property.list ? "list of " : ""
            ) + property.type_);
  } else {
    return (
            optionality === "" ? "" : optionality + " "
          ) + ((
              property.list ? "list of " : ""
            ) + property.type_ + (" (" + (validations$1 + ")")));
  }
}

function printPropertyGroupTypeInPseudocode(properties, propertyVarName, identifier, model, $$event, propertyNameMaps, source) {
  var begin = Printf.sprintf(/* Format */{
        _0: {
          TAG: /* String_literal */11,
          _0: " {",
          _1: /* End_of_format */0
        },
        _1: " {"
      });
  var params = Belt_List.keepMap(properties, (function (propertyId) {
          var property = ModelUtils.resolvePropertyById(propertyId, model);
          return Belt_Option.map(property, (function (property) {
                        var typecomment = printPropertyTypeInPseudocodeForCodegen(property, undefined, identifier, model, $$event, propertyNameMaps, source, undefined);
                        return Curry._2(Printf.sprintf(/* Format */{
                                        _0: {
                                          TAG: /* String */2,
                                          _0: /* No_padding */0,
                                          _1: {
                                            TAG: /* String_literal */11,
                                            _0: ": ",
                                            _1: {
                                              TAG: /* String */2,
                                              _0: /* No_padding */0,
                                              _1: /* End_of_format */0
                                            }
                                          }
                                        },
                                        _1: "%s: %s"
                                      }), Curry._1(propertyVarName, property), typecomment);
                      }));
        }));
  var end = Printf.sprintf(/* Format */{
        _0: {
          TAG: /* Char_literal */12,
          _0: /* '}' */125,
          _1: /* End_of_format */0
        },
        _1: "}"
      });
  return Belt_List.concatMany([
              {
                hd: begin,
                tl: /* [] */0
              },
              params,
              {
                hd: end,
                tl: /* [] */0
              }
            ]);
}

function getPropertyNameMaps($$event, source, model) {
  var destinationsSendingEventFromSource = Belt_List.keep(ModelUtils.getSourceDestinationIds(source.destinations), (function (destinationId) {
          return shouldSendEventToSourceDestination($$event, source, destinationId);
        }));
  return CodegenRules.getPropertyNameMaps(Belt_List.toArray(PropertyUtils.getAllPropertiesFromEvents((function (e) {
                        return e.id === $$event.id;
                      }), model)), $$event.id, destinationsSendingEventFromSource, model);
}

function snippetArguments(inputProperties, propertyVarName, $$event, model, groupTypeVarName, identifier, snippetFormat, commentFormat, source) {
  var functionArguments = Belt_List.fromArray(resolveFunctionArguments(model, inputProperties, $$event));
  var propertyNameMaps = getPropertyNameMaps($$event, source, model);
  return Belt_List.mapU(functionArguments, (function (argument) {
                switch (argument.TAG | 0) {
                  case /* Property */0 :
                      var property = argument._0;
                      var typeComment = printPropertyTypeInPseudocodeForCodegen(property, undefined, identifier, model, $$event, propertyNameMaps, source, undefined);
                      return [
                              Curry._2(Printf.sprintf(snippetFormat), Curry._1(propertyVarName, property), Curry._1(propertyVarName, property)),
                              Belt_Array.mapU(typeComment.split("\n"), (function (typeCommentLine) {
                                        return Curry._1(Printf.sprintf(commentFormat), typeCommentLine);
                                      })).join("\n")
                            ];
                  case /* GroupProperty */1 :
                      var groupProperty = argument._0;
                      var typeComment$1 = printPropertyTypeInPseudocodeForCodegen(groupProperty, undefined, identifier, model, $$event, propertyNameMaps, source, undefined);
                      return [
                              Curry._2(Printf.sprintf(snippetFormat), Curry._1(propertyVarName, groupProperty), Curry._1(propertyVarName, groupProperty)),
                              Curry._1(Printf.sprintf(commentFormat), typeComment$1)
                            ];
                  case /* AssociateUserGroup */2 :
                      var userGroupType = argument._0;
                      return [
                              Curry._2(Printf.sprintf(snippetFormat), Curry._3(groupTypeVarName, "associateUserWith", userGroupType.id, model), Curry._1(identifier, ModelUtils.getGroupTypeNameWithArchiveWithDefaultGroupTypeId(userGroupType.id, model) + "Id")),
                              Curry._1(Printf.sprintf(commentFormat), "string")
                            ];
                  case /* AssociateEventGroup */3 :
                      var eventGroupType = argument._0;
                      return [
                              Curry._2(Printf.sprintf(snippetFormat), Curry._3(groupTypeVarName, "associateEventWith", eventGroupType.id, model), Curry._1(identifier, ModelUtils.getGroupTypeNameWithArchiveWithDefaultGroupTypeId(eventGroupType.id, model) + "Id")),
                              Curry._1(Printf.sprintf(commentFormat), "string")
                            ];
                  case /* UpdateGroup */4 :
                      var groupType = argument._0;
                      return [
                              Curry._2(Printf.sprintf(snippetFormat), Curry._3(groupTypeVarName, "updateGroupPropertiesFor", groupType.id, model), Curry._1(identifier, ModelUtils.getGroupTypeNameWithArchiveWithDefaultGroupTypeId(groupType.id, model) + "Id")),
                              Curry._1(Printf.sprintf(commentFormat), "string")
                            ];
                  
                }
              }));
}

var reservedKeywords = {
  hd: "abstract",
  tl: {
    hd: "await",
    tl: {
      hd: "boolean",
      tl: {
        hd: "break",
        tl: {
          hd: "byte",
          tl: {
            hd: "case",
            tl: {
              hd: "catch",
              tl: {
                hd: "char",
                tl: {
                  hd: "class",
                  tl: {
                    hd: "const",
                    tl: {
                      hd: "continue",
                      tl: {
                        hd: "debugger",
                        tl: {
                          hd: "default",
                          tl: {
                            hd: "delete",
                            tl: {
                              hd: "do",
                              tl: {
                                hd: "double",
                                tl: {
                                  hd: "else",
                                  tl: {
                                    hd: "enum",
                                    tl: {
                                      hd: "export",
                                      tl: {
                                        hd: "extends",
                                        tl: {
                                          hd: "false",
                                          tl: {
                                            hd: "final",
                                            tl: {
                                              hd: "finally",
                                              tl: {
                                                hd: "float",
                                                tl: {
                                                  hd: "for",
                                                  tl: {
                                                    hd: "function",
                                                    tl: {
                                                      hd: "goto",
                                                      tl: {
                                                        hd: "if",
                                                        tl: {
                                                          hd: "implements",
                                                          tl: {
                                                            hd: "import",
                                                            tl: {
                                                              hd: "in",
                                                              tl: {
                                                                hd: "instanceof",
                                                                tl: {
                                                                  hd: "int",
                                                                  tl: {
                                                                    hd: "interface",
                                                                    tl: {
                                                                      hd: "let",
                                                                      tl: {
                                                                        hd: "long",
                                                                        tl: {
                                                                          hd: "native",
                                                                          tl: {
                                                                            hd: "new",
                                                                            tl: {
                                                                              hd: "null",
                                                                              tl: {
                                                                                hd: "package",
                                                                                tl: {
                                                                                  hd: "private",
                                                                                  tl: {
                                                                                    hd: "protected",
                                                                                    tl: {
                                                                                      hd: "public",
                                                                                      tl: {
                                                                                        hd: "return",
                                                                                        tl: {
                                                                                          hd: "short",
                                                                                          tl: {
                                                                                            hd: "static",
                                                                                            tl: {
                                                                                              hd: "super",
                                                                                              tl: {
                                                                                                hd: "switch",
                                                                                                tl: {
                                                                                                  hd: "synchronized",
                                                                                                  tl: {
                                                                                                    hd: "this",
                                                                                                    tl: {
                                                                                                      hd: "throw",
                                                                                                      tl: {
                                                                                                        hd: "throws",
                                                                                                        tl: {
                                                                                                          hd: "transient",
                                                                                                          tl: {
                                                                                                            hd: "true",
                                                                                                            tl: {
                                                                                                              hd: "try",
                                                                                                              tl: {
                                                                                                                hd: "typeof",
                                                                                                                tl: {
                                                                                                                  hd: "undefined",
                                                                                                                  tl: {
                                                                                                                    hd: "var",
                                                                                                                    tl: {
                                                                                                                      hd: "void",
                                                                                                                      tl: {
                                                                                                                        hd: "volatile",
                                                                                                                        tl: {
                                                                                                                          hd: "while",
                                                                                                                          tl: {
                                                                                                                            hd: "with",
                                                                                                                            tl: {
                                                                                                                              hd: "yield",
                                                                                                                              tl: /* [] */0
                                                                                                                            }
                                                                                                                          }
                                                                                                                        }
                                                                                                                      }
                                                                                                                    }
                                                                                                                  }
                                                                                                                }
                                                                                                              }
                                                                                                            }
                                                                                                          }
                                                                                                        }
                                                                                                      }
                                                                                                    }
                                                                                                  }
                                                                                                }
                                                                                              }
                                                                                            }
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

function identifier(caseOpt, name) {
  var $$case = caseOpt !== undefined ? caseOpt : Case.camel;
  var name$1 = Curry._1($$case, prefixWithWordIfFirstNumber(name));
  return Curry._2(Printf.sprintf(/* Format */{
                  _0: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: /* End_of_format */0
                    }
                  },
                  _1: "%s%s"
                }), name$1, List.exists((function (param) {
                    return name$1 === param;
                  }), reservedKeywords) ? "_" : "");
}

function groupTypeVarName(prefixOpt, groupTypeId, model) {
  var prefix = prefixOpt !== undefined ? prefixOpt : "";
  return Curry._2(Printf.sprintf(/* Format */{
                  _0: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: /* End_of_format */0
                    }
                  },
                  _1: "%s%s"
                }), prefix, identifier(Case.pascal, ModelUtils.getGroupTypeNameWithArchiveWithDefaultGroupTypeId(groupTypeId, model)));
}

function propertyVarName($$case, property) {
  var enrichedProperty = property.builtIn !== "NotBuiltIn";
  if (enrichedProperty) {
    return Curry._1(Printf.sprintf(/* Format */{
                    _0: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* Char_literal */12,
                        _0: /* '_' */95,
                        _1: /* End_of_format */0
                      }
                    },
                    _1: "%s_"
                  }), identifier($$case, prefixWithWordIfFirstNumber(PropertyCodegenNameUseCase.get(property))));
  } else {
    return identifier($$case, prefixWithWordIfFirstNumber(PropertyCodegenNameUseCase.get(property)));
  }
}

function generateSnippetFromEnrichedModel($$event, model, source) {
  var $$event$1 = Belt_Option.getExn(ModelUtils.getEventById($$event.id, model));
  var inputProperties = AvoModel.resolveProperties(model, $$event$1.directPropertyRefs);
  var partial_arg = Case.camel;
  var $$arguments = snippetArguments(inputProperties, (function (eta) {
          return propertyVarName(undefined, eta);
        }), $$event$1, model, groupTypeVarName, (function (param) {
          return identifier(partial_arg, param);
        }), /* Format */{
        _0: {
          TAG: /* String */2,
          _0: /* No_padding */0,
          _1: {
            TAG: /* String_literal */11,
            _0: ": ",
            _1: {
              TAG: /* String */2,
              _0: /* No_padding */0,
              _1: /* End_of_format */0
            }
          }
        },
        _1: "%s: %s"
      }, /* Format */{
        _0: {
          TAG: /* String_literal */11,
          _0: "// ",
          _1: {
            TAG: /* String */2,
            _0: /* No_padding */0,
            _1: /* End_of_format */0
          }
        },
        _1: "// %s"
      }, source);
  var printArguments = function (avoFormat, params) {
    if (!params) {
      return ;
    }
    if (params.tl) {
      return Belt_List.forEach(params, (function (param) {
                    return AvoFormat.fprintF(avoFormat, "@,%s,%s", undefined, [
                                param[0],
                                commentWithSpace(param[1])
                              ]);
                  }));
    }
    var match = params.hd;
    return AvoFormat.fprintF(avoFormat, "%s%s", undefined, [
                match[0],
                commentWithSpace(match[1])
              ]);
  };
  var avoFormat = AvoFormat.newTextBuilder(undefined);
  AvoFormat.fprintF(avoFormat, "@[<v>", undefined, []);
  if ($$arguments) {
    if ($$arguments.tl) {
      AvoFormat.fprintF(avoFormat, "@[<v 2>Avo.%s({%a@]@,});", [(function (__x) {
                return printArguments(__x, $$arguments);
              })], [identifier(undefined, EventCodegenNameUseCase.get($$event$1))]);
    } else {
      AvoFormat.fprintF(avoFormat, "Avo.%s({%a});", [(function (__x) {
                return printArguments(__x, $$arguments);
              })], [identifier(undefined, EventCodegenNameUseCase.get($$event$1))]);
    }
  } else {
    AvoFormat.fprintF(avoFormat, "Avo.%s();", undefined, [identifier(undefined, EventCodegenNameUseCase.get($$event$1))]);
  }
  AvoFormat.fprintF(avoFormat, "@]", undefined, []);
  return AvoFormat.buildText(avoFormat);
}

function generateSnippetGtmFromEnrichedModel($$event, model, source) {
  var $$event$1 = Belt_Option.getExn(ModelUtils.getEventById($$event.id, model));
  var inputProperties = filterPinnedProperties($$event$1.directPropertyRefs, model.properties, model);
  var inputProperties$1 = AvoModel.resolveProperties(model, inputProperties);
  var partial_arg = Case.title;
  var $$arguments = snippetArguments(inputProperties$1, (function (eta) {
          return propertyVarName(undefined, eta);
        }), $$event$1, model, groupTypeVarName, (function (param) {
          return identifier(partial_arg, param);
        }), /* Format */{
        _0: {
          TAG: /* String */2,
          _0: /* No_padding */0,
          _1: {
            TAG: /* String_literal */11,
            _0: ": {{",
            _1: {
              TAG: /* String */2,
              _0: /* No_padding */0,
              _1: {
                TAG: /* String_literal */11,
                _0: "}}",
                _1: /* End_of_format */0
              }
            }
          }
        },
        _1: "%s: {{%s}}"
      }, /* Format */{
        _0: {
          TAG: /* String_literal */11,
          _0: "// ",
          _1: {
            TAG: /* String */2,
            _0: /* No_padding */0,
            _1: /* End_of_format */0
          }
        },
        _1: "// %s"
      }, source);
  var printArguments = function (avoFormat, params) {
    if (!params) {
      return ;
    }
    if (params.tl) {
      return Belt_List.forEach(params, (function (param) {
                    return AvoFormat.fprintF(avoFormat, "@,%s,%s", undefined, [
                                param[0],
                                commentWithSpace(param[1])
                              ]);
                  }));
    }
    var match = params.hd;
    return AvoFormat.fprintF(avoFormat, "%s%s", undefined, [
                match[0],
                commentWithSpace(match[1])
              ]);
  };
  var avoFormat = AvoFormat.newTextBuilder(undefined);
  AvoFormat.fprintF(avoFormat, "@[<v>", undefined, []);
  AvoFormat.fprintF(avoFormat, "@[<v 2><script>", undefined, []);
  if ($$arguments) {
    if ($$arguments.tl) {
      AvoFormat.fprintF(avoFormat, "@,@[<v 2>Avo.%s({%a@]@,});", [(function (__x) {
                return printArguments(__x, $$arguments);
              })], [identifier(undefined, EventCodegenNameUseCase.get($$event$1))]);
    } else {
      AvoFormat.fprintF(avoFormat, "@,Avo.%s({%a});", [(function (__x) {
                return printArguments(__x, $$arguments);
              })], [identifier(undefined, EventCodegenNameUseCase.get($$event$1))]);
    }
  } else {
    AvoFormat.fprintF(avoFormat, "@,Avo.%s();", undefined, [identifier(undefined, EventCodegenNameUseCase.get($$event$1))]);
  }
  AvoFormat.fprintF(avoFormat, "@]@,</script>", undefined, []);
  AvoFormat.fprintF(avoFormat, "@]", undefined, []);
  return AvoFormat.buildText(avoFormat);
}

var JavaScript = {
  reservedKeywords: reservedKeywords,
  identifier: identifier,
  groupTypeVarName: groupTypeVarName,
  propertyVarName: propertyVarName,
  generateSnippetFromEnrichedModel: generateSnippetFromEnrichedModel,
  generateSnippetGtmFromEnrichedModel: generateSnippetGtmFromEnrichedModel
};

var reservedKeywords$1 = {
  hd: "and",
  tl: {
    hd: "as",
    tl: {
      hd: "assert",
      tl: {
        hd: "begin",
        tl: {
          hd: "class",
          tl: {
            hd: "constraint",
            tl: {
              hd: "do",
              tl: {
                hd: "done",
                tl: {
                  hd: "downto",
                  tl: {
                    hd: "else",
                    tl: {
                      hd: "end",
                      tl: {
                        hd: "exception",
                        tl: {
                          hd: "external",
                          tl: {
                            hd: "false",
                            tl: {
                              hd: "for",
                              tl: {
                                hd: "fun",
                                tl: {
                                  hd: "function",
                                  tl: {
                                    hd: "functor",
                                    tl: {
                                      hd: "if",
                                      tl: {
                                        hd: "in",
                                        tl: {
                                          hd: "include",
                                          tl: {
                                            hd: "inherit",
                                            tl: {
                                              hd: "initializer",
                                              tl: {
                                                hd: "lazy",
                                                tl: {
                                                  hd: "let",
                                                  tl: {
                                                    hd: "match",
                                                    tl: {
                                                      hd: "method",
                                                      tl: {
                                                        hd: "module",
                                                        tl: {
                                                          hd: "mutable",
                                                          tl: {
                                                            hd: "new",
                                                            tl: {
                                                              hd: "object",
                                                              tl: {
                                                                hd: "of",
                                                                tl: {
                                                                  hd: "open",
                                                                  tl: {
                                                                    hd: "or",
                                                                    tl: {
                                                                      hd: "private",
                                                                      tl: {
                                                                        hd: "pub",
                                                                        tl: {
                                                                          hd: "rec",
                                                                          tl: {
                                                                            hd: "sig",
                                                                            tl: {
                                                                              hd: "struct",
                                                                              tl: {
                                                                                hd: "switch",
                                                                                tl: {
                                                                                  hd: "then",
                                                                                  tl: {
                                                                                    hd: "to",
                                                                                    tl: {
                                                                                      hd: "true",
                                                                                      tl: {
                                                                                        hd: "try",
                                                                                        tl: {
                                                                                          hd: "type",
                                                                                          tl: {
                                                                                            hd: "val",
                                                                                            tl: {
                                                                                              hd: "virtual",
                                                                                              tl: {
                                                                                                hd: "when",
                                                                                                tl: {
                                                                                                  hd: "while",
                                                                                                  tl: {
                                                                                                    hd: "with",
                                                                                                    tl: /* [] */0
                                                                                                  }
                                                                                                }
                                                                                              }
                                                                                            }
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

function identifier$1(caseOpt, name) {
  var $$case = caseOpt !== undefined ? caseOpt : Case.camel;
  var name$1 = Curry._1($$case, name);
  var name$2 = name$1.replace(/[^\w\s\-]/ig, "");
  return Curry._2(Printf.sprintf(/* Format */{
                  _0: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: /* End_of_format */0
                    }
                  },
                  _1: "%s%s"
                }), name$2, List.exists((function (param) {
                    return name$2 === param;
                  }), reservedKeywords$1) ? "_" : "");
}

function groupTypeVarName$1(prefixOpt, groupTypeId, model) {
  var prefix = prefixOpt !== undefined ? prefixOpt : "";
  return Curry._2(Printf.sprintf(/* Format */{
                  _0: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: /* End_of_format */0
                    }
                  },
                  _1: "%s%s"
                }), prefix, identifier$1(Case.pascal, ModelUtils.getGroupTypeNameWithArchiveWithDefaultGroupTypeId(groupTypeId, model)));
}

function propertyVarName$1($$case, property) {
  var enrichedProperty = property.builtIn !== "NotBuiltIn";
  if (enrichedProperty) {
    return Curry._1(Printf.sprintf(/* Format */{
                    _0: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* Char_literal */12,
                        _0: /* '_' */95,
                        _1: /* End_of_format */0
                      }
                    },
                    _1: "%s_"
                  }), identifier$1($$case, prefixWithWordIfFirstNumber(PropertyCodegenNameUseCase.get(property))));
  } else {
    return identifier$1($$case, prefixWithWordIfFirstNumber(PropertyCodegenNameUseCase.get(property)));
  }
}

function namedPropertyVarName($$case, property) {
  return Curry._1(Printf.sprintf(/* Format */{
                  _0: {
                    TAG: /* Char_literal */12,
                    _0: /* '~' */126,
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: /* End_of_format */0
                    }
                  },
                  _1: "~%s"
                }), propertyVarName$1($$case, property));
}

function generateSnippetFromEnrichedModel$1($$event, model, source) {
  var $$event$1 = Belt_Option.getExn(ModelUtils.getEventById($$event.id, model));
  var flattenGroups = Belt_List.someU(source.config, (function (config) {
          return config === "ForceFlattenPropertyBundlesInCodegen";
        }));
  var inputGroups = flattenGroups ? /* [] */0 : AvoModel.resolvePropertyBundleRefs(model.propertyBundles, $$event$1.propertyBundles);
  var inputProperties = filterPinnedProperties($$event$1.directPropertyRefs, model.properties, model);
  var inputProperties$1 = flattenGroups ? AvoModel.resolveProperties(model, inputProperties) : Belt_List.keep(AvoModel.resolveProperties(model, inputProperties), (function (property) {
            return !Belt_List.some(inputGroups, (function (group) {
                          return Belt_List.some(group.properties, (function (propertyId) {
                                        return property.id === propertyId;
                                      }));
                        }));
          }));
  var propertyNameMaps = getPropertyNameMaps($$event$1, source, model);
  var propertyGroupsArgs = Belt_List.map(inputGroups, (function (group) {
          var comment = printPropertyGroupTypeInPseudocode(group.properties, (function (eta) {
                  return propertyVarName$1(undefined, eta);
                }), (function (eta) {
                  return identifier$1(undefined, eta);
                }), model, $$event$1, propertyNameMaps, source);
          return [
                  Curry._2(Printf.sprintf(/* Format */{
                            _0: {
                              TAG: /* Char_literal */12,
                              _0: /* '~' */126,
                              _1: {
                                TAG: /* String */2,
                                _0: /* No_padding */0,
                                _1: {
                                  TAG: /* Char_literal */12,
                                  _0: /* '=' */61,
                                  _1: {
                                    TAG: /* String */2,
                                    _0: /* No_padding */0,
                                    _1: /* End_of_format */0
                                  }
                                }
                              }
                            },
                            _1: "~%s=%s"
                          }), identifier$1(undefined, group.name), identifier$1(undefined, group.name)),
                  comment
                ];
        }));
  var partial_arg = Case.camel;
  var $$arguments = snippetArguments(inputProperties$1, (function (eta) {
          return propertyVarName$1(undefined, eta);
        }), $$event$1, model, groupTypeVarName$1, (function (param) {
          return identifier$1(partial_arg, param);
        }), /* Format */{
        _0: {
          TAG: /* Char_literal */12,
          _0: /* '~' */126,
          _1: {
            TAG: /* String */2,
            _0: /* No_padding */0,
            _1: {
              TAG: /* Char_literal */12,
              _0: /* '=' */61,
              _1: {
                TAG: /* String */2,
                _0: /* No_padding */0,
                _1: /* End_of_format */0
              }
            }
          }
        },
        _1: "~%s=%s"
      }, /* Format */{
        _0: {
          TAG: /* String_literal */11,
          _0: "// ",
          _1: {
            TAG: /* String */2,
            _0: /* No_padding */0,
            _1: /* End_of_format */0
          }
        },
        _1: "// %s"
      }, source);
  var printArguments = function (ppf, params) {
    if (!params) {
      return ;
    }
    if (params.tl) {
      return Belt_List.forEach(params, (function (param) {
                    return AvoFormat.fprintF(ppf, "@,%s,%s", undefined, [
                                param[0],
                                commentWithSpace(param[1])
                              ]);
                  }));
    }
    var match = params.hd;
    return AvoFormat.fprintF(ppf, "%s%s", undefined, [
                match[0],
                commentWithSpace(match[1])
              ]);
  };
  var avoFormat = AvoFormat.newTextBuilder(undefined);
  AvoFormat.fprintF(avoFormat, "@[<v>", undefined, []);
  var argsLength = Belt_List.length($$arguments) + Belt_List.length(propertyGroupsArgs) | 0;
  if (argsLength > 1) {
    AvoFormat.fprintF(avoFormat, "@[<v 2>let () = Avo.%s(%a%a@]@,);", [
          (function (__x) {
              return printPropertyGroupArguments(__x, propertyGroupsArgs, "/*", "*/");
            }),
          (function (__x) {
              return printArguments(__x, $$arguments);
            })
        ], [identifier$1(undefined, EventCodegenNameUseCase.get($$event$1))]);
  } else {
    AvoFormat.fprintF(avoFormat, "let () = Avo.%s(%a%a);", [
          (function (__x) {
              return printPropertyGroupArguments(__x, propertyGroupsArgs, "/*", "*/");
            }),
          (function (__x) {
              return printArguments(__x, $$arguments);
            })
        ], [identifier$1(undefined, EventCodegenNameUseCase.get($$event$1))]);
  }
  AvoFormat.fprintF(avoFormat, "@]", undefined, []);
  return AvoFormat.buildText(avoFormat);
}

var Reason = {
  reservedKeywords: reservedKeywords$1,
  identifier: identifier$1,
  groupTypeVarName: groupTypeVarName$1,
  propertyVarName: propertyVarName$1,
  namedPropertyVarName: namedPropertyVarName,
  generateSnippetFromEnrichedModel: generateSnippetFromEnrichedModel$1
};

var reservedKeywords$2 = {
  hd: "abstract",
  tl: {
    hd: "await",
    tl: {
      hd: "boolean",
      tl: {
        hd: "break",
        tl: {
          hd: "byte",
          tl: {
            hd: "case",
            tl: {
              hd: "catch",
              tl: {
                hd: "char",
                tl: {
                  hd: "class",
                  tl: {
                    hd: "const",
                    tl: {
                      hd: "continue",
                      tl: {
                        hd: "debugger",
                        tl: {
                          hd: "default",
                          tl: {
                            hd: "delete",
                            tl: {
                              hd: "do",
                              tl: {
                                hd: "double",
                                tl: {
                                  hd: "else",
                                  tl: {
                                    hd: "enum",
                                    tl: {
                                      hd: "export",
                                      tl: {
                                        hd: "extends",
                                        tl: {
                                          hd: "false",
                                          tl: {
                                            hd: "final",
                                            tl: {
                                              hd: "finally",
                                              tl: {
                                                hd: "float",
                                                tl: {
                                                  hd: "for",
                                                  tl: {
                                                    hd: "function",
                                                    tl: {
                                                      hd: "goto",
                                                      tl: {
                                                        hd: "if",
                                                        tl: {
                                                          hd: "implements",
                                                          tl: {
                                                            hd: "import",
                                                            tl: {
                                                              hd: "in",
                                                              tl: {
                                                                hd: "instanceof",
                                                                tl: {
                                                                  hd: "int",
                                                                  tl: {
                                                                    hd: "interface",
                                                                    tl: {
                                                                      hd: "let",
                                                                      tl: {
                                                                        hd: "long",
                                                                        tl: {
                                                                          hd: "native",
                                                                          tl: {
                                                                            hd: "new",
                                                                            tl: {
                                                                              hd: "null",
                                                                              tl: {
                                                                                hd: "package",
                                                                                tl: {
                                                                                  hd: "private",
                                                                                  tl: {
                                                                                    hd: "protected",
                                                                                    tl: {
                                                                                      hd: "public",
                                                                                      tl: {
                                                                                        hd: "return",
                                                                                        tl: {
                                                                                          hd: "short",
                                                                                          tl: {
                                                                                            hd: "static",
                                                                                            tl: {
                                                                                              hd: "super",
                                                                                              tl: {
                                                                                                hd: "switch",
                                                                                                tl: {
                                                                                                  hd: "synchronized",
                                                                                                  tl: {
                                                                                                    hd: "this",
                                                                                                    tl: {
                                                                                                      hd: "throw",
                                                                                                      tl: {
                                                                                                        hd: "throws",
                                                                                                        tl: {
                                                                                                          hd: "transient",
                                                                                                          tl: {
                                                                                                            hd: "true",
                                                                                                            tl: {
                                                                                                              hd: "try",
                                                                                                              tl: {
                                                                                                                hd: "typeof",
                                                                                                                tl: {
                                                                                                                  hd: "undefined",
                                                                                                                  tl: {
                                                                                                                    hd: "var",
                                                                                                                    tl: {
                                                                                                                      hd: "void",
                                                                                                                      tl: {
                                                                                                                        hd: "volatile",
                                                                                                                        tl: {
                                                                                                                          hd: "while",
                                                                                                                          tl: {
                                                                                                                            hd: "with",
                                                                                                                            tl: {
                                                                                                                              hd: "yield",
                                                                                                                              tl: /* [] */0
                                                                                                                            }
                                                                                                                          }
                                                                                                                        }
                                                                                                                      }
                                                                                                                    }
                                                                                                                  }
                                                                                                                }
                                                                                                              }
                                                                                                            }
                                                                                                          }
                                                                                                        }
                                                                                                      }
                                                                                                    }
                                                                                                  }
                                                                                                }
                                                                                              }
                                                                                            }
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

function identifier$2(caseOpt, name) {
  var $$case = caseOpt !== undefined ? caseOpt : Case.camel;
  var name$1 = Curry._1($$case, prefixWithWordIfFirstNumber(name));
  return Curry._2(Printf.sprintf(/* Format */{
                  _0: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: /* End_of_format */0
                    }
                  },
                  _1: "%s%s"
                }), name$1, List.exists((function (param) {
                    return name$1 === param;
                  }), reservedKeywords$2) ? "_" : "");
}

function groupTypeVarName$2(prefixOpt, groupTypeId, model) {
  var prefix = prefixOpt !== undefined ? prefixOpt : "";
  return Curry._2(Printf.sprintf(/* Format */{
                  _0: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: /* End_of_format */0
                    }
                  },
                  _1: "%s%s"
                }), prefix, identifier$2(Case.pascal, ModelUtils.getGroupTypeNameWithArchiveWithDefaultGroupTypeId(groupTypeId, model)));
}

function propertyVarName$2($$case, property) {
  var enrichedProperty = property.builtIn !== "NotBuiltIn";
  if (enrichedProperty) {
    return Curry._1(Printf.sprintf(/* Format */{
                    _0: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* Char_literal */12,
                        _0: /* '_' */95,
                        _1: /* End_of_format */0
                      }
                    },
                    _1: "%s_"
                  }), identifier$2($$case, prefixWithWordIfFirstNumber(PropertyCodegenNameUseCase.get(property))));
  } else {
    return identifier$2($$case, prefixWithWordIfFirstNumber(PropertyCodegenNameUseCase.get(property)));
  }
}

function generateSnippetFromEnrichedModel$2($$event, model, source) {
  var $$event$1 = Belt_Option.getExn(ModelUtils.getEventById($$event.id, model));
  var inputProperties = filterPinnedProperties($$event$1.directPropertyRefs, model.properties, model);
  var inputProperties$1 = AvoModel.resolveProperties(model, inputProperties);
  var partial_arg = Case.camel;
  var $$arguments = snippetArguments(inputProperties$1, (function (eta) {
          return propertyVarName$2(undefined, eta);
        }), $$event$1, model, groupTypeVarName$2, (function (param) {
          return identifier$2(partial_arg, param);
        }), /* Format */{
        _0: {
          TAG: /* String */2,
          _0: /* No_padding */0,
          _1: {
            TAG: /* String_literal */11,
            _0: ": ",
            _1: {
              TAG: /* String */2,
              _0: /* No_padding */0,
              _1: /* End_of_format */0
            }
          }
        },
        _1: "%s: %s"
      }, /* Format */{
        _0: {
          TAG: /* String_literal */11,
          _0: "// ",
          _1: {
            TAG: /* String */2,
            _0: /* No_padding */0,
            _1: /* End_of_format */0
          }
        },
        _1: "// %s"
      }, source);
  var printArguments = function (avoFormat, params) {
    if (!params) {
      return ;
    }
    if (params.tl) {
      return Belt_List.forEach(params, (function (param) {
                    return AvoFormat.fprintF(avoFormat, "@,%s,%s", undefined, [
                                param[0],
                                commentWithSpace(param[1])
                              ]);
                  }));
    }
    var match = params.hd;
    return AvoFormat.fprintF(avoFormat, "%s%s", undefined, [
                match[0],
                commentWithSpace(match[1])
              ]);
  };
  var avoFormat = AvoFormat.newTextBuilder(undefined);
  AvoFormat.fprintF(avoFormat, "@[<v>", undefined, []);
  if ($$arguments) {
    if ($$arguments.tl) {
      AvoFormat.fprintF(avoFormat, "@[<v 2>Avo.%s({%a@]@,});", [(function (__x) {
                return printArguments(__x, $$arguments);
              })], [identifier$2(undefined, EventCodegenNameUseCase.get($$event$1))]);
    } else {
      AvoFormat.fprintF(avoFormat, "Avo.%s({%a});", [(function (__x) {
                return printArguments(__x, $$arguments);
              })], [identifier$2(undefined, EventCodegenNameUseCase.get($$event$1))]);
    }
  } else {
    AvoFormat.fprintF(avoFormat, "Avo.%s();", undefined, [identifier$2(undefined, EventCodegenNameUseCase.get($$event$1))]);
  }
  AvoFormat.fprintF(avoFormat, "@]", undefined, []);
  return AvoFormat.buildText(avoFormat);
}

var TypeScript = {
  reservedKeywords: reservedKeywords$2,
  identifier: identifier$2,
  groupTypeVarName: groupTypeVarName$2,
  propertyVarName: propertyVarName$2,
  generateSnippetFromEnrichedModel: generateSnippetFromEnrichedModel$2
};

var reservedKeywords$3 = {
  hd: "__ENCODING__",
  tl: {
    hd: "__LINE__",
    tl: {
      hd: "__FILE__",
      tl: {
        hd: "BEGIN",
        tl: {
          hd: "END",
          tl: {
            hd: "alias",
            tl: {
              hd: "and",
              tl: {
                hd: "begin",
                tl: {
                  hd: "break",
                  tl: {
                    hd: "case",
                    tl: {
                      hd: "class",
                      tl: {
                        hd: "def",
                        tl: {
                          hd: "defined?",
                          tl: {
                            hd: "do",
                            tl: {
                              hd: "else",
                              tl: {
                                hd: "elsif",
                                tl: {
                                  hd: "end",
                                  tl: {
                                    hd: "ensure",
                                    tl: {
                                      hd: "false",
                                      tl: {
                                        hd: "for",
                                        tl: {
                                          hd: "if",
                                          tl: {
                                            hd: "in",
                                            tl: {
                                              hd: "module",
                                              tl: {
                                                hd: "next",
                                                tl: {
                                                  hd: "nil",
                                                  tl: {
                                                    hd: "not",
                                                    tl: {
                                                      hd: "or",
                                                      tl: {
                                                        hd: "redo",
                                                        tl: {
                                                          hd: "rescue",
                                                          tl: {
                                                            hd: "retry",
                                                            tl: {
                                                              hd: "return",
                                                              tl: {
                                                                hd: "self",
                                                                tl: {
                                                                  hd: "super",
                                                                  tl: {
                                                                    hd: "then",
                                                                    tl: {
                                                                      hd: "true",
                                                                      tl: {
                                                                        hd: "undef",
                                                                        tl: {
                                                                          hd: "unless",
                                                                          tl: {
                                                                            hd: "until",
                                                                            tl: {
                                                                              hd: "when",
                                                                              tl: {
                                                                                hd: "while",
                                                                                tl: {
                                                                                  hd: "yield",
                                                                                  tl: /* [] */0
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

function identifier$3(caseOpt, name) {
  var $$case = caseOpt !== undefined ? caseOpt : Case.snake;
  var name$1 = Curry._1($$case, name);
  return Curry._2(Printf.sprintf(/* Format */{
                  _0: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: /* End_of_format */0
                    }
                  },
                  _1: "%s%s"
                }), name$1, List.exists((function (param) {
                    return name$1 === param;
                  }), reservedKeywords$3) ? "_" : "");
}

function groupTypeVarName$3(prefixOpt, groupTypeId, model) {
  var prefix = prefixOpt !== undefined ? prefixOpt : "";
  return identifier$3(undefined, Curry._2(Printf.sprintf(/* Format */{
                      _0: {
                        TAG: /* String */2,
                        _0: /* No_padding */0,
                        _1: {
                          TAG: /* String */2,
                          _0: /* No_padding */0,
                          _1: /* End_of_format */0
                        }
                      },
                      _1: "%s%s"
                    }), prefix, ModelUtils.getGroupTypeNameWithArchiveWithDefaultGroupTypeId(groupTypeId, model)));
}

function propertyVarName$3($$case, property) {
  var enrichedProperty = property.builtIn !== "NotBuiltIn";
  if (enrichedProperty) {
    return Curry._1(Printf.sprintf(/* Format */{
                    _0: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: /* End_of_format */0
                    },
                    _1: "%s"
                  }), identifier$3($$case, prefixWithWordIfFirstNumber(PropertyCodegenNameUseCase.get(property))));
  } else {
    return identifier$3($$case, prefixWithWordIfFirstNumber(PropertyCodegenNameUseCase.get(property)));
  }
}

function generateSnippetFromEnrichedModel$3($$event, model, source) {
  var $$event$1 = Belt_Option.getExn(ModelUtils.getEventById($$event.id, model));
  var inputProperties = filterPinnedProperties($$event$1.directPropertyRefs, model.properties, model);
  var inputProperties$1 = AvoModel.resolveProperties(model, inputProperties);
  var partial_arg = Case.snake;
  var $$arguments = snippetArguments(inputProperties$1, (function (eta) {
          return propertyVarName$3(undefined, eta);
        }), $$event$1, model, groupTypeVarName$3, (function (param) {
          return identifier$3(partial_arg, param);
        }), /* Format */{
        _0: {
          TAG: /* String */2,
          _0: /* No_padding */0,
          _1: {
            TAG: /* String_literal */11,
            _0: ": ",
            _1: {
              TAG: /* String */2,
              _0: /* No_padding */0,
              _1: /* End_of_format */0
            }
          }
        },
        _1: "%s: %s"
      }, /* Format */{
        _0: {
          TAG: /* String_literal */11,
          _0: "# ",
          _1: {
            TAG: /* String */2,
            _0: /* No_padding */0,
            _1: /* End_of_format */0
          }
        },
        _1: "# %s"
      }, source);
  var printArguments = function (avoFormat, params) {
    if (!params) {
      return ;
    }
    if (params.tl) {
      return Belt_List.forEachWithIndex(params, (function (index, param) {
                    return AvoFormat.fprintF(avoFormat, "@,%s%s%s", undefined, [
                                param[0],
                                index === (Belt_List.length(params) - 1 | 0) ? "" : ",",
                                commentWithSpace(param[1])
                              ]);
                  }));
    }
    var match = params.hd;
    return AvoFormat.fprintF(avoFormat, "%s%s", undefined, [
                match[0],
                commentWithSpace(match[1])
              ]);
  };
  var avoFormat = AvoFormat.newTextBuilder(undefined);
  AvoFormat.fprintF(avoFormat, "@[<v>", undefined, []);
  if (Belt_List.length($$arguments) > 1) {
    AvoFormat.fprintF(avoFormat, "@[<v 2>Avo.%s(", undefined, [identifier$3(undefined, EventCodegenNameUseCase.get($$event$1))]);
    printArguments(avoFormat, $$arguments);
    AvoFormat.fprintF(avoFormat, "@]@,)", undefined, []);
  } else {
    AvoFormat.fprintF(avoFormat, "Avo.%s(", undefined, [identifier$3(undefined, EventCodegenNameUseCase.get($$event$1))]);
    printArguments(avoFormat, $$arguments);
    AvoFormat.fprintF(avoFormat, ")", undefined, []);
  }
  AvoFormat.fprintF(avoFormat, "@]", undefined, []);
  return AvoFormat.buildText(avoFormat);
}

var Ruby = {
  reservedKeywords: reservedKeywords$3,
  identifier: identifier$3,
  groupTypeVarName: groupTypeVarName$3,
  propertyVarName: propertyVarName$3,
  generateSnippetFromEnrichedModel: generateSnippetFromEnrichedModel$3
};

var reservedKeywords$4 = {
  hd: "and",
  tl: {
    hd: "del",
    tl: {
      hd: "from",
      tl: {
        hd: "not",
        tl: {
          hd: "while",
          tl: {
            hd: "as",
            tl: {
              hd: "elif",
              tl: {
                hd: "global",
                tl: {
                  hd: "or",
                  tl: {
                    hd: "with",
                    tl: {
                      hd: "assert",
                      tl: {
                        hd: "else",
                        tl: {
                          hd: "if",
                          tl: {
                            hd: "pass",
                            tl: {
                              hd: "yield",
                              tl: {
                                hd: "break",
                                tl: {
                                  hd: "except",
                                  tl: {
                                    hd: "import",
                                    tl: {
                                      hd: "print",
                                      tl: {
                                        hd: "class",
                                        tl: {
                                          hd: "exec",
                                          tl: {
                                            hd: "in",
                                            tl: {
                                              hd: "raise",
                                              tl: {
                                                hd: "continue",
                                                tl: {
                                                  hd: "finally",
                                                  tl: {
                                                    hd: "is",
                                                    tl: {
                                                      hd: "return",
                                                      tl: {
                                                        hd: "def",
                                                        tl: {
                                                          hd: "for",
                                                          tl: {
                                                            hd: "lambda",
                                                            tl: {
                                                              hd: "try",
                                                              tl: /* [] */0
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

function identifier$4(caseOpt, name) {
  var $$case = caseOpt !== undefined ? caseOpt : Case.snake;
  var name$1 = Curry._1($$case, name);
  return Curry._2(Printf.sprintf(/* Format */{
                  _0: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: /* End_of_format */0
                    }
                  },
                  _1: "%s%s"
                }), name$1, List.exists((function (param) {
                    return name$1 === param;
                  }), reservedKeywords$4) ? "_" : "");
}

function groupTypeVarName$4(prefixOpt, groupTypeId, model) {
  var prefix = prefixOpt !== undefined ? prefixOpt : "";
  return identifier$4(undefined, Curry._2(Printf.sprintf(/* Format */{
                      _0: {
                        TAG: /* String */2,
                        _0: /* No_padding */0,
                        _1: {
                          TAG: /* String */2,
                          _0: /* No_padding */0,
                          _1: /* End_of_format */0
                        }
                      },
                      _1: "%s%s"
                    }), prefix, ModelUtils.getGroupTypeNameWithArchiveWithDefaultGroupTypeId(groupTypeId, model)));
}

function propertyVarName$4($$case, property) {
  var enrichedProperty = property.builtIn !== "NotBuiltIn";
  if (enrichedProperty) {
    return Curry._1(Printf.sprintf(/* Format */{
                    _0: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* Char_literal */12,
                        _0: /* '_' */95,
                        _1: /* End_of_format */0
                      }
                    },
                    _1: "%s_"
                  }), identifier$4($$case, prefixWithWordIfFirstNumber(PropertyCodegenNameUseCase.get(property))));
  } else {
    return identifier$4($$case, prefixWithWordIfFirstNumber(PropertyCodegenNameUseCase.get(property)));
  }
}

function generateSnippetFromEnrichedModel$4($$event, model, source) {
  var $$event$1 = Belt_Option.getExn(ModelUtils.getEventById($$event.id, model));
  var inputProperties = filterPinnedProperties($$event$1.directPropertyRefs, model.properties, model);
  var inputProperties$1 = AvoModel.resolveProperties(model, inputProperties);
  var partial_arg = Case.snake;
  var $$arguments = snippetArguments(inputProperties$1, (function (eta) {
          return propertyVarName$4(undefined, eta);
        }), $$event$1, model, groupTypeVarName$4, (function (param) {
          return identifier$4(partial_arg, param);
        }), /* Format */{
        _0: {
          TAG: /* String */2,
          _0: /* No_padding */0,
          _1: {
            TAG: /* Char_literal */12,
            _0: /* '=' */61,
            _1: {
              TAG: /* String */2,
              _0: /* No_padding */0,
              _1: /* End_of_format */0
            }
          }
        },
        _1: "%s=%s"
      }, /* Format */{
        _0: {
          TAG: /* String_literal */11,
          _0: "# ",
          _1: {
            TAG: /* String */2,
            _0: /* No_padding */0,
            _1: /* End_of_format */0
          }
        },
        _1: "# %s"
      }, source);
  var printArguments = function (avoFormat, params) {
    if (!params) {
      return ;
    }
    if (params.tl) {
      return Belt_List.forEachWithIndex(params, (function (index, param) {
                    return AvoFormat.fprintF(avoFormat, "@,%s%s%s", undefined, [
                                param[0],
                                index === (Belt_List.length(params) - 1 | 0) ? "" : ",",
                                commentWithSpace(param[1])
                              ]);
                  }));
    }
    var match = params.hd;
    return AvoFormat.fprintF(avoFormat, "%s%s", undefined, [
                match[0],
                commentWithSpace(match[1])
              ]);
  };
  var avoFormat = AvoFormat.newTextBuilder(undefined);
  AvoFormat.fprintF(avoFormat, "@[<v>", undefined, []);
  if (Belt_List.length($$arguments) > 1) {
    AvoFormat.fprintF(avoFormat, "@[<v 2>avo.%s(%a@]@,)", [(function (__x) {
              return printArguments(__x, $$arguments);
            })], [identifier$4(undefined, EventCodegenNameUseCase.get($$event$1))]);
  } else {
    AvoFormat.fprintF(avoFormat, "avo.%s(%a)", [(function (__x) {
              return printArguments(__x, $$arguments);
            })], [identifier$4(undefined, EventCodegenNameUseCase.get($$event$1))]);
  }
  AvoFormat.fprintF(avoFormat, "@]", undefined, []);
  return AvoFormat.buildText(avoFormat);
}

function printPinnedValue(pinnedValue) {
  if (pinnedValue === undefined) {
    return Pervasives.failwith("Invalid pinned value");
  }
  var variant = pinnedValue.NAME;
  if (variant === "BooleanLit") {
    if (pinnedValue.VAL) {
      return "True";
    } else {
      return "False";
    }
  } else if (variant === "StringLit") {
    return Curry._1(Printf.sprintf(/* Format */{
                    _0: {
                      TAG: /* Char_literal */12,
                      _0: /* '"' */34,
                      _1: {
                        TAG: /* String */2,
                        _0: /* No_padding */0,
                        _1: {
                          TAG: /* Char_literal */12,
                          _0: /* '"' */34,
                          _1: /* End_of_format */0
                        }
                      }
                    },
                    _1: "\"%s\""
                  }), pinnedValue.VAL);
  } else if (variant === "FloatLit") {
    return pinnedValue.VAL.toString();
  } else {
    return String(pinnedValue.VAL);
  }
}

var Python = {
  reservedKeywords: reservedKeywords$4,
  identifier: identifier$4,
  groupTypeVarName: groupTypeVarName$4,
  propertyVarName: propertyVarName$4,
  generateSnippetFromEnrichedModel: generateSnippetFromEnrichedModel$4,
  printPinnedValue: printPinnedValue
};

var reservedKeywords$5 = {
  hd: "__halt_compiler",
  tl: {
    hd: "abstract",
    tl: {
      hd: "and",
      tl: {
        hd: "array",
        tl: {
          hd: "as",
          tl: {
            hd: "break",
            tl: {
              hd: "callable",
              tl: {
                hd: "case",
                tl: {
                  hd: "catch",
                  tl: {
                    hd: "class",
                    tl: {
                      hd: "clone",
                      tl: {
                        hd: "const",
                        tl: {
                          hd: "continue",
                          tl: {
                            hd: "declare",
                            tl: {
                              hd: "default",
                              tl: {
                                hd: "die",
                                tl: {
                                  hd: "do",
                                  tl: {
                                    hd: "echo",
                                    tl: {
                                      hd: "else",
                                      tl: {
                                        hd: "elseif",
                                        tl: {
                                          hd: "empty",
                                          tl: {
                                            hd: "enddeclare",
                                            tl: {
                                              hd: "endfor",
                                              tl: {
                                                hd: "endforeach",
                                                tl: {
                                                  hd: "endif",
                                                  tl: {
                                                    hd: "endswitch",
                                                    tl: {
                                                      hd: "endwhile",
                                                      tl: {
                                                        hd: "eval",
                                                        tl: {
                                                          hd: "exit",
                                                          tl: {
                                                            hd: "extends",
                                                            tl: {
                                                              hd: "final",
                                                              tl: {
                                                                hd: "finally",
                                                                tl: {
                                                                  hd: "for",
                                                                  tl: {
                                                                    hd: "foreach",
                                                                    tl: {
                                                                      hd: "function",
                                                                      tl: {
                                                                        hd: "global",
                                                                        tl: {
                                                                          hd: "goto",
                                                                          tl: {
                                                                            hd: "if",
                                                                            tl: {
                                                                              hd: "implements",
                                                                              tl: {
                                                                                hd: "include",
                                                                                tl: {
                                                                                  hd: "include_once",
                                                                                  tl: {
                                                                                    hd: "instanceof",
                                                                                    tl: {
                                                                                      hd: "insteadof",
                                                                                      tl: {
                                                                                        hd: "interface",
                                                                                        tl: {
                                                                                          hd: "isset",
                                                                                          tl: {
                                                                                            hd: "list",
                                                                                            tl: {
                                                                                              hd: "namespace",
                                                                                              tl: {
                                                                                                hd: "new",
                                                                                                tl: {
                                                                                                  hd: "or",
                                                                                                  tl: {
                                                                                                    hd: "print",
                                                                                                    tl: {
                                                                                                      hd: "private",
                                                                                                      tl: {
                                                                                                        hd: "protected",
                                                                                                        tl: {
                                                                                                          hd: "public",
                                                                                                          tl: {
                                                                                                            hd: "require",
                                                                                                            tl: {
                                                                                                              hd: "require_once",
                                                                                                              tl: {
                                                                                                                hd: "return",
                                                                                                                tl: {
                                                                                                                  hd: "static",
                                                                                                                  tl: {
                                                                                                                    hd: "switch",
                                                                                                                    tl: {
                                                                                                                      hd: "throw",
                                                                                                                      tl: {
                                                                                                                        hd: "trait",
                                                                                                                        tl: {
                                                                                                                          hd: "try",
                                                                                                                          tl: {
                                                                                                                            hd: "unset",
                                                                                                                            tl: {
                                                                                                                              hd: "use",
                                                                                                                              tl: {
                                                                                                                                hd: "var",
                                                                                                                                tl: {
                                                                                                                                  hd: "while",
                                                                                                                                  tl: {
                                                                                                                                    hd: "xor",
                                                                                                                                    tl: {
                                                                                                                                      hd: "yield",
                                                                                                                                      tl: {
                                                                                                                                        hd: "yield from",
                                                                                                                                        tl: /* [] */0
                                                                                                                                      }
                                                                                                                                    }
                                                                                                                                  }
                                                                                                                                }
                                                                                                                              }
                                                                                                                            }
                                                                                                                          }
                                                                                                                        }
                                                                                                                      }
                                                                                                                    }
                                                                                                                  }
                                                                                                                }
                                                                                                              }
                                                                                                            }
                                                                                                          }
                                                                                                        }
                                                                                                      }
                                                                                                    }
                                                                                                  }
                                                                                                }
                                                                                              }
                                                                                            }
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

function identifier$5(caseOpt, name) {
  var $$case = caseOpt !== undefined ? caseOpt : Case.snake;
  var name$1 = Curry._1($$case, name);
  return Curry._2(Printf.sprintf(/* Format */{
                  _0: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: /* End_of_format */0
                    }
                  },
                  _1: "%s%s"
                }), name$1, List.exists((function (param) {
                    return name$1 === param;
                  }), reservedKeywords$5) ? "_" : "");
}

function groupTypeVarName$5(prefixOpt, groupTypeId, model) {
  var prefix = prefixOpt !== undefined ? prefixOpt : "";
  return identifier$5(undefined, Curry._2(Printf.sprintf(/* Format */{
                      _0: {
                        TAG: /* String */2,
                        _0: /* No_padding */0,
                        _1: {
                          TAG: /* String */2,
                          _0: /* No_padding */0,
                          _1: /* End_of_format */0
                        }
                      },
                      _1: "%s%s"
                    }), prefix, ModelUtils.getGroupTypeNameWithArchiveWithDefaultGroupTypeId(groupTypeId, model)));
}

function propertyVarName$5($$case, property) {
  var enrichedProperty = property.builtIn !== "NotBuiltIn";
  if (enrichedProperty) {
    return Curry._1(Printf.sprintf(/* Format */{
                    _0: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* Char_literal */12,
                        _0: /* '_' */95,
                        _1: /* End_of_format */0
                      }
                    },
                    _1: "%s_"
                  }), identifier$5($$case, prefixWithWordIfFirstNumber(PropertyCodegenNameUseCase.get(property))));
  } else {
    return identifier$5($$case, prefixWithWordIfFirstNumber(PropertyCodegenNameUseCase.get(property)));
  }
}

function generateSnippetFromEnrichedModel$5($$event, model, source) {
  var $$event$1 = Belt_Option.getExn(ModelUtils.getEventById($$event.id, model));
  var inputProperties = filterPinnedProperties($$event$1.directPropertyRefs, model.properties, model);
  var inputProperties$1 = AvoModel.resolveProperties(model, inputProperties);
  var partial_arg = Case.camel;
  var $$arguments = snippetArguments(inputProperties$1, (function (eta) {
          return propertyVarName$5(undefined, eta);
        }), $$event$1, model, groupTypeVarName$5, (function (param) {
          return identifier$5(partial_arg, param);
        }), /* Format */{
        _0: {
          TAG: /* String */2,
          _0: /* No_padding */0,
          _1: {
            TAG: /* Char_literal */12,
            _0: /* '=' */61,
            _1: {
              TAG: /* String */2,
              _0: /* No_padding */0,
              _1: /* End_of_format */0
            }
          }
        },
        _1: "%s=%s"
      }, /* Format */{
        _0: {
          TAG: /* String_literal */11,
          _0: "// ",
          _1: {
            TAG: /* String */2,
            _0: /* No_padding */0,
            _1: /* End_of_format */0
          }
        },
        _1: "// %s"
      }, source);
  var printArguments = function (ppf, params) {
    if (!params) {
      return ;
    }
    if (params.tl) {
      return Belt_List.forEachWithIndex(params, (function (index, param) {
                    return AvoFormat.fprintF(ppf, "@,%s%s%s", undefined, [
                                param[0],
                                index === (Belt_List.length(params) - 1 | 0) ? "" : ",",
                                commentWithSpace(param[1])
                              ]);
                  }));
    }
    var match = params.hd;
    return AvoFormat.fprintF(ppf, "%s%s", undefined, [
                match[0],
                commentWithSpace(match[1])
              ]);
  };
  var avoFormat = AvoFormat.newTextBuilder(undefined);
  AvoFormat.fprintF(avoFormat, "@[<v>", undefined, []);
  if (Belt_List.length($$arguments) > 1) {
    AvoFormat.fprintF(avoFormat, "@[<v 2>Avo::%s(%a@]@,)", [(function (__x) {
              return printArguments(__x, $$arguments);
            })], [identifier$5(undefined, EventCodegenNameUseCase.get($$event$1))]);
  } else {
    AvoFormat.fprintF(avoFormat, "Avo::%s(%a)", [(function (__x) {
              return printArguments(__x, $$arguments);
            })], [identifier$5(undefined, EventCodegenNameUseCase.get($$event$1))]);
  }
  AvoFormat.fprintF(avoFormat, "@]", undefined, []);
  return AvoFormat.buildText(avoFormat);
}

function printPinnedValue$1(pinnedValue) {
  if (pinnedValue === undefined) {
    return Pervasives.failwith("Invalid pinned value");
  }
  var variant = pinnedValue.NAME;
  if (variant === "BooleanLit") {
    if (pinnedValue.VAL) {
      return "TRUE";
    } else {
      return "FALSE";
    }
  } else if (variant === "StringLit") {
    return Curry._1(Printf.sprintf(/* Format */{
                    _0: {
                      TAG: /* Char_literal */12,
                      _0: /* '\'' */39,
                      _1: {
                        TAG: /* String */2,
                        _0: /* No_padding */0,
                        _1: {
                          TAG: /* Char_literal */12,
                          _0: /* '\'' */39,
                          _1: /* End_of_format */0
                        }
                      }
                    },
                    _1: "'%s'"
                  }), pinnedValue.VAL);
  } else if (variant === "FloatLit") {
    return pinnedValue.VAL.toString();
  } else {
    return String(pinnedValue.VAL);
  }
}

var PHP = {
  reservedKeywords: reservedKeywords$5,
  identifier: identifier$5,
  groupTypeVarName: groupTypeVarName$5,
  propertyVarName: propertyVarName$5,
  generateSnippetFromEnrichedModel: generateSnippetFromEnrichedModel$5,
  printPinnedValue: printPinnedValue$1
};

var reservedKeywords$6 = {
  hd: "abstract",
  tl: {
    hd: "continue",
    tl: {
      hd: "for",
      tl: {
        hd: "new",
        tl: {
          hd: "switch",
          tl: {
            hd: "assert",
            tl: {
              hd: "default",
              tl: {
                hd: "goto",
                tl: {
                  hd: "package",
                  tl: {
                    hd: "synchronized",
                    tl: {
                      hd: "boolean",
                      tl: {
                        hd: "do",
                        tl: {
                          hd: "if",
                          tl: {
                            hd: "private",
                            tl: {
                              hd: "this",
                              tl: {
                                hd: "break",
                                tl: {
                                  hd: "double",
                                  tl: {
                                    hd: "implements",
                                    tl: {
                                      hd: "protected",
                                      tl: {
                                        hd: "throw",
                                        tl: {
                                          hd: "byte",
                                          tl: {
                                            hd: "else",
                                            tl: {
                                              hd: "import",
                                              tl: {
                                                hd: "public",
                                                tl: {
                                                  hd: "throws",
                                                  tl: {
                                                    hd: "case",
                                                    tl: {
                                                      hd: "enum",
                                                      tl: {
                                                        hd: "instanceof",
                                                        tl: {
                                                          hd: "return",
                                                          tl: {
                                                            hd: "transient",
                                                            tl: {
                                                              hd: "catch",
                                                              tl: {
                                                                hd: "extends",
                                                                tl: {
                                                                  hd: "int",
                                                                  tl: {
                                                                    hd: "short",
                                                                    tl: {
                                                                      hd: "try",
                                                                      tl: {
                                                                        hd: "char",
                                                                        tl: {
                                                                          hd: "final",
                                                                          tl: {
                                                                            hd: "interface",
                                                                            tl: {
                                                                              hd: "static",
                                                                              tl: {
                                                                                hd: "void",
                                                                                tl: {
                                                                                  hd: "class",
                                                                                  tl: {
                                                                                    hd: "finally",
                                                                                    tl: {
                                                                                      hd: "long",
                                                                                      tl: {
                                                                                        hd: "strictfp",
                                                                                        tl: {
                                                                                          hd: "volatile",
                                                                                          tl: {
                                                                                            hd: "const",
                                                                                            tl: {
                                                                                              hd: "float",
                                                                                              tl: {
                                                                                                hd: "native",
                                                                                                tl: {
                                                                                                  hd: "super",
                                                                                                  tl: {
                                                                                                    hd: "while",
                                                                                                    tl: /* [] */0
                                                                                                  }
                                                                                                }
                                                                                              }
                                                                                            }
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

function identifier$6(caseOpt, name) {
  var $$case = caseOpt !== undefined ? caseOpt : Case.camel;
  var name$1 = Curry._1($$case, name);
  return Curry._2(Printf.sprintf(/* Format */{
                  _0: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: /* End_of_format */0
                    }
                  },
                  _1: "%s%s"
                }), name$1, List.exists((function (param) {
                    return name$1 === param;
                  }), reservedKeywords$6) ? "_" : "");
}

function groupTypeVarName$6(prefixOpt, groupTypeId, model) {
  var prefix = prefixOpt !== undefined ? prefixOpt : "";
  return Curry._2(Printf.sprintf(/* Format */{
                  _0: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: /* End_of_format */0
                    }
                  },
                  _1: "%s%s"
                }), prefix, identifier$6(Case.pascal, ModelUtils.getGroupTypeNameWithArchiveWithDefaultGroupTypeId(groupTypeId, model)));
}

function propertyVarName$6($$case, property) {
  var enrichedProperty = property.builtIn !== "NotBuiltIn";
  if (enrichedProperty) {
    return Curry._1(Printf.sprintf(/* Format */{
                    _0: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* Char_literal */12,
                        _0: /* '_' */95,
                        _1: /* End_of_format */0
                      }
                    },
                    _1: "%s_"
                  }), identifier$6($$case, prefixWithWordIfFirstNumber(PropertyCodegenNameUseCase.get(property))));
  } else {
    return identifier$6($$case, prefixWithWordIfFirstNumber(PropertyCodegenNameUseCase.get(property)));
  }
}

function generateSnippetFromEnrichedModel$6($$event, model, source) {
  var $$event$1 = Belt_Option.getExn(ModelUtils.getEventById($$event.id, model));
  var inputProperties = filterPinnedProperties($$event$1.directPropertyRefs, model.properties, model);
  var inputProperties$1 = AvoModel.resolveProperties(model, inputProperties);
  var partial_arg = Case.camel;
  var $$arguments = Belt_List.mapU(snippetArguments(inputProperties$1, (function (eta) {
              return propertyVarName$6(undefined, eta);
            }), $$event$1, model, groupTypeVarName$6, (function (param) {
              return identifier$6(partial_arg, param);
            }), /* Format */{
            _0: {
              TAG: /* String */2,
              _0: /* No_padding */0,
              _1: {
                TAG: /* Char_literal */12,
                _0: /* ':' */58,
                _1: {
                  TAG: /* String */2,
                  _0: /* No_padding */0,
                  _1: /* End_of_format */0
                }
              }
            },
            _1: "%s:%s"
          }, /* Format */{
            _0: {
              TAG: /* String_literal */11,
              _0: "// ",
              _1: {
                TAG: /* String */2,
                _0: /* No_padding */0,
                _1: /* End_of_format */0
              }
            },
            _1: "// %s"
          }, source), (function (param) {
          return [
                  Caml_array.get(param[0].split(":"), 1),
                  param[1]
                ];
        }));
  var printArguments = function (ppf, params) {
    if (!params) {
      return ;
    }
    if (params.tl) {
      return Belt_List.forEachWithIndex(params, (function (index, param) {
                    return AvoFormat.fprintF(ppf, "@,%s%s%s", undefined, [
                                param[0],
                                index === (Belt_List.length(params) - 1 | 0) ? "" : ",",
                                commentWithSpace(param[1])
                              ]);
                  }));
    }
    var match = params.hd;
    return AvoFormat.fprintF(ppf, "%s%s", undefined, [
                match[0],
                commentWithSpace(match[1])
              ]);
  };
  var avoFormat = AvoFormat.newTextBuilder(undefined);
  AvoFormat.fprintF(avoFormat, "@[<v>", undefined, []);
  if (Belt_List.length($$arguments) > 1) {
    AvoFormat.fprintF(avoFormat, "@[<v 2>Avo.%s(%a@]@,);", [(function (__x) {
              return printArguments(__x, $$arguments);
            })], [identifier$6(undefined, EventCodegenNameUseCase.get($$event$1))]);
  } else {
    AvoFormat.fprintF(avoFormat, "Avo.%s(%a);", [(function (__x) {
              return printArguments(__x, $$arguments);
            })], [identifier$6(undefined, EventCodegenNameUseCase.get($$event$1))]);
  }
  AvoFormat.fprintF(avoFormat, "@]", undefined, []);
  return AvoFormat.buildText(avoFormat);
}

var Java = {
  reservedKeywords: reservedKeywords$6,
  identifier: identifier$6,
  groupTypeVarName: groupTypeVarName$6,
  propertyVarName: propertyVarName$6,
  generateSnippetFromEnrichedModel: generateSnippetFromEnrichedModel$6
};

var reservedKeywords$7 = {
  hd: "package",
  tl: {
    hd: "as",
    tl: {
      hd: "typealias",
      tl: {
        hd: "class",
        tl: {
          hd: "this",
          tl: {
            hd: "super",
            tl: {
              hd: "val",
              tl: {
                hd: "var",
                tl: {
                  hd: "fun",
                  tl: {
                    hd: "for",
                    tl: {
                      hd: "null",
                      tl: {
                        hd: "true",
                        tl: {
                          hd: "false",
                          tl: {
                            hd: "is",
                            tl: {
                              hd: "in",
                              tl: {
                                hd: "throw",
                                tl: {
                                  hd: "return",
                                  tl: {
                                    hd: "break",
                                    tl: {
                                      hd: "continue",
                                      tl: {
                                        hd: "object",
                                        tl: {
                                          hd: "if",
                                          tl: {
                                            hd: "try",
                                            tl: {
                                              hd: "else",
                                              tl: {
                                                hd: "while",
                                                tl: {
                                                  hd: "do",
                                                  tl: {
                                                    hd: "when",
                                                    tl: {
                                                      hd: "interface",
                                                      tl: {
                                                        hd: "typeof",
                                                        tl: /* [] */0
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

function identifier$7(caseOpt, name) {
  var $$case = caseOpt !== undefined ? caseOpt : Case.camel;
  var allKindsOfDashes = /–|—|—|―|‒/g;
  var name$1 = Curry._1($$case, name).replace(allKindsOfDashes, "Dash");
  var name$2 = prefixWithWordIfFirstNumberWithCase(name$1, $$case);
  var containsDollarSign = name$2.indexOf("$") !== -1;
  var name$3 = containsDollarSign ? "`" + (name$2 + "`") : name$2;
  return Curry._2(Printf.sprintf(/* Format */{
                  _0: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: /* End_of_format */0
                    }
                  },
                  _1: "%s%s"
                }), name$3, List.exists((function (param) {
                    return name$3 === param;
                  }), reservedKeywords$7) ? "_" : "");
}

function groupTypeVarName$7(prefixOpt, groupTypeId, model) {
  var prefix = prefixOpt !== undefined ? prefixOpt : "";
  return Curry._2(Printf.sprintf(/* Format */{
                  _0: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: /* End_of_format */0
                    }
                  },
                  _1: "%s%s"
                }), prefix, identifier$7(Case.pascal, ModelUtils.getGroupTypeNameWithArchiveWithDefaultGroupTypeId(groupTypeId, model)));
}

function propertyVarName$7($$case, property) {
  var enrichedProperty = property.builtIn !== "NotBuiltIn";
  if (enrichedProperty) {
    return Curry._1(Printf.sprintf(/* Format */{
                    _0: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* Char_literal */12,
                        _0: /* '_' */95,
                        _1: /* End_of_format */0
                      }
                    },
                    _1: "%s_"
                  }), identifier$7($$case, prefixWithWordIfFirstNumber(PropertyCodegenNameUseCase.get(property))));
  } else {
    return identifier$7($$case, prefixWithWordIfFirstNumber(PropertyCodegenNameUseCase.get(property)));
  }
}

function generateSnippetFromEnrichedModel$7($$event, model, source) {
  var $$event$1 = Belt_Option.getExn(ModelUtils.getEventById($$event.id, model));
  var flattenGroups = Belt_List.someU(source.config, (function (config) {
          return config === "ForceFlattenPropertyBundlesInCodegen";
        }));
  var inputGroups = flattenGroups ? /* [] */0 : AvoModel.resolvePropertyBundleRefs(model.propertyBundles, $$event$1.propertyBundles);
  var inputProperties = filterPinnedProperties($$event$1.directPropertyRefs, model.properties, model);
  var inputProperties$1 = flattenGroups ? AvoModel.resolveProperties(model, inputProperties) : Belt_List.keep(AvoModel.resolveProperties(model, inputProperties), (function (property) {
            return !Belt_List.some(inputGroups, (function (group) {
                          return Belt_List.some(group.properties, (function (propertyId) {
                                        return property.id === propertyId;
                                      }));
                        }));
          }));
  var propertyNameMaps = getPropertyNameMaps($$event$1, source, model);
  var propertyGroupsArgs = Belt_List.map(inputGroups, (function (group) {
          var comment = printPropertyGroupTypeInPseudocode(group.properties, (function (eta) {
                  return propertyVarName$7(undefined, eta);
                }), (function (eta) {
                  return identifier$7(undefined, eta);
                }), model, $$event$1, propertyNameMaps, source);
          return [
                  Curry._2(Printf.sprintf(/* Format */{
                            _0: {
                              TAG: /* String */2,
                              _0: /* No_padding */0,
                              _1: {
                                TAG: /* Char_literal */12,
                                _0: /* '=' */61,
                                _1: {
                                  TAG: /* String */2,
                                  _0: /* No_padding */0,
                                  _1: /* End_of_format */0
                                }
                              }
                            },
                            _1: "%s=%s"
                          }), identifier$7(undefined, group.name), identifier$7(undefined, group.name)),
                  comment
                ];
        }));
  var partial_arg = Case.camel;
  var $$arguments = snippetArguments(inputProperties$1, (function (eta) {
          return propertyVarName$7(undefined, eta);
        }), $$event$1, model, groupTypeVarName$7, (function (param) {
          return identifier$7(partial_arg, param);
        }), /* Format */{
        _0: {
          TAG: /* String */2,
          _0: /* No_padding */0,
          _1: {
            TAG: /* Char_literal */12,
            _0: /* '=' */61,
            _1: {
              TAG: /* String */2,
              _0: /* No_padding */0,
              _1: /* End_of_format */0
            }
          }
        },
        _1: "%s=%s"
      }, /* Format */{
        _0: {
          TAG: /* String_literal */11,
          _0: "// ",
          _1: {
            TAG: /* String */2,
            _0: /* No_padding */0,
            _1: /* End_of_format */0
          }
        },
        _1: "// %s"
      }, source);
  var printArguments = function (avoFormat, params) {
    if (!params) {
      return ;
    }
    if (params.tl) {
      return Belt_List.forEachWithIndex(params, (function (index, param) {
                    return AvoFormat.fprintF(avoFormat, "@,%s%s%s", undefined, [
                                param[0],
                                index === (Belt_List.length(params) - 1 | 0) ? "" : ",",
                                commentWithSpace(param[1])
                              ]);
                  }));
    }
    var match = params.hd;
    return AvoFormat.fprintF(avoFormat, "%s%s", undefined, [
                match[0],
                commentWithSpace(match[1])
              ]);
  };
  var avoFormat = AvoFormat.newTextBuilder(undefined);
  AvoFormat.fprintF(avoFormat, "@[<v>", undefined, []);
  var argsLength = Belt_List.length($$arguments) + Belt_List.length(propertyGroupsArgs) | 0;
  if (argsLength > 1) {
    AvoFormat.fprintF(avoFormat, "@[<v 2>Avo.%s(%a%a@]@,)", [
          (function (__x) {
              return printPropertyGroupArguments(__x, propertyGroupsArgs, "/*", "*/");
            }),
          (function (__x) {
              return printArguments(__x, $$arguments);
            })
        ], [identifier$7(undefined, EventCodegenNameUseCase.get($$event$1))]);
  } else {
    AvoFormat.fprintF(avoFormat, "Avo.%s(%a%a)", [
          (function (__x) {
              return printPropertyGroupArguments(__x, propertyGroupsArgs, "/*", "*/");
            }),
          (function (__x) {
              return printArguments(__x, $$arguments);
            })
        ], [identifier$7(undefined, EventCodegenNameUseCase.get($$event$1))]);
  }
  AvoFormat.fprintF(avoFormat, "@]", undefined, []);
  return AvoFormat.buildText(avoFormat);
}

function pinnedPropertyToString(propertyRef, property, hasMatchValidation, optional) {
  var propertyValue = printPinnedValueExn(propertyRef.pinnedValue);
  if (optional || !(property.type_ === "string" && !Curry._1(hasMatchValidation, property))) {
    return Curry._1(Printf.sprintf(/* Format */{
                    _0: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* String_literal */11,
                        _0: ".toString()",
                        _1: /* End_of_format */0
                      }
                    },
                    _1: "%s.toString()"
                  }), propertyValue);
  } else {
    return Curry._1(Printf.sprintf(/* Format */{
                    _0: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: /* End_of_format */0
                    },
                    _1: "%s"
                  }), propertyValue);
  }
}

var Kotlin = {
  reservedKeywords: reservedKeywords$7,
  identifier: identifier$7,
  groupTypeVarName: groupTypeVarName$7,
  propertyVarName: propertyVarName$7,
  generateSnippetFromEnrichedModel: generateSnippetFromEnrichedModel$7,
  pinnedPropertyToString: pinnedPropertyToString
};

var reservedKeywords$8 = {
  hd: "auto",
  tl: {
    hd: "break",
    tl: {
      hd: "case",
      tl: {
        hd: "char",
        tl: {
          hd: "const",
          tl: {
            hd: "continue",
            tl: {
              hd: "default",
              tl: {
                hd: "do",
                tl: {
                  hd: "double",
                  tl: {
                    hd: "else",
                    tl: {
                      hd: "enum",
                      tl: {
                        hd: "extern",
                        tl: {
                          hd: "float",
                          tl: {
                            hd: "for",
                            tl: {
                              hd: "goto",
                              tl: {
                                hd: "if",
                                tl: {
                                  hd: "inline",
                                  tl: {
                                    hd: "int",
                                    tl: {
                                      hd: "long",
                                      tl: {
                                        hd: "register",
                                        tl: {
                                          hd: "restrict",
                                          tl: {
                                            hd: "return",
                                            tl: {
                                              hd: "short",
                                              tl: {
                                                hd: "signed",
                                                tl: {
                                                  hd: "sizeof",
                                                  tl: {
                                                    hd: "static",
                                                    tl: {
                                                      hd: "struct",
                                                      tl: {
                                                        hd: "switch",
                                                        tl: {
                                                          hd: "typedef",
                                                          tl: {
                                                            hd: "union",
                                                            tl: {
                                                              hd: "unsigned",
                                                              tl: {
                                                                hd: "void",
                                                                tl: {
                                                                  hd: "volatile",
                                                                  tl: {
                                                                    hd: "while",
                                                                    tl: {
                                                                      hd: "_Bool",
                                                                      tl: {
                                                                        hd: "_Complex",
                                                                        tl: {
                                                                          hd: "_Imaginary",
                                                                          tl: {
                                                                            hd: "BOOL",
                                                                            tl: {
                                                                              hd: "Class",
                                                                              tl: {
                                                                                hd: "bycopy",
                                                                                tl: {
                                                                                  hd: "byref",
                                                                                  tl: {
                                                                                    hd: "id",
                                                                                    tl: {
                                                                                      hd: "IMP",
                                                                                      tl: {
                                                                                        hd: "in",
                                                                                        tl: {
                                                                                          hd: "inout",
                                                                                          tl: {
                                                                                            hd: "nil",
                                                                                            tl: {
                                                                                              hd: "NO",
                                                                                              tl: {
                                                                                                hd: "NULL",
                                                                                                tl: {
                                                                                                  hd: "oneway",
                                                                                                  tl: {
                                                                                                    hd: "out",
                                                                                                    tl: {
                                                                                                      hd: "Protocol",
                                                                                                      tl: {
                                                                                                        hd: "SEL",
                                                                                                        tl: {
                                                                                                          hd: "self",
                                                                                                          tl: {
                                                                                                            hd: "Super",
                                                                                                            tl: {
                                                                                                              hd: "YES",
                                                                                                              tl: {
                                                                                                                hd: "@interface",
                                                                                                                tl: {
                                                                                                                  hd: "@end",
                                                                                                                  tl: {
                                                                                                                    hd: "@implementation",
                                                                                                                    tl: {
                                                                                                                      hd: "@protocol",
                                                                                                                      tl: {
                                                                                                                        hd: "@class",
                                                                                                                        tl: {
                                                                                                                          hd: "@public",
                                                                                                                          tl: {
                                                                                                                            hd: "@protected",
                                                                                                                            tl: {
                                                                                                                              hd: "@private",
                                                                                                                              tl: {
                                                                                                                                hd: "@property",
                                                                                                                                tl: {
                                                                                                                                  hd: "@try",
                                                                                                                                  tl: {
                                                                                                                                    hd: "@throw",
                                                                                                                                    tl: {
                                                                                                                                      hd: "@catch",
                                                                                                                                      tl: {
                                                                                                                                        hd: "@finally",
                                                                                                                                        tl: {
                                                                                                                                          hd: "@synthesize",
                                                                                                                                          tl: {
                                                                                                                                            hd: "@dynamic",
                                                                                                                                            tl: {
                                                                                                                                              hd: "@selector",
                                                                                                                                              tl: {
                                                                                                                                                hd: "atomic",
                                                                                                                                                tl: {
                                                                                                                                                  hd: "nonatomic",
                                                                                                                                                  tl: {
                                                                                                                                                    hd: "retain",
                                                                                                                                                    tl: /* [] */0
                                                                                                                                                  }
                                                                                                                                                }
                                                                                                                                              }
                                                                                                                                            }
                                                                                                                                          }
                                                                                                                                        }
                                                                                                                                      }
                                                                                                                                    }
                                                                                                                                  }
                                                                                                                                }
                                                                                                                              }
                                                                                                                            }
                                                                                                                          }
                                                                                                                        }
                                                                                                                      }
                                                                                                                    }
                                                                                                                  }
                                                                                                                }
                                                                                                              }
                                                                                                            }
                                                                                                          }
                                                                                                        }
                                                                                                      }
                                                                                                    }
                                                                                                  }
                                                                                                }
                                                                                              }
                                                                                            }
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

function identifier$8(caseOpt, name) {
  var $$case = caseOpt !== undefined ? caseOpt : Case.camel;
  var name$1 = Curry._1($$case, name);
  return Curry._2(Printf.sprintf(/* Format */{
                  _0: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: /* End_of_format */0
                    }
                  },
                  _1: "%s%s"
                }), name$1, List.exists((function (param) {
                    return name$1 === param;
                  }), reservedKeywords$8) ? "_" : "");
}

function groupTypeVarName$8(prefixOpt, groupTypeId, model) {
  var prefix = prefixOpt !== undefined ? prefixOpt : "";
  return Curry._2(Printf.sprintf(/* Format */{
                  _0: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: /* End_of_format */0
                    }
                  },
                  _1: "%s%s"
                }), prefix, identifier$8(Case.pascal, ModelUtils.getGroupTypeNameWithArchiveWithDefaultGroupTypeId(groupTypeId, model)));
}

function propertyVarName$8($$case, property) {
  var enrichedProperty = property.builtIn !== "NotBuiltIn";
  if (enrichedProperty) {
    return Curry._1(Printf.sprintf(/* Format */{
                    _0: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* Char_literal */12,
                        _0: /* '_' */95,
                        _1: /* End_of_format */0
                      }
                    },
                    _1: "%s_"
                  }), identifier$8($$case, prefixWithWordIfFirstNumber(PropertyCodegenNameUseCase.get(property))));
  } else {
    return identifier$8($$case, prefixWithWordIfFirstNumber(PropertyCodegenNameUseCase.get(property)));
  }
}

function printApplyParams(ppf, params) {
  if (!params) {
    return Format.fprintf(ppf, /* Format */{
                _0: /* End_of_format */0,
                _1: ""
              });
  }
  var rest = params.tl;
  var param = params.hd;
  if (rest) {
    return Curry._4(Format.fprintf(ppf, /* Format */{
                    _0: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* Char_literal */12,
                        _0: /* ':' */58,
                        _1: {
                          TAG: /* String */2,
                          _0: /* No_padding */0,
                          _1: {
                            TAG: /* Formatting_lit */17,
                            _0: {
                              TAG: /* Break */0,
                              _0: "@ ",
                              _1: 1,
                              _2: 0
                            },
                            _1: {
                              TAG: /* Alpha */15,
                              _0: /* End_of_format */0
                            }
                          }
                        }
                      }
                    },
                    _1: "%s:%s@ %a"
                  }), param, param, printApplyParams, rest);
  } else {
    return Curry._2(Format.fprintf(ppf, /* Format */{
                    _0: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* Char_literal */12,
                        _0: /* ':' */58,
                        _1: {
                          TAG: /* String */2,
                          _0: /* No_padding */0,
                          _1: /* End_of_format */0
                        }
                      }
                    },
                    _1: "%s:%s"
                  }), param, param);
  }
}

function printApplyParams$1(ppf, params) {
  if (!params) {
    return Format.fprintf(ppf, /* Format */{
                _0: /* End_of_format */0,
                _1: ""
              });
  }
  var rest = params.tl;
  var param = params.hd;
  if (rest) {
    return Curry._3(Format.fprintf(ppf, /* Format */{
                    _0: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* Formatting_lit */17,
                        _0: {
                          TAG: /* Break */0,
                          _0: "@ ",
                          _1: 1,
                          _2: 0
                        },
                        _1: {
                          TAG: /* Alpha */15,
                          _0: /* End_of_format */0
                        }
                      }
                    },
                    _1: "%s@ %a"
                  }), param, printApplyParams, rest);
  } else {
    return Curry._1(Format.fprintf(ppf, /* Format */{
                    _0: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: /* End_of_format */0
                    },
                    _1: "%s"
                  }), param);
  }
}

function generateSnippetFromEnrichedModel$8($$event, model, source) {
  var $$event$1 = Belt_Option.getExn(ModelUtils.getEventById($$event.id, model));
  var flattenGroups = Belt_List.someU(source.config, (function (config) {
          return config === "ForceFlattenPropertyBundlesInCodegen";
        }));
  var inputGroups = flattenGroups ? /* [] */0 : AvoModel.resolvePropertyBundleRefs(model.propertyBundles, $$event$1.propertyBundles);
  var inputProperties = filterPinnedProperties($$event$1.directPropertyRefs, model.properties, model);
  var inputProperties$1 = flattenGroups ? AvoModel.resolveProperties(model, inputProperties) : Belt_List.keep(AvoModel.resolveProperties(model, inputProperties), (function (property) {
            return !Belt_List.some(inputGroups, (function (group) {
                          return Belt_List.some(group.properties, (function (propertyId) {
                                        return property.id === propertyId;
                                      }));
                        }));
          }));
  var propertyNameMaps = getPropertyNameMaps($$event$1, source, model);
  var propertyGroupsArgs = Belt_List.map(inputGroups, (function (group) {
          var comment = printPropertyGroupTypeInPseudocode(group.properties, (function (eta) {
                  return propertyVarName$8(undefined, eta);
                }), (function (eta) {
                  return identifier$8(undefined, eta);
                }), model, $$event$1, propertyNameMaps, source);
          return [
                  Curry._2(Printf.sprintf(/* Format */{
                            _0: {
                              TAG: /* String */2,
                              _0: /* No_padding */0,
                              _1: {
                                TAG: /* Char_literal */12,
                                _0: /* ':' */58,
                                _1: {
                                  TAG: /* String */2,
                                  _0: /* No_padding */0,
                                  _1: /* End_of_format */0
                                }
                              }
                            },
                            _1: "%s:%s"
                          }), identifier$8(undefined, group.name), identifier$8(undefined, group.name)),
                  comment
                ];
        }));
  var partial_arg = Case.pascal;
  var $$arguments = snippetArguments(inputProperties$1, (function (eta) {
          return propertyVarName$8(undefined, eta);
        }), $$event$1, model, groupTypeVarName$8, (function (param) {
          return identifier$8(partial_arg, param);
        }), /* Format */{
        _0: {
          TAG: /* String */2,
          _0: /* No_padding */0,
          _1: {
            TAG: /* Char_literal */12,
            _0: /* ':' */58,
            _1: {
              TAG: /* String */2,
              _0: /* No_padding */0,
              _1: /* End_of_format */0
            }
          }
        },
        _1: "%s:%s"
      }, /* Format */{
        _0: {
          TAG: /* String_literal */11,
          _0: "// ",
          _1: {
            TAG: /* String */2,
            _0: /* No_padding */0,
            _1: /* End_of_format */0
          }
        },
        _1: "// %s"
      }, source);
  var ppf = AvoFormat.newTextBuilder(undefined);
  AvoFormat.fprintF(ppf, "@[<v>", undefined, []);
  var argsLength = Belt_List.length($$arguments) + Belt_List.length(propertyGroupsArgs) | 0;
  if (argsLength > 0) {
    AvoFormat.fprintF(ppf, "@[<v 2>[Avo %sWith%a%a@]];", [
          (function (__x) {
              return printPropertyGroupArguments(__x, propertyGroupsArgs, "/*", "*/");
            }),
          (function (__x) {
              if (!$$arguments) {
                return ;
              }
              if ($$arguments.tl) {
                return Belt_List.forEach($$arguments, (function (param) {
                              return AvoFormat.fprintF(__x, "@,%s,%s", undefined, [
                                          param[0],
                                          commentWithSpace(param[1])
                                        ]);
                            }));
              }
              var match = $$arguments.hd;
              return AvoFormat.fprintF(__x, "%s%s", undefined, [
                          match[0],
                          commentWithSpace(match[1])
                        ]);
            })
        ], [identifier$8(undefined, EventCodegenNameUseCase.get($$event$1))]);
  } else {
    AvoFormat.fprintF(ppf, "[Avo %s];", undefined, [identifier$8(undefined, EventCodegenNameUseCase.get($$event$1))]);
  }
  AvoFormat.fprintF(ppf, "@]", undefined, []);
  return AvoFormat.buildText(ppf);
}

var ObjectiveC = {
  reservedKeywords: reservedKeywords$8,
  identifier: identifier$8,
  groupTypeVarName: groupTypeVarName$8,
  propertyVarName: propertyVarName$8,
  printApplyParams: printApplyParams$1,
  generateSnippetFromEnrichedModel: generateSnippetFromEnrichedModel$8
};

var reservedKeywords$9 = {
  hd: "Protocol",
  tl: {
    hd: "Self",
    tl: {
      hd: "Type",
      tl: {
        hd: "__COLUMN__",
        tl: {
          hd: "__FILE__",
          tl: {
            hd: "__FUNCTION__",
            tl: {
              hd: "__LINE__",
              tl: {
                hd: "as",
                tl: {
                  hd: "associativity",
                  tl: {
                    hd: "break",
                    tl: {
                      hd: "case",
                      tl: {
                        hd: "class",
                        tl: {
                          hd: "continue",
                          tl: {
                            hd: "convenience",
                            tl: {
                              hd: "default",
                              tl: {
                                hd: "deinit",
                                tl: {
                                  hd: "didSet",
                                  tl: {
                                    hd: "do",
                                    tl: {
                                      hd: "dynamic",
                                      tl: {
                                        hd: "dynamicType",
                                        tl: {
                                          hd: "else",
                                          tl: {
                                            hd: "enum",
                                            tl: {
                                              hd: "extension",
                                              tl: {
                                                hd: "fallthrough",
                                                tl: {
                                                  hd: "false",
                                                  tl: {
                                                    hd: "final",
                                                    tl: {
                                                      hd: "for",
                                                      tl: {
                                                        hd: "func ",
                                                        tl: {
                                                          hd: "get",
                                                          tl: {
                                                            hd: "if",
                                                            tl: {
                                                              hd: "import",
                                                              tl: {
                                                                hd: "in",
                                                                tl: {
                                                                  hd: "infix",
                                                                  tl: {
                                                                    hd: "init",
                                                                    tl: {
                                                                      hd: "inout",
                                                                      tl: {
                                                                        hd: "internal",
                                                                        tl: {
                                                                          hd: "is",
                                                                          tl: {
                                                                            hd: "lazy",
                                                                            tl: {
                                                                              hd: "left",
                                                                              tl: {
                                                                                hd: "let",
                                                                                tl: {
                                                                                  hd: "mutating",
                                                                                  tl: {
                                                                                    hd: "nil",
                                                                                    tl: {
                                                                                      hd: "none",
                                                                                      tl: {
                                                                                        hd: "nonmutating",
                                                                                        tl: {
                                                                                          hd: "operator",
                                                                                          tl: {
                                                                                            hd: "optional",
                                                                                            tl: {
                                                                                              hd: "override",
                                                                                              tl: {
                                                                                                hd: "postfix",
                                                                                                tl: {
                                                                                                  hd: "precedence",
                                                                                                  tl: {
                                                                                                    hd: "prefix",
                                                                                                    tl: {
                                                                                                      hd: "private",
                                                                                                      tl: {
                                                                                                        hd: "protocol",
                                                                                                        tl: {
                                                                                                          hd: "public",
                                                                                                          tl: {
                                                                                                            hd: "required",
                                                                                                            tl: {
                                                                                                              hd: "return",
                                                                                                              tl: {
                                                                                                                hd: "right",
                                                                                                                tl: {
                                                                                                                  hd: "self",
                                                                                                                  tl: {
                                                                                                                    hd: "set",
                                                                                                                    tl: {
                                                                                                                      hd: "static",
                                                                                                                      tl: {
                                                                                                                        hd: "struct",
                                                                                                                        tl: {
                                                                                                                          hd: "subscript",
                                                                                                                          tl: {
                                                                                                                            hd: "super",
                                                                                                                            tl: {
                                                                                                                              hd: "switch",
                                                                                                                              tl: {
                                                                                                                                hd: "true",
                                                                                                                                tl: {
                                                                                                                                  hd: "try",
                                                                                                                                  tl: {
                                                                                                                                    hd: "typealias",
                                                                                                                                    tl: {
                                                                                                                                      hd: "unowned",
                                                                                                                                      tl: {
                                                                                                                                        hd: "var",
                                                                                                                                        tl: {
                                                                                                                                          hd: "weak",
                                                                                                                                          tl: {
                                                                                                                                            hd: "where",
                                                                                                                                            tl: {
                                                                                                                                              hd: "while",
                                                                                                                                              tl: {
                                                                                                                                                hd: "repeat",
                                                                                                                                                tl: /* [] */0
                                                                                                                                              }
                                                                                                                                            }
                                                                                                                                          }
                                                                                                                                        }
                                                                                                                                      }
                                                                                                                                    }
                                                                                                                                  }
                                                                                                                                }
                                                                                                                              }
                                                                                                                            }
                                                                                                                          }
                                                                                                                        }
                                                                                                                      }
                                                                                                                    }
                                                                                                                  }
                                                                                                                }
                                                                                                              }
                                                                                                            }
                                                                                                          }
                                                                                                        }
                                                                                                      }
                                                                                                    }
                                                                                                  }
                                                                                                }
                                                                                              }
                                                                                            }
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

function identifier$9(caseOpt, name) {
  var $$case = caseOpt !== undefined ? caseOpt : Case.camel;
  var name$1 = Curry._1($$case, name);
  return Curry._2(Printf.sprintf(/* Format */{
                  _0: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: /* End_of_format */0
                    }
                  },
                  _1: "%s%s"
                }), name$1, List.exists((function (param) {
                    return name$1 === param;
                  }), reservedKeywords$9) ? "_" : "");
}

function groupTypeVarName$9(prefixOpt, groupTypeId, model) {
  var prefix = prefixOpt !== undefined ? prefixOpt : "";
  return Curry._2(Printf.sprintf(/* Format */{
                  _0: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: /* End_of_format */0
                    }
                  },
                  _1: "%s%s"
                }), prefix, identifier$9(Case.pascal, ModelUtils.getGroupTypeNameWithArchiveWithDefaultGroupTypeId(groupTypeId, model)));
}

function propertyVarName$9($$case, property) {
  var enrichedProperty = property.builtIn !== "NotBuiltIn";
  if (enrichedProperty) {
    return Curry._1(Printf.sprintf(/* Format */{
                    _0: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* Char_literal */12,
                        _0: /* '_' */95,
                        _1: /* End_of_format */0
                      }
                    },
                    _1: "%s_"
                  }), identifier$9($$case, prefixWithWordIfFirstNumber(PropertyCodegenNameUseCase.get(property))));
  } else {
    return identifier$9($$case, prefixWithWordIfFirstNumber(PropertyCodegenNameUseCase.get(property)));
  }
}

function generateSnippetFromEnrichedModel$9($$event, model, source) {
  var $$event$1 = Belt_Option.getExn(ModelUtils.getEventById($$event.id, model));
  var flattenGroups = Belt_List.someU(source.config, (function (config) {
          return config === "ForceFlattenPropertyBundlesInCodegen";
        }));
  var inputGroups = flattenGroups ? /* [] */0 : AvoModel.resolvePropertyBundleRefs(model.propertyBundles, $$event$1.propertyBundles);
  var inputProperties = filterPinnedProperties($$event$1.directPropertyRefs, model.properties, model);
  var inputProperties$1 = flattenGroups ? AvoModel.resolveProperties(model, inputProperties) : Belt_List.keep(AvoModel.resolveProperties(model, inputProperties), (function (property) {
            return !Belt_List.some(inputGroups, (function (group) {
                          return Belt_List.some(group.properties, (function (propertyId) {
                                        return property.id === propertyId;
                                      }));
                        }));
          }));
  var propertyNameMaps = getPropertyNameMaps($$event$1, source, model);
  var propertyGroupsArgs = Belt_List.mapU(Belt_List.map(inputGroups, (function (group) {
              var comment = printPropertyGroupTypeInPseudocode(group.properties, (function (eta) {
                      return propertyVarName$9(undefined, eta);
                    }), (function (eta) {
                      return identifier$9(undefined, eta);
                    }), model, $$event$1, propertyNameMaps, source);
              return [
                      Curry._2(Printf.sprintf(/* Format */{
                                _0: {
                                  TAG: /* String */2,
                                  _0: /* No_padding */0,
                                  _1: {
                                    TAG: /* String_literal */11,
                                    _0: ": ",
                                    _1: {
                                      TAG: /* String */2,
                                      _0: /* No_padding */0,
                                      _1: /* End_of_format */0
                                    }
                                  }
                                },
                                _1: "%s: %s"
                              }), identifier$9(undefined, group.name), identifier$9(undefined, group.name)),
                      comment
                    ];
            })), (function (param) {
          return [
                  Caml_array.get(param[0].split(":"), 1),
                  param[1]
                ];
        }));
  var partial_arg = Case.camel;
  var $$arguments = Belt_List.mapU(snippetArguments(inputProperties$1, (function (eta) {
              return propertyVarName$9(undefined, eta);
            }), $$event$1, model, groupTypeVarName$9, (function (param) {
              return identifier$9(partial_arg, param);
            }), /* Format */{
            _0: {
              TAG: /* String */2,
              _0: /* No_padding */0,
              _1: {
                TAG: /* String_literal */11,
                _0: ": ",
                _1: {
                  TAG: /* String */2,
                  _0: /* No_padding */0,
                  _1: /* End_of_format */0
                }
              }
            },
            _1: "%s: %s"
          }, /* Format */{
            _0: {
              TAG: /* String_literal */11,
              _0: "// ",
              _1: {
                TAG: /* String */2,
                _0: /* No_padding */0,
                _1: /* End_of_format */0
              }
            },
            _1: "// %s"
          }, source), (function (param) {
          return [
                  Caml_array.get(param[0].split(":"), 1),
                  param[1]
                ];
        }));
  var printArguments = function (ppf, params) {
    if (!params) {
      return ;
    }
    if (params.tl) {
      return Belt_List.forEachWithIndex(params, (function (index, param) {
                    var param$1 = param[0];
                    return AvoFormat.fprintF(ppf, "@,%s: %s%s%s", undefined, [
                                param$1,
                                param$1,
                                index === (Belt_List.length(params) - 1 | 0) ? "" : ",",
                                commentWithSpace(param[1])
                              ]);
                  }));
    }
    var match = params.hd;
    var param = match[0];
    return AvoFormat.fprintF(ppf, "%s: %s%s", undefined, [
                param,
                param,
                commentWithSpace(match[1])
              ]);
  };
  var ppf = AvoFormat.newTextBuilder(undefined);
  AvoFormat.fprintF(ppf, "@[<v>", undefined, []);
  var argsLength = Belt_List.length($$arguments) + Belt_List.length(propertyGroupsArgs) | 0;
  if (argsLength > 1) {
    AvoFormat.fprintF(ppf, "@[<v 2>Avo.%s(%a%a@]@,);", [
          (function (__x) {
              return printPropertyGroupArguments(__x, propertyGroupsArgs, "/*", "*/");
            }),
          (function (__x) {
              return printArguments(__x, $$arguments);
            })
        ], [identifier$9(undefined, EventCodegenNameUseCase.get($$event$1))]);
  } else {
    AvoFormat.fprintF(ppf, "Avo.%s(%a%a);", [
          (function (__x) {
              return printPropertyGroupArguments(__x, propertyGroupsArgs, "/*", "*/");
            }),
          (function (__x) {
              return printArguments(__x, $$arguments);
            })
        ], [identifier$9(undefined, EventCodegenNameUseCase.get($$event$1))]);
  }
  AvoFormat.fprintF(ppf, "@]", undefined, []);
  return AvoFormat.buildText(ppf);
}

var Swift = {
  reservedKeywords: reservedKeywords$9,
  identifier: identifier$9,
  groupTypeVarName: groupTypeVarName$9,
  propertyVarName: propertyVarName$9,
  generateSnippetFromEnrichedModel: generateSnippetFromEnrichedModel$9
};

var reservedKeywords$10 = {
  hd: "abstract",
  tl: {
    hd: "as",
    tl: {
      hd: "base",
      tl: {
        hd: "bool",
        tl: {
          hd: "break",
          tl: {
            hd: "byte",
            tl: {
              hd: "case",
              tl: {
                hd: "catch",
                tl: {
                  hd: "char",
                  tl: {
                    hd: "checked",
                    tl: {
                      hd: "class",
                      tl: {
                        hd: "const",
                        tl: {
                          hd: "continue",
                          tl: {
                            hd: "decimal",
                            tl: {
                              hd: "default",
                              tl: {
                                hd: "delegate",
                                tl: {
                                  hd: "do ",
                                  tl: {
                                    hd: "double",
                                    tl: {
                                      hd: "else",
                                      tl: {
                                        hd: "enum",
                                        tl: {
                                          hd: "event",
                                          tl: {
                                            hd: "explicit",
                                            tl: {
                                              hd: "extern",
                                              tl: {
                                                hd: "false",
                                                tl: {
                                                  hd: "finally",
                                                  tl: {
                                                    hd: "fixed",
                                                    tl: {
                                                      hd: "float",
                                                      tl: {
                                                        hd: "for",
                                                        tl: {
                                                          hd: "forech",
                                                          tl: {
                                                            hd: "goto",
                                                            tl: {
                                                              hd: "if",
                                                              tl: {
                                                                hd: "implicit",
                                                                tl: {
                                                                  hd: "in",
                                                                  tl: {
                                                                    hd: "int",
                                                                    tl: {
                                                                      hd: "interface",
                                                                      tl: {
                                                                        hd: "internal",
                                                                        tl: {
                                                                          hd: "is ",
                                                                          tl: {
                                                                            hd: "lock",
                                                                            tl: {
                                                                              hd: "long",
                                                                              tl: {
                                                                                hd: "namespace",
                                                                                tl: {
                                                                                  hd: "new",
                                                                                  tl: {
                                                                                    hd: "null",
                                                                                    tl: {
                                                                                      hd: "object",
                                                                                      tl: {
                                                                                        hd: "operator",
                                                                                        tl: {
                                                                                          hd: "out",
                                                                                          tl: {
                                                                                            hd: "override",
                                                                                            tl: {
                                                                                              hd: "params",
                                                                                              tl: {
                                                                                                hd: "private",
                                                                                                tl: {
                                                                                                  hd: "protected",
                                                                                                  tl: {
                                                                                                    hd: "public",
                                                                                                    tl: {
                                                                                                      hd: "readonly",
                                                                                                      tl: {
                                                                                                        hd: "ref",
                                                                                                        tl: {
                                                                                                          hd: "return",
                                                                                                          tl: {
                                                                                                            hd: "sbyte",
                                                                                                            tl: {
                                                                                                              hd: "sealed",
                                                                                                              tl: {
                                                                                                                hd: "short",
                                                                                                                tl: {
                                                                                                                  hd: "sizeof",
                                                                                                                  tl: {
                                                                                                                    hd: "stackalloc",
                                                                                                                    tl: {
                                                                                                                      hd: "static",
                                                                                                                      tl: {
                                                                                                                        hd: "string",
                                                                                                                        tl: {
                                                                                                                          hd: "struct",
                                                                                                                          tl: {
                                                                                                                            hd: "switch",
                                                                                                                            tl: {
                                                                                                                              hd: "this",
                                                                                                                              tl: {
                                                                                                                                hd: "throw",
                                                                                                                                tl: {
                                                                                                                                  hd: "true",
                                                                                                                                  tl: {
                                                                                                                                    hd: "try",
                                                                                                                                    tl: {
                                                                                                                                      hd: "typeof",
                                                                                                                                      tl: {
                                                                                                                                        hd: "uint",
                                                                                                                                        tl: {
                                                                                                                                          hd: "ulong",
                                                                                                                                          tl: {
                                                                                                                                            hd: "unchecked",
                                                                                                                                            tl: {
                                                                                                                                              hd: "unsafe",
                                                                                                                                              tl: {
                                                                                                                                                hd: "ushort",
                                                                                                                                                tl: {
                                                                                                                                                  hd: "using",
                                                                                                                                                  tl: {
                                                                                                                                                    hd: "virtual",
                                                                                                                                                    tl: {
                                                                                                                                                      hd: "volatile",
                                                                                                                                                      tl: {
                                                                                                                                                        hd: "void",
                                                                                                                                                        tl: {
                                                                                                                                                          hd: "while",
                                                                                                                                                          tl: /* [] */0
                                                                                                                                                        }
                                                                                                                                                      }
                                                                                                                                                    }
                                                                                                                                                  }
                                                                                                                                                }
                                                                                                                                              }
                                                                                                                                            }
                                                                                                                                          }
                                                                                                                                        }
                                                                                                                                      }
                                                                                                                                    }
                                                                                                                                  }
                                                                                                                                }
                                                                                                                              }
                                                                                                                            }
                                                                                                                          }
                                                                                                                        }
                                                                                                                      }
                                                                                                                    }
                                                                                                                  }
                                                                                                                }
                                                                                                              }
                                                                                                            }
                                                                                                          }
                                                                                                        }
                                                                                                      }
                                                                                                    }
                                                                                                  }
                                                                                                }
                                                                                              }
                                                                                            }
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

function identifier$10(caseOpt, name) {
  var $$case = caseOpt !== undefined ? caseOpt : Case.camel;
  var name$1 = Curry._1($$case, name);
  return Curry._2(Printf.sprintf(/* Format */{
                  _0: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: /* End_of_format */0
                    }
                  },
                  _1: "%s%s"
                }), name$1, List.exists((function (param) {
                    return name$1 === param;
                  }), reservedKeywords$10) ? "_" : "");
}

function groupTypeVarName$10(prefixOpt, groupTypeId, model) {
  var prefix = prefixOpt !== undefined ? prefixOpt : "";
  return Curry._2(Printf.sprintf(/* Format */{
                  _0: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: /* End_of_format */0
                    }
                  },
                  _1: "%s%s"
                }), prefix, identifier$10(Case.pascal, ModelUtils.getGroupTypeNameWithArchiveWithDefaultGroupTypeId(groupTypeId, model)));
}

function propertyVarName$10($$case, property) {
  var enrichedProperty = property.builtIn !== "NotBuiltIn";
  if (enrichedProperty) {
    return Curry._1(Printf.sprintf(/* Format */{
                    _0: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* Char_literal */12,
                        _0: /* '_' */95,
                        _1: /* End_of_format */0
                      }
                    },
                    _1: "%s_"
                  }), identifier$10($$case, prefixWithWordIfFirstNumber(PropertyCodegenNameUseCase.get(property))));
  } else {
    return identifier$10($$case, prefixWithWordIfFirstNumber(PropertyCodegenNameUseCase.get(property)));
  }
}

function generateSnippetFromEnrichedModel$10($$event, model, source) {
  var $$event$1 = Belt_Option.getExn(ModelUtils.getEventById($$event.id, model));
  var inputProperties = filterPinnedProperties($$event$1.directPropertyRefs, model.properties, model);
  var inputProperties$1 = AvoModel.resolveProperties(model, inputProperties);
  var partial_arg = Case.camel;
  var $$arguments = snippetArguments(inputProperties$1, (function (eta) {
          return propertyVarName$10(undefined, eta);
        }), $$event$1, model, groupTypeVarName$10, (function (param) {
          return identifier$10(partial_arg, param);
        }), /* Format */{
        _0: {
          TAG: /* String */2,
          _0: /* No_padding */0,
          _1: {
            TAG: /* String_literal */11,
            _0: ": ",
            _1: {
              TAG: /* String */2,
              _0: /* No_padding */0,
              _1: /* End_of_format */0
            }
          }
        },
        _1: "%s: %s"
      }, /* Format */{
        _0: {
          TAG: /* String_literal */11,
          _0: "// ",
          _1: {
            TAG: /* String */2,
            _0: /* No_padding */0,
            _1: /* End_of_format */0
          }
        },
        _1: "// %s"
      }, source);
  var printArguments = function (ppf, params) {
    if (!params) {
      return ;
    }
    if (params.tl) {
      return Belt_List.forEach(params, (function (param) {
                    return AvoFormat.fprintF(ppf, "@,%s,%s", undefined, [
                                param[0],
                                commentWithSpace(param[1])
                              ]);
                  }));
    }
    var match = params.hd;
    return AvoFormat.fprintF(ppf, "%s%s", undefined, [
                match[0],
                commentWithSpace(match[1])
              ]);
  };
  var ppf = AvoFormat.newTextBuilder(undefined);
  AvoFormat.fprintF(ppf, "@[<v>", undefined, []);
  if (Belt_List.length($$arguments) > 1) {
    AvoFormat.fprintF(ppf, "@[<v 2>Avo.%s(%a@]@,);", [(function (__x) {
              return printArguments(__x, $$arguments);
            })], [identifier$10(undefined, EventCodegenNameUseCase.get($$event$1))]);
  } else {
    AvoFormat.fprintF(ppf, "Avo.%s(%a);", [(function (__x) {
              return printArguments(__x, $$arguments);
            })], [identifier$10(undefined, EventCodegenNameUseCase.get($$event$1))]);
  }
  AvoFormat.fprintF(ppf, "@]", undefined, []);
  return AvoFormat.buildText(ppf);
}

var CSharp = {
  reservedKeywords: reservedKeywords$10,
  identifier: identifier$10,
  groupTypeVarName: groupTypeVarName$10,
  propertyVarName: propertyVarName$10,
  generateSnippetFromEnrichedModel: generateSnippetFromEnrichedModel$10
};

function className(language, name) {
  if (language === "java") {
    return identifier$6(undefined, name);
  } else if (language === "objc") {
    return identifier$8(undefined, name);
  } else if (language === "js" || language === "js_v2") {
    return identifier(undefined, name);
  } else if (language === "re" || language === "re_v2") {
    return identifier$1(undefined, name);
  } else if (language === "cs") {
    return identifier$10(undefined, name);
  } else if (language === "kt") {
    return identifier$7(undefined, name);
  } else if (language === "py3" || language === "py") {
    return identifier$4(undefined, name);
  } else if (language === "rb") {
    return identifier$3(undefined, name);
  } else if (language === "ts") {
    return identifier$2(undefined, name);
  } else if (language === "php") {
    return identifier$5(undefined, name);
  } else if (language === "swift") {
    return identifier$9(undefined, name);
  } else {
    return name;
  }
}

function eventName(language, $$event) {
  if (language === "swift" || language === "php" || language === "ts" || language === "rb" || language === "kt" || language === "cs" || language === "objc" || language === "java" || language === "js" || language === "js_v2" || language === "re" || language === "re_v2" || language === "py3" || language === "py") {
    return className(language, EventCodegenNameUseCase.get($$event));
  } else {
    return className(language, $$event.name);
  }
}

function groupTypeVarName$11(language, prefix, groupType, enrichedModel) {
  if (language === "java") {
    return groupTypeVarName$6(prefix, groupType.id, enrichedModel);
  } else if (language === "php" || language === "rb" || language === "objc") {
    return groupType.name;
  } else if (language === "js" || language === "js_v2") {
    return groupTypeVarName(prefix, groupType.id, enrichedModel);
  } else if (language === "re" || language === "re_v2") {
    return groupTypeVarName$1(prefix, groupType.id, enrichedModel);
  } else if (language === "cs") {
    return groupTypeVarName$10(prefix, groupType.id, enrichedModel);
  } else if (language === "kt") {
    return groupTypeVarName$7(prefix, groupType.id, enrichedModel);
  } else if (language === "py3" || language === "py") {
    return groupTypeVarName$4(prefix, groupType.id, enrichedModel);
  } else if (language === "ts") {
    return groupTypeVarName$2(prefix, groupType.id, enrichedModel);
  } else if (language === "swift") {
    return groupTypeVarName$9(prefix, groupType.id, enrichedModel);
  } else {
    return groupType.name;
  }
}

function propertyName(language, property) {
  if (language === "java") {
    return propertyVarName$6(undefined, property);
  } else if (language === "objc") {
    return propertyVarName$8(undefined, property);
  } else if (language === "js" || language === "js_v2") {
    return propertyVarName(undefined, property);
  } else if (language === "re" || language === "re_v2") {
    return propertyVarName$1(undefined, property);
  } else if (language === "cs") {
    return propertyVarName$10(undefined, property);
  } else if (language === "kt") {
    return propertyVarName$7(undefined, property);
  } else if (language === "py3" || language === "py") {
    return propertyVarName$4(undefined, property);
  } else if (language === "rb") {
    return propertyVarName$3(undefined, property);
  } else if (language === "ts") {
    return propertyVarName$2(undefined, property);
  } else if (language === "php") {
    return propertyVarName$5(undefined, property);
  } else if (language === "swift") {
    return propertyVarName$9(undefined, property);
  } else {
    return property.name;
  }
}

function objectPropertyTypeSignature(parentPropertyId, nestedPropertyRefs, formatPropertyNameForLanguageOpt, model, language, eventIdOpt, param) {
  var formatPropertyNameForLanguage = formatPropertyNameForLanguageOpt !== undefined ? formatPropertyNameForLanguageOpt : true;
  var eventId = eventIdOpt !== undefined ? Caml_option.valFromOption(eventIdOpt) : undefined;
  return Belt_List.keepMap(nestedPropertyRefs, (function (nestedPropertyRef) {
                var maybeNestedProperty = ModelUtils.resolvePropertyById(nestedPropertyRef.id, model);
                if (maybeNestedProperty === undefined) {
                  return ;
                }
                var isPinnedValue = eventId !== undefined ? Belt_Option.isSome(CodegenRules.getNestedPropertyPinnedValue(nestedPropertyRef, parentPropertyId, eventId, model)) : Belt_Option.isSome(nestedPropertyRef.pinnedValue);
                if (isPinnedValue) {
                  return ;
                }
                var displayedPropertyName = formatPropertyNameForLanguage ? propertyName(language, maybeNestedProperty) : maybeNestedProperty.name;
                return displayedPropertyName + ": " + maybeNestedProperty.type_;
              }));
}

function objectPropertyTypeSignature_mapped(nestedPropertyRefs, formatPropertyNameForLanguageOpt, language, eventIdOpt, properties, rules, parentPropertyId, model) {
  var formatPropertyNameForLanguage = formatPropertyNameForLanguageOpt !== undefined ? formatPropertyNameForLanguageOpt : true;
  var eventId = eventIdOpt !== undefined ? Caml_option.valFromOption(eventIdOpt) : undefined;
  return Belt_List.keepMap(nestedPropertyRefs, (function (nestedPropertyRef) {
                var maybeNestedProperty = Curry._2(TrackingPlanMappedModel.Properties.get, properties, nestedPropertyRef.id);
                if (maybeNestedProperty === undefined) {
                  return ;
                }
                var isPinnedValue = eventId !== undefined ? Belt_Option.isSome(CodegenRules.getNestedPropertyPinnedValue_mapped(nestedPropertyRef, parentPropertyId, eventId, properties, rules, model)) : Belt_Option.isSome(nestedPropertyRef.pinnedValue);
                if (isPinnedValue) {
                  return ;
                }
                var displayedPropertyName = formatPropertyNameForLanguage ? propertyName(language, maybeNestedProperty) : maybeNestedProperty.name;
                return displayedPropertyName + ": " + maybeNestedProperty.type_;
              }));
}

function enrichModelForSnippet(serverTrackingWithDeviceIdOpt, eventSpecificPropertyValues, skipImplementWithAvoFilteringOpt, model, source) {
  var serverTrackingWithDeviceId = serverTrackingWithDeviceIdOpt !== undefined ? serverTrackingWithDeviceIdOpt : false;
  var skipImplementWithAvoFiltering = skipImplementWithAvoFilteringOpt !== undefined ? skipImplementWithAvoFilteringOpt : false;
  var destinations = AvoModel.resolveDestinations(model, ModelUtils.getSourceDestinationIds(source.destinations));
  var destinations$1 = AvoConfig.getValidDestinations({
        hd: TrackingPlanModel.Source.makeT(source),
        tl: /* [] */0
      }, destinations);
  return enrich(serverTrackingWithDeviceId, eventSpecificPropertyValues, undefined, skipImplementWithAvoFiltering, undefined, model, source, destinations$1)[0];
}

function snippetWithEnrichedModel(enrichedModel, source, $$event) {
  var match = source.platform;
  var match$1 = source.language;
  if (match === "Gtm" && match$1 === "js_v2") {
    return generateSnippetGtmFromEnrichedModel($$event, enrichedModel, source);
  }
  if (match$1 === "java") {
    return generateSnippetFromEnrichedModel$6($$event, enrichedModel, source);
  } else if (match$1 === "objc") {
    return generateSnippetFromEnrichedModel$8($$event, enrichedModel, source);
  } else if (match$1 === "js_v2") {
    return generateSnippetFromEnrichedModel($$event, enrichedModel, source);
  } else if (match$1 === "re_v2") {
    return generateSnippetFromEnrichedModel$1($$event, enrichedModel, source);
  } else if (match$1 === "cs") {
    return generateSnippetFromEnrichedModel$10($$event, enrichedModel, source);
  } else if (match$1 === "kt") {
    return generateSnippetFromEnrichedModel$7($$event, enrichedModel, source);
  } else if (match$1 === "py3" || match$1 === "py") {
    return generateSnippetFromEnrichedModel$4($$event, enrichedModel, source);
  } else if (match$1 === "rb") {
    return generateSnippetFromEnrichedModel$3($$event, enrichedModel, source);
  } else if (match$1 === "ts") {
    return generateSnippetFromEnrichedModel$2($$event, enrichedModel, source);
  } else if (match$1 === "php") {
    return generateSnippetFromEnrichedModel$5($$event, enrichedModel, source);
  } else if (match$1 === "swift") {
    return generateSnippetFromEnrichedModel$9($$event, enrichedModel, source);
  } else {
    return "";
  }
}

function snippet(serverTrackingWithDeviceIdOpt, eventSpecificPropertyValues, model, source, $$event) {
  var serverTrackingWithDeviceId = serverTrackingWithDeviceIdOpt !== undefined ? serverTrackingWithDeviceIdOpt : false;
  var enrichedModel = enrichModelForSnippet(serverTrackingWithDeviceId, eventSpecificPropertyValues, undefined, model, source);
  return snippetWithEnrichedModel(enrichedModel, source, $$event);
}

function getLegacyMatches(property) {
  return Belt_List.headExn(Belt_List.keepMap(property.validations, (function (validation) {
                    if (typeof validation === "object" && validation.NAME === "Matches") {
                      return Belt_List.map(validation.VAL, (function (lit) {
                                    var match = lit[0].VAL;
                                    if (typeof match === "object" && match.NAME === "StringLit") {
                                      return match.VAL;
                                    } else {
                                      return Pervasives.failwith("unsupported literal");
                                    }
                                  }));
                    }
                    
                  })));
}

function getPropertyAllowedValues(model, sourceIds, property, eventId, uniqueAllowedValuesSets) {
  var isSystem = property.sendAs === /* SystemProperty */0 ? "IsSystem" : "NotSystem";
  if (eventId === undefined) {
    return Belt_SetString.fromArray(ComputeUnionAllowedValuesUseCase.computeWithMappedModel(model, sourceIds, property));
  }
  if (isSystem === "IsSystem") {
    return Belt_SetString.fromArray(ComputeUnionAllowedValuesUseCase.computeWithMappedModel(model, sourceIds, property));
  }
  var eventIdsToAllowedValues = uniqueAllowedValuesSets.eventIdsToAllowedValues;
  return Belt_Option.getWithDefault(Belt_MapString.get(eventIdsToAllowedValues, eventId), undefined);
}

function isEnum(property, model, sourceIds, eventId, uniqueAllowedValuesSets) {
  var hasNoRegex = GetRegexValidationUseCase.get(property) === undefined;
  var match = ModelUtils_mapped.hasMigrated(model, "EventSpecificAllowedPropertyValues");
  var match$1 = property.type_;
  if (!match) {
    if (match$1 === "string" && hasNoRegex) {
      return hasMatchValidation(property);
    } else {
      return false;
    }
  }
  if (match$1 !== "string") {
    return false;
  }
  var allowedValues = getPropertyAllowedValues(model, sourceIds, property, eventId, uniqueAllowedValuesSets);
  if (hasNoRegex) {
    return Belt_SetString.size(allowedValues) > 0;
  } else {
    return false;
  }
}

function getEventSpecificPropertyValuesCsvString(property, model, sourceIds, eventId, uniqueAllowedValuesSets) {
  if (ModelUtils_mapped.hasMigrated(model, "EventSpecificAllowedPropertyValues")) {
    if (isEnum(property, model, sourceIds, eventId, uniqueAllowedValuesSets)) {
      return JSON.stringify(Belt_SetString.toArray(getPropertyAllowedValues(model, sourceIds, property, eventId, uniqueAllowedValuesSets)).join(", "));
    } else {
      return "";
    }
  } else if (GetRegexValidationUseCase.get(property) === undefined && hasMatchValidation(property)) {
    return JSON.stringify(Belt_List.toArray(getLegacyMatches(property)).join(", "));
  } else {
    return "";
  }
}

function headerComment(ppf, startCommentSymbol, version) {
  Curry._2(Format.fprintf(ppf, /* Format */{
            _0: {
              TAG: /* String */2,
              _0: /* No_padding */0,
              _1: {
                TAG: /* String_literal */11,
                _0: " Generated by Avo VERSION ",
                _1: {
                  TAG: /* String */2,
                  _0: /* No_padding */0,
                  _1: {
                    TAG: /* String_literal */11,
                    _0: ". You should never have to make changes to this file.",
                    _1: /* End_of_format */0
                  }
                }
              }
            },
            _1: "%s Generated by Avo VERSION %s. You should never have to make changes to this file."
          }), startCommentSymbol, version);
  Curry._1(Format.fprintf(ppf, /* Format */{
            _0: {
              TAG: /* Formatting_lit */17,
              _0: {
                TAG: /* Break */0,
                _0: "@,",
                _1: 0,
                _2: 0
              },
              _1: {
                TAG: /* String */2,
                _0: /* No_padding */0,
                _1: {
                  TAG: /* String_literal */11,
                  _0: " If you find yourself in the situation where you have to edit the file please contact us at hi",
                  _1: {
                    TAG: /* Formatting_lit */17,
                    _0: {
                      TAG: /* Scan_indic */2,
                      _0: /* 'a' */97
                    },
                    _1: {
                      TAG: /* String_literal */11,
                      _0: "vo.app.",
                      _1: /* End_of_format */0
                    }
                  }
                }
              }
            },
            _1: "@,%s If you find yourself in the situation where you have to edit the file please contact us at hi@avo.app."
          }), startCommentSymbol);
  return Curry._1(Format.fprintf(ppf, /* Format */{
                  _0: {
                    TAG: /* Formatting_lit */17,
                    _0: {
                      TAG: /* Break */0,
                      _0: "@,",
                      _1: 0,
                      _2: 0
                    },
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* String_literal */11,
                        _0: " If you encounter a git conflict in this file run `avo pull` and it will be resolved automatically.",
                        _1: /* End_of_format */0
                      }
                    }
                  },
                  _1: "@,%s If you encounter a git conflict in this file run `avo pull` and it will be resolved automatically."
                }), startCommentSymbol);
}

function headerCommentAvoFormat(ppf, startCommentSymbol, version) {
  AvoFormat.fprintF(ppf, "%s Generated by Avo VERSION %s. You should never have to make changes to this file.", undefined, [
        startCommentSymbol,
        version
      ]);
  AvoFormat.fprintF(ppf, "@,%s If you find yourself in the situation where you have to edit the file please contact us at hi@avo.app.", undefined, [startCommentSymbol]);
  return AvoFormat.fprintF(ppf, "@,%s If you encounter a git conflict in this file run `avo pull` and it will be resolved automatically.", undefined, [startCommentSymbol]);
}

function printEventTriggersDoc(startCommentSymbol, model, eventId, sourceId, schemaId, branchId, ppf) {
  var eventTriggers = ModelUtils.getTriggersByEventId(eventId, model);
  var sourceTriggers = Belt_Array.keepU(eventTriggers, (function (trigger) {
          var sourceIds = trigger.sources;
          if (!sourceIds) {
            return true;
          }
          var sourceIds$1 = sourceIds._0;
          if (sourceIds$1.length !== 0) {
            return Belt_Array.someU(sourceIds$1, (function (triggerSourceId) {
                          return triggerSourceId === sourceId;
                        }));
          } else {
            return false;
          }
        }));
  if (Caml_obj.caml_notequal(sourceTriggers, [])) {
    Curry._2(Format.fprintf(ppf, /* Format */{
              _0: {
                TAG: /* Formatting_lit */17,
                _0: {
                  TAG: /* Break */0,
                  _0: "@,",
                  _1: 0,
                  _2: 0
                },
                _1: {
                  TAG: /* String */2,
                  _0: /* No_padding */0,
                  _1: {
                    TAG: /* Formatting_lit */17,
                    _0: {
                      TAG: /* Break */0,
                      _0: "@,",
                      _1: 0,
                      _2: 0
                    },
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* String_literal */11,
                        _0: "When to trigger this event:",
                        _1: /* End_of_format */0
                      }
                    }
                  }
                }
              },
              _1: "@,%s@,%sWhen to trigger this event:"
            }), startCommentSymbol, startCommentSymbol);
    return Belt_Array.forEachWithIndexU(sourceTriggers, (function (index, trigger) {
                  var descriptionString = QuillToPlainText.quillToPlainText(trigger.description);
                  Curry._3(Format.fprintf(ppf, /* Format */{
                            _0: {
                              TAG: /* Formatting_lit */17,
                              _0: {
                                TAG: /* Break */0,
                                _0: "@,",
                                _1: 0,
                                _2: 0
                              },
                              _1: {
                                TAG: /* String */2,
                                _0: /* No_padding */0,
                                _1: {
                                  TAG: /* Int */4,
                                  _0: /* Int_d */0,
                                  _1: /* No_padding */0,
                                  _2: /* No_precision */0,
                                  _3: {
                                    TAG: /* String_literal */11,
                                    _0: ". ",
                                    _1: {
                                      TAG: /* String */2,
                                      _0: /* No_padding */0,
                                      _1: /* End_of_format */0
                                    }
                                  }
                                }
                              }
                            },
                            _1: "@,%s%d. %s"
                          }), startCommentSymbol, index + 1 | 0, descriptionString);
                  return Curry._2(Format.fprintf(ppf, /* Format */{
                                  _0: {
                                    TAG: /* Formatting_lit */17,
                                    _0: {
                                      TAG: /* Break */0,
                                      _0: "@,",
                                      _1: 0,
                                      _2: 0
                                    },
                                    _1: {
                                      TAG: /* String */2,
                                      _0: /* No_padding */0,
                                      _1: {
                                        TAG: /* String_literal */11,
                                        _0: "View in Avo: ",
                                        _1: {
                                          TAG: /* String */2,
                                          _0: /* No_padding */0,
                                          _1: /* End_of_format */0
                                        }
                                      }
                                    }
                                  },
                                  _1: "@,%sView in Avo: %s"
                                }), startCommentSymbol, LinkUrls.triggerLink(schemaId, branchId, eventId, trigger.id));
                }));
  }
  
}

function printEventTriggersDocAvoFormat(startCommentSymbol, model, eventId, sourceId, schemaId, branchId, ppf) {
  var eventTriggers = ModelUtils.getTriggersByEventId(eventId, model);
  var sourceTriggers = Belt_Array.keepU(eventTriggers, (function (trigger) {
          var sourceIds = trigger.sources;
          if (!sourceIds) {
            return true;
          }
          var sourceIds$1 = sourceIds._0;
          if (sourceIds$1.length !== 0) {
            return Belt_Array.someU(sourceIds$1, (function (triggerSourceId) {
                          return triggerSourceId === sourceId;
                        }));
          } else {
            return false;
          }
        }));
  if (Caml_obj.caml_notequal(sourceTriggers, [])) {
    AvoFormat.fprintF(ppf, "@,%s@,%sWhen to trigger this event:", undefined, [
          startCommentSymbol,
          startCommentSymbol
        ]);
    return Belt_Array.forEachWithIndexU(sourceTriggers, (function (index, trigger) {
                  var descriptionString = QuillToPlainText.quillToPlainText(trigger.description);
                  AvoFormat.fprintF(ppf, "@,%s%s. %s", undefined, [
                        startCommentSymbol,
                        String(index + 1 | 0),
                        descriptionString
                      ]);
                  return AvoFormat.fprintF(ppf, "@,%sView in Avo: %s", undefined, [
                              startCommentSymbol,
                              LinkUrls.triggerLink(schemaId, branchId, eventId, trigger.id)
                            ]);
                }));
  }
  
}

function eventTriggersWithIncludedSourcesIds(model, eventId, filterSourceIds) {
  var eventTriggers = ModelUtils.getTriggersByEventId(eventId, model);
  return Belt_Array.keepU(Belt_Array.mapU(eventTriggers, (function (trigger) {
                    var triggerSourceIds = trigger.sources;
                    var tmp;
                    if (triggerSourceIds) {
                      var triggerSourceIds$1 = triggerSourceIds._0;
                      tmp = Belt_List.length(filterSourceIds) === 0 ? triggerSourceIds$1 : Belt_Array.keepMapU(triggerSourceIds$1, (function (triggerSourceId) {
                                if (Belt_List.someU(filterSourceIds, (function (sourceId) {
                                          return sourceId === triggerSourceId;
                                        }))) {
                                  return triggerSourceId;
                                }
                                
                              }));
                    } else {
                      tmp = Belt_List.toArray(Belt_List.mapU(model.sources, (function (source) {
                                  return source.id;
                                })));
                    }
                    return [
                            trigger,
                            tmp
                          ];
                  })), (function (param) {
                return param[1].length !== 0;
              }));
}

function eventTriggersWithIncludedSourcesNames(model, eventId, sourceIds) {
  var sourceName = function (sourceId) {
    var sourceName$1 = Belt_Option.getWithDefault(Belt_Option.flatMapU(ModelUtils.getSourceById(sourceId, model), (function (source) {
                return source.name;
              })), "Unnamed source");
    if (sourceName$1 === "") {
      return "Unnamed source";
    } else {
      return sourceName$1;
    }
  };
  var eventTriggers = ModelUtils.getTriggersByEventId(eventId, model);
  return Belt_Array.keepU(Belt_Array.mapU(eventTriggers, (function (trigger) {
                    var triggerSourceIds = trigger.sources;
                    return [
                            trigger,
                            triggerSourceIds ? Belt_Array.keepMapU(triggerSourceIds._0, (function (triggerSourceId) {
                                      if (Belt_List.someU(sourceIds, (function (sourceId) {
                                                return sourceId === triggerSourceId;
                                              }))) {
                                        return sourceName(triggerSourceId);
                                      }
                                      
                                    })) : ["all sources"]
                          ];
                  })), (function (param) {
                return param[1].length !== 0;
              }));
}

function eventTriggersForSourcesJson(model, eventId, filterSourceIds, schemaId, branchId) {
  var eventTriggersWithIncludedSourceIds = eventTriggersWithIncludedSourcesIds(model, eventId, filterSourceIds);
  return Belt_Array.mapU(eventTriggersWithIncludedSourceIds, (function (param) {
                var trigger = param[0];
                var imageUrl = Belt_Option.mapU(trigger.content, (function (content) {
                        return content._0.url;
                      }));
                var descriptionString = QuillToPlainText.quillToPlainText(trigger.description);
                return Json_encode.object_(Belt_List.concat({
                                hd: [
                                  "id",
                                  trigger.id
                                ],
                                tl: {
                                  hd: [
                                    "description",
                                    descriptionString
                                  ],
                                  tl: {
                                    hd: [
                                      "sourceIds",
                                      Json_encode.array((function (prim) {
                                              return prim;
                                            }), param[1])
                                    ],
                                    tl: {
                                      hd: [
                                        "link",
                                        LinkUrls.triggerLink(schemaId, branchId, eventId, trigger.id)
                                      ],
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }, imageUrl !== undefined ? ({
                                    hd: [
                                      "imageUrl",
                                      imageUrl
                                    ],
                                    tl: /* [] */0
                                  }) : /* [] */0));
              }));
}

function eventTriggersForSources(model, eventId, sourceIds, withNumber, schemaId, branchId) {
  var eventTriggersWithIncludedSourcesNames$1 = eventTriggersWithIncludedSourcesNames(model, eventId, sourceIds);
  return Belt_Array.mapWithIndexU(eventTriggersWithIncludedSourcesNames$1, (function (index, param) {
                var trigger = param[0];
                var descriptionString = QuillToPlainText.quillToPlainText(trigger.description);
                return (
                        withNumber ? Curry._2(Format.sprintf(/* Format */{
                                    _0: {
                                      TAG: /* Int */4,
                                      _0: /* Int_d */0,
                                      _1: /* No_padding */0,
                                      _2: /* No_precision */0,
                                      _3: {
                                        TAG: /* String_literal */11,
                                        _0: ". ",
                                        _1: {
                                          TAG: /* String */2,
                                          _0: /* No_padding */0,
                                          _1: /* End_of_format */0
                                        }
                                      }
                                    },
                                    _1: "%d. %s"
                                  }), index + 1 | 0, descriptionString) : Curry._1(Format.sprintf(/* Format */{
                                    _0: {
                                      TAG: /* String */2,
                                      _0: /* No_padding */0,
                                      _1: /* End_of_format */0
                                    },
                                    _1: "%s"
                                  }), descriptionString)
                      ) + (Curry._1(Format.sprintf(/* Format */{
                                  _0: {
                                    TAG: /* String_literal */11,
                                    _0: ". Trigger on ",
                                    _1: {
                                      TAG: /* String */2,
                                      _0: /* No_padding */0,
                                      _1: /* End_of_format */0
                                    }
                                  },
                                  _1: ". Trigger on %s"
                                }), param[1].join(", ")) + Curry._1(Format.sprintf(/* Format */{
                                  _0: {
                                    TAG: /* String_literal */11,
                                    _0: ". View in Avo: ",
                                    _1: {
                                      TAG: /* String */2,
                                      _0: /* No_padding */0,
                                      _1: /* End_of_format */0
                                    }
                                  },
                                  _1: ". View in Avo: %s"
                                }), LinkUrls.triggerLink(schemaId, branchId, eventId, trigger.id)));
              }));
}

function eventDescriptionWithTriggers(eventDescription, includedSources, model, eventId, schemaId, branchId) {
  var triggers = eventTriggersForSources(model, eventId, includedSources, true, schemaId, branchId);
  var triggersString = triggers.length !== 0 ? "Event triggers: " + triggers.join(". ") : "";
  var match = eventDescription.trim();
  var match$1 = triggersString.trim();
  if (match === "" && match$1 === "") {
    return "";
  }
  if (match$1 === "") {
    return match;
  } else if (match === "") {
    return match$1;
  } else if (match.endsWith(".")) {
    return match + (" " + match$1);
  } else {
    return match + (". " + match$1);
  }
}

function getPropertyVarInstance(msgOpt, properties, predicate) {
  var msg = msgOpt !== undefined ? msgOpt : "Property not found";
  var maybeProperty = Belt_List.getBy(properties, Curry.__1(predicate));
  if (maybeProperty !== undefined) {
    return Caml_option.valFromOption(maybeProperty);
  } else {
    return Js_exn.raiseError(msg);
  }
}

function getPropertyVarName(msgOpt, propertyVarName, properties, predicate) {
  var msg = msgOpt !== undefined ? msgOpt : "Property not found";
  var maybeProperty = Belt_List.getBy(properties, Curry.__1(predicate));
  if (maybeProperty !== undefined) {
    return Curry._1(propertyVarName, Caml_option.valFromOption(maybeProperty));
  } else {
    return Js_exn.raiseError(msg);
  }
}

function eventTriggersMarkdownForSources(model, eventId, sourceIds, withNumber, schemaId, branchId) {
  var eventTriggersWithIncludedSourcesNames$1 = eventTriggersWithIncludedSourcesNames(model, eventId, sourceIds);
  return Belt_Array.mapWithIndexU(eventTriggersWithIncludedSourcesNames$1, (function (index, param) {
                var triggerSources = param[1];
                var trigger = param[0];
                var descriptionString = QuillToPlainText.quillToPlainText(trigger.description);
                var match = descriptionString.trim();
                var tmp;
                tmp = withNumber ? (
                    match === "" ? Curry._2(Format.sprintf(/* Format */{
                                _0: {
                                  TAG: /* Int */4,
                                  _0: /* Int_d */0,
                                  _1: /* No_padding */0,
                                  _2: /* No_precision */0,
                                  _3: {
                                    TAG: /* String_literal */11,
                                    _0: ". _Triggered on ",
                                    _1: {
                                      TAG: /* String */2,
                                      _0: /* No_padding */0,
                                      _1: {
                                        TAG: /* Char_literal */12,
                                        _0: /* '_' */95,
                                        _1: /* End_of_format */0
                                      }
                                    }
                                  }
                                },
                                _1: "%d. _Triggered on %s_"
                              }), index + 1 | 0, triggerSources.join(", ")) : Curry._3(Format.sprintf(/* Format */{
                                _0: {
                                  TAG: /* Int */4,
                                  _0: /* Int_d */0,
                                  _1: /* No_padding */0,
                                  _2: /* No_precision */0,
                                  _3: {
                                    TAG: /* String_literal */11,
                                    _0: ". ",
                                    _1: {
                                      TAG: /* String */2,
                                      _0: /* No_padding */0,
                                      _1: {
                                        TAG: /* String_literal */11,
                                        _0: " \n  _Triggered on ",
                                        _1: {
                                          TAG: /* String */2,
                                          _0: /* No_padding */0,
                                          _1: {
                                            TAG: /* Char_literal */12,
                                            _0: /* '_' */95,
                                            _1: /* End_of_format */0
                                          }
                                        }
                                      }
                                    }
                                  }
                                },
                                _1: "%d. %s \n  _Triggered on %s_"
                              }), index + 1 | 0, descriptionString, triggerSources.join(", "))
                  ) : (
                    match === "" ? Curry._1(Format.sprintf(/* Format */{
                                _0: {
                                  TAG: /* String_literal */11,
                                  _0: "_Triggered on ",
                                  _1: {
                                    TAG: /* String */2,
                                    _0: /* No_padding */0,
                                    _1: {
                                      TAG: /* Char_literal */12,
                                      _0: /* '_' */95,
                                      _1: /* End_of_format */0
                                    }
                                  }
                                },
                                _1: "_Triggered on %s_"
                              }), triggerSources.join(", ")) : Curry._2(Format.sprintf(/* Format */{
                                _0: {
                                  TAG: /* String */2,
                                  _0: /* No_padding */0,
                                  _1: {
                                    TAG: /* String_literal */11,
                                    _0: "  \n  _Triggered on ",
                                    _1: {
                                      TAG: /* String */2,
                                      _0: /* No_padding */0,
                                      _1: {
                                        TAG: /* Char_literal */12,
                                        _0: /* '_' */95,
                                        _1: /* End_of_format */0
                                      }
                                    }
                                  }
                                },
                                _1: "%s  \n  _Triggered on %s_"
                              }), descriptionString, triggerSources.join(", "))
                  );
                var match$1 = trigger.content;
                var tmp$1;
                var exit = 0;
                if (match$1 !== undefined && match$1.TAG === /* Image */0) {
                  tmp$1 = Curry._2(Format.sprintf(/* Format */{
                            _0: {
                              TAG: /* String_literal */11,
                              _0: "\n  ![](",
                              _1: {
                                TAG: /* String */2,
                                _0: /* No_padding */0,
                                _1: {
                                  TAG: /* String_literal */11,
                                  _0: "=w700)  \n  [View trigger in Avo](",
                                  _1: {
                                    TAG: /* String */2,
                                    _0: /* No_padding */0,
                                    _1: {
                                      TAG: /* Char_literal */12,
                                      _0: /* ')' */41,
                                      _1: /* End_of_format */0
                                    }
                                  }
                                }
                              }
                            },
                            _1: "\n  ![](%s=w700)  \n  [View trigger in Avo](%s)"
                          }), match$1._0.url, LinkUrls.triggerLink(schemaId, branchId, eventId, trigger.id));
                } else {
                  exit = 1;
                }
                if (exit === 1) {
                  tmp$1 = Curry._1(Format.sprintf(/* Format */{
                            _0: {
                              TAG: /* String_literal */11,
                              _0: "\n  View in Avo: ",
                              _1: {
                                TAG: /* String */2,
                                _0: /* No_padding */0,
                                _1: /* End_of_format */0
                              }
                            },
                            _1: "\n  View in Avo: %s"
                          }), LinkUrls.triggerLink(schemaId, branchId, eventId, trigger.id));
                }
                return tmp + tmp$1;
              }));
}

function eventDescriptionWithMarkdownTriggers(eventDescription, includedSources, model, eventId, schemaId, branchId) {
  var triggers = eventTriggersMarkdownForSources(model, eventId, includedSources, true, schemaId, branchId);
  var triggersString = triggers.length !== 0 ? "Triggers:\n" + triggers.join("\n") : "";
  var match = eventDescription.trim();
  var match$1 = triggersString.trim();
  var description;
  var exit = 0;
  if (match === "" && match$1 === "") {
    description = "";
  } else {
    exit = 1;
  }
  if (exit === 1) {
    description = match$1 === "" ? match : (
        match === "" ? match$1 : match + ("\n\n" + match$1)
      );
  }
  if (description === "") {
    return description;
  } else {
    return description + ("  \n\n_This description was published from [Avo](https://avo.app/schemas/" + schemaId + ")_");
  }
}

export {
  hasShapeValidation ,
  hasMatchValidation ,
  hasNestedPropertyValidation ,
  nameMappingString ,
  shouldSendEventToSourceDestination ,
  shouldSendEventToDestinationType ,
  prefixWithWordIfFirstNumber ,
  prefixWithWordIfFirstNumberWithCase ,
  propertyCodegenName ,
  printApiKey ,
  prefixWithUnderscoreIfFirstNumber ,
  propertyDocsFormat ,
  eventDocsFormat ,
  eventLink ,
  propertyGroupLink ,
  stringReplaceByRe ,
  stripTrailingWhiteSpace ,
  escapeSingleQuotes ,
  escapeDoubleQuotes ,
  commentWithSpace ,
  printModuleMap ,
  printEventMap ,
  classifyProperties ,
  filterOutGroupProperties ,
  classifyGroupPropertiesByGroupType ,
  filterPinnedProperties ,
  getPinnedProperties ,
  printPinnedValueOption ,
  printPinnedValueExn ,
  enrichWithRef ,
  getSnowplowSchemaPropertyRef ,
  unsafeGetSnowplowSchemaPropertyRef ,
  printingFunctionToString ,
  printingFunctionToStringAvoFormat ,
  enrich ,
  resolveFunctionArguments ,
  getPropertyOnEventRegexDocString ,
  printPropertyTypeInPseudocodeForCodegen ,
  printPropertyGroupTypeInPseudocode ,
  getPropertyNameMaps ,
  snippetArguments ,
  JavaScript ,
  Reason ,
  TypeScript ,
  Ruby ,
  Python ,
  PHP ,
  Java ,
  Kotlin ,
  ObjectiveC ,
  Swift ,
  CSharp ,
  className ,
  eventName ,
  groupTypeVarName$11 as groupTypeVarName,
  propertyName ,
  objectPropertyTypeSignature ,
  objectPropertyTypeSignature_mapped ,
  enrichModelForSnippet ,
  snippetWithEnrichedModel ,
  snippet ,
  getLegacyMatches ,
  getPropertyAllowedValues ,
  isEnum ,
  getEventSpecificPropertyValuesCsvString ,
  headerComment ,
  headerCommentAvoFormat ,
  printEventTriggersDoc ,
  printEventTriggersDocAvoFormat ,
  eventTriggersWithIncludedSourcesIds ,
  eventTriggersWithIncludedSourcesNames ,
  eventTriggersForSourcesJson ,
  eventTriggersForSources ,
  eventDescriptionWithTriggers ,
  getPropertyVarInstance ,
  getPropertyVarName ,
  eventTriggersMarkdownForSources ,
  eventDescriptionWithMarkdownTriggers ,
  
}
/* Case Not a pure module */
