// Generated by ReScript, PLEASE EDIT WITH CARE

import * as BranchesDiscrepanciesWorker from "./BranchesDiscrepanciesWorker.mjs";

var instance = new ComlinkWorker(new URL("./BranchesDiscrepanciesWorker.mjs", import.meta.url), {});

function getBranchDiscrepancies(eventComponentsWithNames, config, mainModelToCompareToJson, branchModelToCompareToJson) {
  return instance.getBranchDiscrepancies(eventComponentsWithNames, config, mainModelToCompareToJson, branchModelToCompareToJson);
}

export {
  getBranchDiscrepancies ,
  
}
/* instance Not a pure module */
