// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as AvoLimits from "./AvoLimits.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as BranchActionBox__ApprovalWorkflow from "./BranchActionBox__ApprovalWorkflow.mjs";
import * as BranchActionBox__NoApprovalWorkflow from "./BranchActionBox__NoApprovalWorkflow.mjs";

function BranchActionBox(Props) {
  var actionDiff = Props.actionDiff;
  var actions = Props.actions;
  var branchId = Props.branchId;
  var branchDiffStatus = Props.branchDiffStatus;
  var branchName = Props.branchName;
  var branchModel = Props.branchModel;
  var branchModelWithPulledMaster = Props.branchModelWithPulledMaster;
  var branchStatus = Props.branchStatus;
  var changes = Props.changes;
  var masterModel = Props.masterModel;
  var masterModelBeforeBranch = Props.masterModelBeforeBranch;
  var mergeConflicts = Props.mergeConflicts;
  var role = Props.role;
  var workspace = WorkspaceContext.use(undefined);
  var approvalWorkflow = AvoLimits.ApprovalWorkflow.computeAvailability(workspace);
  if (approvalWorkflow === "AvailableDuringTrial" || approvalWorkflow === "Available") {
    return React.createElement(BranchActionBox__ApprovalWorkflow.make, {
                branchId: branchId,
                branchName: branchName,
                branchModelWithPulledMaster: branchModelWithPulledMaster,
                branchStatus: branchStatus,
                masterModel: masterModel,
                role: role
              });
  } else {
    return React.createElement(BranchActionBox__NoApprovalWorkflow.make, {
                actionDiff: actionDiff,
                actions: actions,
                branchDiffStatus: branchDiffStatus,
                branchId: branchId,
                branchModel: branchModel,
                branchModelWithPulledMaster: branchModelWithPulledMaster,
                branchName: branchName,
                branchStatus: branchStatus,
                changes: changes,
                masterModel: masterModel,
                masterModelBeforeBranch: masterModelBeforeBranch,
                mergeConflicts: mergeConflicts,
                role: role
              });
  }
}

var make = BranchActionBox;

export {
  make ,
  
}
/* react Not a pure module */
