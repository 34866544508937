// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Case from "../../../bs-case/src/case.mjs";
import * as Icon from "../Icon.mjs";
import * as Link from "../Link.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Toast from "../Toast.mjs";
import * as React from "react";
import * as Router from "../Router.mjs";
import * as Select from "../Select.mjs";
import * as Spacer from "../Spacer.mjs";
import * as Styles from "../styles.mjs";
import * as Mantine from "../Mantine.mjs";
import * as Tooltip from "../Tooltip.mjs";
import Shortid from "shortid";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Firebase from "../../../bs-firestore/src/Firebase.mjs";
import * as Markdown from "../Markdown.mjs";
import * as Tooltips from "../markdown/tooltips/tooltips.mjs";
import * as AvoConfig from "../../../shared/utils/AvoConfig.mjs";
import * as AvoLimits from "../AvoLimits.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as NameInput from "../NameInput.mjs";
import * as RoleUtils from "../RoleUtils.mjs";
import * as Workspace from "../../../model/src/Workspace.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "../ModelStore.mjs";
import * as ModelUtils from "../ModelUtils.mjs";
import * as TextButton from "../TextButton.mjs";
import * as AnalyticsRe from "../analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ContextMenu from "../ContextMenu.mjs";
import * as DrawerUtils from "../DrawerUtils.mjs";
import * as FeatureFlag from "../../../shared/FeatureFlag.mjs";
import * as NamedBranch from "../NamedBranch.mjs";
import * as RouterStore from "../RouterStore.mjs";
import * as CommentInput from "../CommentInput.mjs";
import * as DrawerHeader from "./DrawerHeader.mjs";
import * as OpsWithState from "../OpsWithState.mjs";
import * as PropertyRule from "../PropertyRule.mjs";
import * as EventAutofill from "../EventAutofill.mjs";
import * as FirebaseUtils from "../firebaseUtils.mjs";
import * as PlanLightning from "../PlanLightning.mjs";
import * as PropertyUtils from "../PropertyUtils.mjs";
import * as SimpleTooltip from "../SimpleTooltip.mjs";
import * as AnalyticsUtils from "../analyticsUtils.mjs";
import * as AppFeatureFlag from "../AppFeatureFlag.mjs";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as IconDescription from "../IconDescription.mjs";
import * as EnsureModelStore from "../EnsureModelStore.mjs";
import * as WorkspaceContext from "../WorkspaceContext.mjs";
import * as GlobalSendContext from "../GlobalSendContext.mjs";
import * as ObjectActivityLog from "../ObjectActivityLog.mjs";
import * as TrackingPlanModel from "../../../model/src/TrackingPlanModel.mjs";
import * as BeltListExtensions from "../BeltListExtensions.mjs";
import * as DiscrepancyContext from "../DiscrepancyContext.mjs";
import * as SendActionsContext from "../SendActionsContext.mjs";
import * as ValidationConfigV2 from "../guardrails/ValidationConfigV2.mjs";
import * as OrganizationContext from "../OrganizationContext.mjs";
import * as SchemaBundleContext from "../SchemaBundleContext.mjs";
import * as PropertyModalSources from "../PropertyModalSources.mjs";
import * as PropertyDetailsEvents from "../PropertyDetailsEvents.mjs";
import * as SystemPropertySources from "./SystemPropertySources.mjs";
import CopyTextToClipboard from "copy-text-to-clipboard";
import ReactTextareaAutosize from "react-textarea-autosize";
import * as GetRegexValidationUseCase from "../regex/GetRegexValidationUseCase.mjs";
import * as PropertyDetailsValueRules from "./PropertyDetailsValueRules.mjs";
import * as PropertyDetailsConstraints from "../PropertyDetailsConstraints.mjs";

var rootStyles = Curry._1(Css.style, {
      hd: Css.borderRadius(Styles.Border.radius),
      tl: {
        hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
        tl: {
          hd: Css.marginLeft(Css.px(60)),
          tl: {
            hd: Css.marginRight(Css.px(20)),
            tl: /* [] */0
          }
        }
      }
    });

var headerStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.justifyContent("spaceBetween"),
        tl: {
          hd: Css.alignItems("baseline"),
          tl: {
            hd: Css.backgroundColor(Styles.Color.light02),
            tl: {
              hd: Css.padding4(Css.px(12), Css.px(16), Css.px(12), Css.px(16)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var inputStyles = Curry._1(Css.style, {
      hd: Css.marginLeft(Css.px(8)),
      tl: {
        hd: Css.marginTop(Css.px(0)),
        tl: {
          hd: Css.marginBottom(Css.px(0)),
          tl: {
            hd: Css.marginRight(Css.px(0)),
            tl: /* [] */0
          }
        }
      }
    });

var rowStyles = Curry._1(Css.style, {
      hd: Css.borderTop(Css.px(1), "solid", Styles.Color.light04),
      tl: {
        hd: Css.padding4(Css.px(12), Css.px(16), Css.px(12), Css.px(16)),
        tl: /* [] */0
      }
    });

function PropertyDetails$SentInObjectProperties(Props) {
  var model = Props.model;
  var property = Props.property;
  var inProperties = Props.inProperties;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var handleChange = function (param) {
    var newOptional = !property.optionalWhenInObject;
    return Curry.app(sendActions, [
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                (function (branch) {
                    var schema = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                    var propertyBundle = AnalyticsUtils.getAnalyticsPropertyGroup(model, property);
                    return AnalyticsRe.propertyConfigured(propertyBundle.propertyId, Belt_Option.getWithDefault(propertyBundle.propertyName, ""), propertyBundle.namingConvention, propertyBundle.propertyType, propertyBundle.correctCase, schema.schemaId, schema.schemaName, schema.schemaBillingStatus, schema.branchId, schema.branchName, schema.schemaSubscriptionPlan, schema.schemaTrialPlan, schema.authenticationConfig, schema.schemaSubscriptionPlanSlug, schema.schemaTrialPlanSlug, schema.schemaType, "Presence", "UpdateNestedObjectKeyPresence", undefined, undefined, undefined, undefined, undefined, undefined, undefined, property.optionalWhenInObject ? "SometimesSent" : "AlwaysSent", newOptional ? "SometimesSent" : "AlwaysSent", NamedBranch.getId(branch), schemaBundle.schemaId);
                  }),
                undefined,
                [[
                    {
                      NAME: "UpdatePropertyOptionalWhenInObject",
                      VAL: [
                        property.id,
                        newOptional
                      ]
                    },
                    {
                      propertyId: property.id,
                      propertyQuery: property.id
                    }
                  ]]
              ]);
  };
  return React.createElement("section", {
              className: rootStyles
            }, React.createElement("header", {
                  className: headerStyles
                }, React.createElement($$Text.make, {
                      element: "H1",
                      size: "Medium",
                      weight: "Semi",
                      color: Styles.Color.light11,
                      children: "Sent as part of " + (String(Belt_List.length(inProperties)) + (" Object " + (
                            Belt_List.length(inProperties) === 1 ? "Property" : "Properties"
                          )))
                    }), React.createElement($$Text.make, {
                      element: "Label",
                      size: "Small",
                      weight: "Semi",
                      color: Styles.Color.light10,
                      children: React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.display("flex"),
                                  tl: {
                                    hd: Css.alignItems("center"),
                                    tl: /* [] */0
                                  }
                                })
                          }, "Optional", React.createElement("input", {
                                className: inputStyles,
                                checked: property.optionalWhenInObject,
                                type: "checkbox",
                                onChange: handleChange
                              }))
                    })), Belt_List.toArray(Belt_List.map(inProperties, (function (param) {
                        var id = param.id;
                        return React.createElement("div", {
                                    key: id,
                                    className: rowStyles
                                  }, React.createElement($$Text.make, {
                                        size: "Medium",
                                        weight: "Semi",
                                        color: Styles.Color.light10,
                                        children: React.createElement(Link.make, {
                                              path: Router.Link.addDrawerItem(undefined, {
                                                    NAME: "property",
                                                    VAL: [
                                                      id,
                                                      undefined
                                                    ]
                                                  }),
                                              children: param.name
                                            })
                                      }));
                      }))));
}

function getPropertyTypeName(propertyType, model) {
  if (typeof propertyType !== "number") {
    return Belt_Option.mapWithDefault(ModelUtils.getGroupTypeName(propertyType._0, model), "", (function (name) {
                  return name + " ";
                })) + "Group";
  }
  switch (propertyType) {
    case /* SystemProperty */0 :
        return "System";
    case /* EventProperty */1 :
        return "Event";
    case /* UserProperty */2 :
        return "User";
    
  }
}

function description(builtIn) {
  return Curry._1(Css.merge, {
              hd: Curry._1(Css.style, Styles.input),
              tl: {
                hd: Curry._1(Css.style, {
                      hd: Css.fontWeight(Styles.FontWeight.regular),
                      tl: {
                        hd: Css.fontSize(Styles.FontSize.regular),
                        tl: {
                          hd: Css.color(Styles.Color.light12),
                          tl: {
                            hd: Css.cursor(builtIn !== "NotBuiltIn" ? "default" : "text"),
                            tl: {
                              hd: Css.zIndex(100),
                              tl: {
                                hd: Css.borderRadius(Styles.Border.radius),
                                tl: {
                                  hd: Css.border(Css.px(1), "solid", "transparent"),
                                  tl: {
                                    hd: Css.padding(Css.px(10)),
                                    tl: {
                                      hd: Css.marginLeft(Css.px(-10)),
                                      tl: {
                                        hd: Css.selector(":not(:disabled)", {
                                              hd: Css.focus({
                                                    hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                                                    tl: /* [] */0
                                                  }),
                                              tl: {
                                                hd: Css.hover({
                                                      hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                                                      tl: /* [] */0
                                                    }),
                                                tl: /* [] */0
                                              }
                                            }),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }),
                tl: /* [] */0
              }
            });
}

function PropertyDetails(Props) {
  var currentBranch = Props.currentBranch;
  var currentFilters = Props.currentFilters;
  var filters = Props.filters;
  var eventId = Props.eventId;
  var goToCommentId = Props.goToCommentId;
  var member = Props.member;
  var model = Props.model;
  var openBranches = Props.openBranches;
  var propertyId = Props.propertyId;
  var role = Props.role;
  var schema = Props.schema;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var workspace = WorkspaceContext.use(undefined);
  var mappedModel = ModelStore.Mapped.use(undefined);
  var hasAnyValuesFeatureFlag = AppFeatureFlag.useFeatureFlag("AnyValues");
  var hasLongValuesFeatureFlag = AppFeatureFlag.useFeatureFlag("LongValues");
  var addToast = Toast.useAddToast(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var globalSend = GlobalSendContext.use(undefined);
  var organization = OrganizationContext.useOrganization(undefined);
  var isGlobalWorkspace = OrganizationContext.useIsGlobalWorkspace(undefined);
  var globalProperty = Curry._1(ModelStore.Mapped.GlobalRequirements.useGlobalProperty, propertyId);
  var branchBarVisible = RouterStore.useBranchBarVisible(undefined);
  var schemaRoute = RouterStore.Schema.useSchemaRoute(undefined);
  var match = React.useContext(DiscrepancyContext.context);
  var expectedPropertyCase = match.expectedPropertyCase;
  var config = match.config;
  var property = Belt_List.head(Belt_List.keepMap(model.properties, (function (x) {
              if (x.TAG === /* PropertyRef */0) {
                return ;
              }
              var property = x._0;
              if (property.id === propertyId) {
                return property;
              }
              
            })));
  var $$event = Belt_Option.flatMap(eventId, (function (id) {
          return Belt_List.getBy(model.events, (function ($$event) {
                        return $$event.id === id;
                      }));
        }));
  var tmp;
  if (property !== undefined) {
    var propertyGroup = AnalyticsUtils.getAnalyticsPropertyGroup(model, property);
    var propertyEvents = ModelUtils.eventsSendingProperty(model, undefined, property.id);
    var inObjectProperties = ModelUtils.getPropertyImmediateParents(property.id, model);
    var filter = member.filter;
    var propertyEvents$1 = filter !== undefined ? Belt_List.keep(propertyEvents, (function ($$event) {
              return Belt_List.some($$event.tags, (function (item) {
                            return Belt_List.some(Belt_List.fromArray(filter.tags), (function (tag) {
                                          return item === tag;
                                        }));
                          }));
            })) : propertyEvents;
    var tmp$1 = {
      propertyId: property.id,
      propertyQuery: property.id
    };
    if (eventId !== undefined) {
      tmp$1.eventId = Caml_option.valFromOption(eventId);
    }
    var context = tmp$1;
    var hasMigratedToAbsence = ModelUtils.hasMigrated(model, "PropertyOptionalAndExcludedSourcesToAbsence");
    var fromItem_itemId = property.id;
    var fromItem_itemName = property.name;
    var fromItem = {
      itemId: fromItem_itemId,
      itemName: fromItem_itemName,
      itemType: "Property"
    };
    var tmp$2;
    if (organization !== undefined) {
      var match$1 = organization.globalWorkspaceIds;
      if (match$1.length !== 1) {
        tmp$2 = null;
      } else {
        var id = match$1[0];
        tmp$2 = globalProperty !== undefined ? React.createElement("section", {
                className: Curry._1(Css.style, {
                      hd: Css.margin(Css.px(16)),
                      tl: {
                        hd: Css.marginBottom(Css.px(0)),
                        tl: {
                          hd: Css.padding(Css.px(16)),
                          tl: {
                            hd: Css.borderRadius(Css.px(8)),
                            tl: {
                              hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.paleBlue, 0.5)),
                              tl: {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.gap(Css.px(10)),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    })
              }, React.createElement("div", undefined, React.createElement(IconDescription.make, {
                        fill: Styles.Color.blue
                      })), React.createElement("div", undefined, React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.alignItems("center"),
                                tl: {
                                  hd: Css.gap(Css.px(8)),
                                  tl: /* [] */0
                                }
                              }
                            })
                      }, React.createElement($$Text.make, {
                            size: "Medium",
                            weight: "Semi",
                            color: Styles.Color.deepBlue,
                            children: "Global property"
                          }), React.createElement(SimpleTooltip.make, {
                            maxWidth: 240,
                            tooltip: "This is a globally defined property so editing is limited",
                            children: React.createElement(Icon.make, {
                                  type_: "information",
                                  size: "medium",
                                  color: Styles.Color.light09
                                })
                          })), React.createElement(Spacer.make, {
                        height: 8
                      }), React.createElement(Link.make, {
                        path: Curry._4(Router.Link.get, {
                              NAME: "schema",
                              VAL: id
                            }, undefined, "events", {
                              hd: {
                                NAME: "property",
                                VAL: [
                                  propertyId,
                                  undefined
                                ]
                              },
                              tl: /* [] */0
                            }),
                        className: Curry._1(Css.style, {
                              hd: Css.fontSize(Styles.FontSize.regular),
                              tl: {
                                hd: Css.color(Styles.Color.deepBlue),
                                tl: {
                                  hd: Css.hover({
                                        hd: Css.textDecoration("underline"),
                                        tl: /* [] */0
                                      }),
                                  tl: /* [] */0
                                }
                              }
                            }),
                        children: React.createElement($$Text.make, {
                              children: "View in global workspace"
                            })
                      }))) : null;
      }
    } else {
      tmp$2 = null;
    }
    var template = schema.onboardingTemplate;
    var tmp$3;
    if (globalProperty !== undefined) {
      var description$1 = globalProperty.description;
      var tmp$4 = {
        className: description(globalProperty.builtIn),
        maxRows: 15,
        minRows: 1,
        placeholder: "Global description missing...",
        value: description$1.trim(),
        id: "global-description-input",
        disabled: true
      };
      var tmp$5 = description$1.trim() === "" ? 1 : undefined;
      if (tmp$5 !== undefined) {
        tmp$4.rows = tmp$5;
      }
      tmp$3 = React.createElement(React.Fragment, undefined, React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: {
                          hd: Css.gap(Css.px(4)),
                          tl: {
                            hd: Css.padding(Css.px(10)),
                            tl: {
                              hd: Css.paddingLeft(Css.px(0)),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    })
              }, React.createElement($$Text.make, {
                    size: "Small",
                    weight: "Semi",
                    color: Styles.Color.light09,
                    children: "Global description"
                  }), React.createElement(Icon.make, {
                    type_: "lock",
                    size: "small",
                    color: Styles.Color.light09
                  })), React.createElement(ReactTextareaAutosize, tmp$4), React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: {
                          hd: Css.gap(Css.px(4)),
                          tl: {
                            hd: Css.padding(Css.px(10)),
                            tl: {
                              hd: Css.paddingLeft(Css.px(0)),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    })
              }, React.createElement($$Text.make, {
                    size: "Small",
                    weight: "Semi",
                    color: Styles.Color.light09,
                    children: "Description"
                  })));
    } else {
      tmp$3 = null;
    }
    var hasSnowplowDestination = Belt_List.someU(model.destinations, (function (destination) {
            var match = destination.type_;
            if (match !== undefined) {
              return match === "Snowplow";
            } else {
              return false;
            }
          }));
    var match$2 = property.type_;
    var tmp$6 = match$2 === "object" && hasSnowplowDestination ? React.createElement("div", {
            className: Curry._1(Css.style, {
                  hd: Css.marginRight(Css.px(15)),
                  tl: {
                    hd: Css.fontWeight(Styles.FontWeight.semi),
                    tl: {
                      hd: Css.fontSize(Styles.FontSize.regular),
                      tl: {
                        hd: Css.color(Styles.Color.light11),
                        tl: /* [] */0
                      }
                    }
                  }
                })
          }, React.createElement("input", {
                id: "snowplow-context",
                checked: property.operation === "SnowplowContext",
                disabled: !RoleUtils.canEdit(role),
                type: "checkbox",
                onChange: (function ($$event) {
                    var isSnowplowContext = $$event.target.checked;
                    return Curry.app(sendActions, [
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                [[
                                    {
                                      NAME: "UpdatePropertyOperation",
                                      VAL: [
                                        property.id,
                                        isSnowplowContext ? "SnowplowContext" : "Set"
                                      ]
                                    },
                                    context
                                  ]]
                              ]);
                  })
              }), React.createElement("label", {
                htmlFor: "snowplow-context"
              }, "Snowplow Context")) : null;
    var match$3 = property.sendAs;
    var tmp$7;
    if (match$3 === 0) {
      tmp$7 = React.createElement("div", {
            className: Curry._1(Css.style, {
                  hd: Css.fontWeight(Styles.FontWeight.semi),
                  tl: {
                    hd: Css.fontSize(Styles.FontSize.regular),
                    tl: {
                      hd: Css.paddingLeft(Css.px(60)),
                      tl: {
                        hd: Css.paddingRight(Css.px(20)),
                        tl: /* [] */0
                      }
                    }
                  }
                })
          }, React.createElement(SystemPropertySources.make, {
                model: model,
                sources: model.sources,
                viewerCanEdit: RoleUtils.canEdit(role),
                property: property,
                sendActions: sendActions
              }));
    } else {
      var propertyEventIds = Belt_SetString.fromArray(Belt_List.toArray(Belt_List.mapU(propertyEvents$1, (function (propertyEvent) {
                      return propertyEvent.id;
                    }))));
      tmp$7 = React.createElement("div", {
            className: Curry._1(Css.style, {
                  hd: Css.paddingRight(Css.px(20)),
                  tl: {
                    hd: Css.paddingLeft(Css.px(60)),
                    tl: /* [] */0
                  }
                })
          }, hasMigratedToAbsence ? (
              propertyEvents$1 ? React.createElement(EnsureModelStore.make, {
                      children: React.createElement(PropertyDetailsEvents.make, {
                            viewerCanEdit: RoleUtils.canEdit(role),
                            property: property,
                            events: propertyEvents$1
                          })
                    }) : React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Semi",
                          children: "Sent in 0 events"
                        }), React.createElement(Tooltip.WithAnalytics.make, {
                          fromItem: fromItem,
                          title: "Sending the Property to Events",
                          children: React.createElement(Tooltip.Content.make, {
                                children: React.createElement(Markdown.make, {
                                      source: Tooltips.sentInEvents
                                    })
                              })
                        }))
            ) : React.createElement(React.Fragment, undefined, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.fontWeight(Styles.FontWeight.semi),
                            tl: {
                              hd: Css.fontSize(Styles.FontSize.regular),
                              tl: {
                                hd: Css.paddingBottom(Css.px(5)),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, "Sent In " + String(Belt_List.length(propertyEvents$1)) + " Events", React.createElement(Tooltip.WithAnalytics.make, {
                          fromItem: fromItem,
                          title: "Sending the Property to Events",
                          children: React.createElement(Tooltip.Content.make, {
                                children: React.createElement(Markdown.make, {
                                      source: Tooltips.sentInEvents
                                    })
                              })
                        })), React.createElement("div", undefined, Belt_List.toArray(Belt_List.mapU(propertyEvents$1, (function ($$event) {
                                return React.createElement("div", {
                                            key: $$event.id,
                                            className: Curry._1(Css.style, {
                                                  hd: Css.display("flex"),
                                                  tl: {
                                                    hd: Css.width(Css.pct(100)),
                                                    tl: {
                                                      hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
                                                      tl: {
                                                        hd: Css.alignItems("center"),
                                                        tl: {
                                                          hd: Css.justifyContent("spaceBetween"),
                                                          tl: /* [] */0
                                                        }
                                                      }
                                                    }
                                                  }
                                                })
                                          }, React.createElement(Link.make, {
                                                path: Router.Link.addDrawerItem(undefined, {
                                                      NAME: "event",
                                                      VAL: [
                                                        $$event.id,
                                                        undefined,
                                                        undefined,
                                                        false
                                                      ]
                                                    }),
                                                className: Curry._1(Css.style, {
                                                      hd: Css.display("block"),
                                                      tl: {
                                                        hd: Css.fontWeight(Styles.FontWeight.semi),
                                                        tl: {
                                                          hd: Css.color(Styles.Color.light10),
                                                          tl: {
                                                            hd: Css.hover({
                                                                  hd: Css.color(Styles.Color.darkBlue),
                                                                  tl: /* [] */0
                                                                }),
                                                            tl: {
                                                              hd: Css.padding2(Css.px(8), Css.px(0)),
                                                              tl: {
                                                                hd: Css.fontSize(Styles.FontSize.regular),
                                                                tl: /* [] */0
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }),
                                                children: $$event.name
                                              }), RoleUtils.canEdit(role) ? React.createElement(ContextMenu.make, {
                                                  options: [{
                                                      NAME: "Option",
                                                      VAL: {
                                                        label: "Remove Event",
                                                        onClick: (function (param) {
                                                            var eventId = $$event.id;
                                                            var eventName = $$event.name;
                                                            Curry.app(sendActions, [
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  [[
                                                                      {
                                                                        NAME: "RemovePropertyRefV3",
                                                                        VAL: [
                                                                          eventId,
                                                                          property.id
                                                                        ]
                                                                      },
                                                                      {
                                                                        eventId: eventId,
                                                                        eventQuery: eventId,
                                                                        propertyId: property.id,
                                                                        propertyQuery: property.id
                                                                      }
                                                                    ]]
                                                                ]);
                                                            return AnalyticsRe.propertyRemoved(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, propertyGroup.propertyId, property.name, propertyGroup.namingConvention, propertyGroup.propertyType, propertyGroup.correctCase, eventId, eventName, "PropertyDetails", schemaBundle.branchId, schemaBundle.schemaId);
                                                          })
                                                      }
                                                    }]
                                                }) : null);
                              }))))), React.createElement(EventAutofill.make, {
                events: Belt_List.keepU(model.events, (function ($$event) {
                        return !Belt_SetString.has(propertyEventIds, $$event.id);
                      })),
                onSelectSuccess: (function (eventId, eventName, branch) {
                    var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                    return AnalyticsRe.propertyAdded(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, eventId, eventName, propertyGroup.propertyId, property.name, propertyGroup.namingConvention, propertyGroup.propertyType, propertyGroup.correctCase, currentFilters.currentTagFilters, currentFilters.currentSourceFilters, currentFilters.currentDestinationFilters, currentFilters.currentSorting, currentFilters.currentPropertyGroupFilters, currentFilters.currentPropertyFilters, currentFilters.currentActionFilters, currentFilters.currentCategoryFilters, property.name.length, "New", Case.to_("Sentence", property.name).split(" ").length, property.name.length, "PropertyDetails", NamedBranch.getId(branch), schemaBundle$1.schemaId);
                  }),
                renderButton: (function (onClick, buttonRef, _isOpen) {
                    return React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                                    height: 10
                                  }), React.createElement(TextButton.make, {
                                    onClick: onClick,
                                    style: "Blue",
                                    size: "Medium",
                                    children: "+ Add Event",
                                    ref: buttonRef
                                  }));
                  }),
                currentFilters: currentFilters,
                eventOrigin: "PropertyDetails",
                selectActions: (function (eventId) {
                    return [[
                              {
                                NAME: "AddPropertyRef",
                                VAL: [
                                  eventId,
                                  property.id
                                ]
                              },
                              {
                                eventId: eventId,
                                eventQuery: eventId,
                                propertyId: property.id,
                                propertyQuery: property.id
                              }
                            ]];
                  })
              }));
    }
    var shouldShowEventSpecificPropertyValuesFeature = property.type_ === "string" && Caml_obj.caml_notequal(property.eventSpecificAllowedPropertyValues, []) && Belt_Option.isNone(GetRegexValidationUseCase.get(property));
    var tmp$8;
    if (RoleUtils.canEdit(role)) {
      var match$4 = AvoLimits.NameMapping.computeAvailability(workspace);
      tmp$8 = React.createElement("button", {
            className: Curry._1(Css.merge, {
                  hd: Curry._1(Css.style, Styles.button),
                  tl: {
                    hd: Curry._1(Css.style, {
                          hd: Css.fontWeight(Styles.FontWeight.semi),
                          tl: {
                            hd: Css.color(Styles.Color.darkBlue),
                            tl: {
                              hd: Css.marginTop(Css.px(5)),
                              tl: {
                                hd: Css.padding2(Css.px(2), Css.px(0)),
                                tl: {
                                  hd: Css.width(Css.pct(100)),
                                  tl: {
                                    hd: Css.textAlign("left"),
                                    tl: {
                                      hd: Css.fontSize(Styles.FontSize.regular),
                                      tl: {
                                        hd: Css.hover({
                                              hd: Css.color(Styles.Color.deepBlue),
                                              tl: /* [] */0
                                            }),
                                        tl: {
                                          hd: Css.display("flex"),
                                          tl: {
                                            hd: Css.alignItems("center"),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }),
                    tl: /* [] */0
                  }
                }),
            onClick: (function (param) {
                if (AvoLimits.NameMapping.isAvailable(workspace)) {
                  return Curry.app(sendActions, [
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              (function (branch) {
                                  var propertyNameMappingsInSchema = Belt_List.concat(Belt_List.keepMap(model.rules, (function (rule) {
                                              var match = rule.item;
                                              var match$1 = rule.definition;
                                              if (typeof match !== "object") {
                                                return ;
                                              }
                                              if (match.NAME !== "Property") {
                                                return ;
                                              }
                                              if (typeof match$1 !== "object") {
                                                return ;
                                              }
                                              var variant = match$1.NAME;
                                              if (variant === "NameInLocalWorkspace" || variant === "NameMapping") {
                                                return match.VAL;
                                              }
                                              
                                            })), {
                                        hd: propertyId,
                                        tl: /* [] */0
                                      });
                                  var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                  AnalyticsRe.propertyUpdated(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, propertyGroup.propertyId, property.name, propertyGroup.namingConvention, propertyGroup.propertyType, propertyGroup.correctCase, "AddRule", undefined, isGlobalWorkspace ? "NameInLocalWorkspace" : "NameMapping", Belt_List.length(BeltListExtensions.dedupeString(propertyNameMappingsInSchema)), Belt_List.length(propertyNameMappingsInSchema), Belt_List.length(Belt_List.keep(model.rules, (function (rule) {
                                                  var match = rule.item;
                                                  var match$1 = rule.definition;
                                                  if (typeof match !== "object") {
                                                    return false;
                                                  }
                                                  if (match.NAME !== "Property") {
                                                    return false;
                                                  }
                                                  if (typeof match$1 !== "object") {
                                                    return false;
                                                  }
                                                  var variant = match$1.NAME;
                                                  if (variant === "NameInLocalWorkspace" || variant === "NameMapping") {
                                                    return match.VAL === property.id;
                                                  } else {
                                                    return false;
                                                  }
                                                }))) + 1 | 0, Belt_List.length(model.properties), NamedBranch.getId(branch), schemaBundle$1.schemaId);
                                  
                                }),
                              undefined,
                              [[
                                  {
                                    NAME: "AddRule",
                                    VAL: [
                                      Shortid(),
                                      {
                                        NAME: "Property",
                                        VAL: property.id
                                      },
                                      isGlobalWorkspace ? ({
                                            NAME: "NameInLocalWorkspace",
                                            VAL: {
                                              workspace: undefined,
                                              name: undefined
                                            }
                                          }) : ({
                                            NAME: "NameMapping",
                                            VAL: {
                                              destination: undefined,
                                              name: undefined
                                            }
                                          })
                                    ]
                                  },
                                  context
                                ]]
                            ]);
                } else {
                  return Curry._1(globalSend, {
                              TAG: /* OpenModal */4,
                              _0: {
                                NAME: "BillingPrompt",
                                VAL: "NameMapping"
                              }
                            });
                }
              })
          }, "+ Add Mapping", match$4 === "Available" ? null : React.createElement("span", {
                  className: Curry._1(Css.style, {
                        hd: Css.paddingTop(Css.px(1)),
                        tl: {
                          hd: Css.marginLeft(Css.px(8)),
                          tl: {
                            hd: Css_Legacy_Core.SVG.fill("transparent"),
                            tl: {
                              hd: Css_Legacy_Core.SVG.stroke(Styles.Color.light10),
                              tl: {
                                hd: Css.zIndex(1),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      })
                }, React.createElement(PlanLightning.make, {
                      tooltipText: match$4 === "AvailableDuringTrial" ? "Name mapping is available during trial and on the " + (Workspace.printPlanName(AvoLimits.NameMapping.availableOnPlan(workspace).name) + " plan") : "Name mapping is available on the " + (Workspace.printPlanName(AvoLimits.NameMapping.availableOnPlan(workspace).name) + " plan. "),
                      tooltipDirection: "right"
                    })));
    } else {
      tmp$8 = null;
    }
    var tmp$9 = {
      object_: {
        NAME: "Property",
        VAL: property
      },
      objectId: property.id,
      currentBranch: currentBranch,
      openBranches: openBranches
    };
    if ($$event !== undefined) {
      tmp$9.event = Caml_option.valFromOption($$event);
    }
    if (goToCommentId !== undefined) {
      tmp$9.goToCommentId = Caml_option.valFromOption(goToCommentId);
    }
    var tmp$10 = {
      threadId: property.id,
      itemType: "Property",
      itemName: property.name,
      mini: true,
      currentBranch: currentBranch,
      role: role,
      location: "Property"
    };
    if (eventId !== undefined) {
      tmp$10.eventId = Caml_option.valFromOption(eventId);
    }
    tmp = React.createElement(React.Fragment, undefined, React.createElement(DrawerHeader.make, {
              kind: (
                globalProperty !== undefined || isGlobalWorkspace ? "Global " : ""
              ) + getPropertyTypeName(property.sendAs, model) + " Property",
              options: Belt_Array.concatMany([
                    RoleUtils.canEdit(role) ? [{
                          NAME: "Option",
                          VAL: {
                            label: "Archive Property",
                            onClick: (function (param) {
                                if (Belt_List.length(propertyEvents$1) > 1 && !window.confirm("Property is used in " + String(Belt_List.length(propertyEvents$1)) + " events, are you sure you want to remove it?")) {
                                  return ;
                                } else {
                                  Router.Schema.replaceDrawerItems(undefined, Belt_List.keep(Router.getStaticRoute(undefined).drawerItems, (function (item) {
                                              if (typeof item === "object" && item.NAME === "property") {
                                                return item.VAL[0] !== property.id;
                                              } else {
                                                return true;
                                              }
                                            })));
                                  return Curry.app(sendActions, [
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              (function (branch) {
                                                  Curry._1(addToast, {
                                                        message: "The property has been archived"
                                                      });
                                                  var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                                  return AnalyticsRe.propertyDeleted(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, Belt_Option.mapWithDefault($$event, "N/A", (function ($$event) {
                                                                    return $$event.name;
                                                                  })), property.name, Belt_Option.mapWithDefault($$event, "N/A", (function ($$event) {
                                                                    return $$event.id;
                                                                  })), property.id, AnalyticsUtils.sendAsToPropertyType(property.sendAs), NamedBranch.getId(branch), schemaBundle$1.schemaId);
                                                }),
                                              undefined,
                                              Belt_Array.concat(Belt_Array.map(Belt_List.toArray(propertyEvents$1), (function ($$event) {
                                                          return [
                                                                  {
                                                                    NAME: "RemovePropertyRefV3",
                                                                    VAL: [
                                                                      $$event.id,
                                                                      property.id
                                                                    ]
                                                                  },
                                                                  {
                                                                    eventId: $$event.id,
                                                                    eventQuery: $$event.id,
                                                                    propertyId: property.id,
                                                                    propertyQuery: property.id
                                                                  }
                                                                ];
                                                        })), [[
                                                      {
                                                        NAME: "Archive",
                                                        VAL: {
                                                          NAME: "Property",
                                                          VAL: property.id
                                                        }
                                                      },
                                                      {
                                                        propertyId: property.id,
                                                        propertyQuery: property.id
                                                      }
                                                    ]])
                                            ]);
                                }
                              })
                          }
                        }] : [],
                    [
                      {
                        NAME: "Option",
                        VAL: {
                          label: "Copy URL",
                          onClick: (function (param) {
                              CopyTextToClipboard(Router.Link.getSimple(undefined));
                              
                            })
                        }
                      },
                      {
                        NAME: "Option",
                        VAL: {
                          label: "Replace property with...",
                          onClick: (function (param) {
                              return Curry._1(globalSend, {
                                          TAG: /* OpenModal */4,
                                          _0: {
                                            NAME: "MergeProperties",
                                            VAL: property.id
                                          }
                                        });
                            })
                        }
                      }
                    ]
                  ]),
              children: React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.position("relative"),
                          tl: {
                            hd: Css.height(Css.px(60)),
                            tl: {
                              hd: Css.marginLeft(Css.px(-20)),
                              tl: {
                                hd: Css.marginTop(Css.px(-19)),
                                tl: {
                                  hd: Css.marginBottom(Css.px(-19)),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        })
                  }, React.createElement(NameInput.make, {
                        name: property.name,
                        existingNames: ModelUtils.getPropertyNamespace__mapped(mappedModel),
                        expectedCase: expectedPropertyCase,
                        forceCase: Curry._1(ValidationConfigV2.Rule.isEnforced, config.properties.case),
                        autoFocus: false,
                        onChange: (function (propertyName, namingConvention, correctCase, _onReset) {
                            if (propertyName !== property.name) {
                              return Curry.app(sendActions, [
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          (function (branch) {
                                              var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                              var propertyGroup_propertyId = propertyGroup.propertyId;
                                              var propertyGroup_propertyName = propertyGroup.propertyName;
                                              var propertyGroup_namingConvention = Case.toAnalytics(namingConvention);
                                              var propertyGroup_propertyType = propertyGroup.propertyType;
                                              var propertyGroup_correctCase = correctCase;
                                              return AnalyticsRe.propertyUpdated(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, propertyGroup_propertyId, property.name, propertyGroup_namingConvention, propertyGroup_propertyType, propertyGroup_correctCase, "Rename", property.name, undefined, undefined, undefined, undefined, Belt_List.length(model.properties), NamedBranch.getId(branch), schemaBundle$1.schemaId);
                                            }),
                                          undefined,
                                          [[
                                              {
                                                NAME: "UpdatePropertyName",
                                                VAL: [
                                                  property.id,
                                                  propertyName
                                                ]
                                              },
                                              context
                                            ]]
                                        ]);
                            }
                            
                          }),
                        submitLabel: "Update Name",
                        placeholder: "Property Name",
                        fullscreen: false,
                        disabled: !RoleUtils.canEdit(role),
                        inputStyles: {
                          hd: Css.padding2(Css.px(0), Css.px(20)),
                          tl: /* [] */0
                        },
                        itemType: "Property",
                        actionType: "Rename",
                        getPossibleItemLink: (function (propertyName) {
                            return Belt_Option.map(ModelUtils.getPropertyByName(propertyName, model), (function (param) {
                                          return Router.Link.addDrawerItem(undefined, {
                                                      NAME: "property",
                                                      VAL: [
                                                        param.id,
                                                        undefined
                                                      ]
                                                    });
                                        }));
                          })
                      }))
            }), tmp$2, React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.maxHeight({
                          NAME: "subtract",
                          VAL: [
                            Css.vh(100),
                            Css.px(DrawerUtils.getTopOffsetAndHeaderHeight(workspace, branchBarVisible, schemaRoute))
                          ]
                        }),
                    tl: {
                      hd: Css.overflowY("auto"),
                      tl: {
                        hd: Css.paddingTop(Css.px(16)),
                        tl: {
                          hd: Css.paddingBottom(Css.px(16)),
                          tl: /* [] */0
                        }
                      }
                    }
                  })
            }, !(template == null) && Belt_Array.some(template.properties, (function (id) {
                    return id === property.id;
                  })) ? React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.padding2(Css.px(10), Css.px(35)),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.light02),
                            tl: {
                              hd: Css.margin2(Css.px(10), Css.px(0)),
                              tl: {
                                hd: Css.color(Styles.Color.light10),
                                tl: {
                                  hd: Css.fontWeight(Styles.FontWeight.semi),
                                  tl: {
                                    hd: Css.fontSize(Styles.FontSize.regular),
                                    tl: {
                                      hd: Css.display("flex"),
                                      tl: {
                                        hd: Css.alignItems("center"),
                                        tl: {
                                          hd: Css.justifyContent("spaceBetween"),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        })
                  }, "This is an example property", RoleUtils.canEdit(role) ? React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: /* [] */0
                              })
                        }, React.createElement("button", {
                              className: Curry._1(Css.merge, {
                                    hd: Curry._1(Css.style, Styles.button),
                                    tl: {
                                      hd: Curry._1(Css.style, {
                                            hd: Css.fontSize(Styles.FontSize.small),
                                            tl: {
                                              hd: Css.fontWeight(Styles.FontWeight.bold),
                                              tl: {
                                                hd: Css.color(Styles.Color.light10),
                                                tl: {
                                                  hd: Css.margin2(Css.px(0), Css.px(2)),
                                                  tl: {
                                                    hd: Css.hover({
                                                          hd: Css.color(Styles.Color.green),
                                                          tl: /* [] */0
                                                        }),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            }
                                          }),
                                      tl: /* [] */0
                                    }
                                  }),
                              onClick: (function (param) {
                                  var schemaRef = Firebase.app(undefined).firestore().collection("schemas").doc(schema.id);
                                  schemaRef.set({
                                        onboardingTemplate: {
                                          events: template.events,
                                          properties: Belt_Array.keep(template.properties, (function (id) {
                                                  return id !== property.id;
                                                })),
                                          propertyGroups: template.propertyGroups,
                                          goals: template.goals,
                                          metrics: template.metrics
                                        }
                                      }, {"merge": true});
                                  return AnalyticsRe.exampleInteraction(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "KeepCurrentExample", "Property", schemaBundle.branchId, schemaBundle.schemaId);
                                })
                            }, "Keep Property"), React.createElement("button", {
                              className: Curry._1(Css.merge, {
                                    hd: Curry._1(Css.style, Styles.button),
                                    tl: {
                                      hd: Curry._1(Css.style, {
                                            hd: Css.fontSize(Styles.FontSize.small),
                                            tl: {
                                              hd: Css.fontWeight(Styles.FontWeight.bold),
                                              tl: {
                                                hd: Css.color(Styles.Color.light10),
                                                tl: {
                                                  hd: Css.margin2(Css.px(0), Css.px(2)),
                                                  tl: {
                                                    hd: Css.hover({
                                                          hd: Css.color(Styles.Color.darkRed),
                                                          tl: /* [] */0
                                                        }),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            }
                                          }),
                                      tl: /* [] */0
                                    }
                                  }),
                              onClick: (function (param) {
                                  var match = Router.getStaticRoute(undefined);
                                  var newDrawerItems = Belt_List.keep(match.drawerItems, (function (item) {
                                          if (typeof item === "object" && item.NAME === "property") {
                                            return item.VAL[0] !== property.id;
                                          } else {
                                            return true;
                                          }
                                        }));
                                  Router.Schema.replaceDrawerItems(undefined, newDrawerItems);
                                  return Curry.app(sendActions, [
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              (function (branch) {
                                                  var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                                  return AnalyticsRe.exampleInteraction(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, "RemoveCurrentExample", "Property", NamedBranch.getId(branch), schemaBundle$1.schemaId);
                                                }),
                                              undefined,
                                              [[
                                                  {
                                                    NAME: "Archive",
                                                    VAL: {
                                                      NAME: "Property",
                                                      VAL: property.id
                                                    }
                                                  },
                                                  {
                                                    propertyId: property.id,
                                                    propertyQuery: property.id
                                                  }
                                                ]]
                                            ]);
                                })
                            }, "Remove Property"), React.createElement("button", {
                              className: Curry._1(Css.merge, {
                                    hd: Curry._1(Css.style, Styles.button),
                                    tl: {
                                      hd: Curry._1(Css.style, {
                                            hd: Css.fontSize(Styles.FontSize.small),
                                            tl: {
                                              hd: Css.fontWeight(Styles.FontWeight.bold),
                                              tl: {
                                                hd: Css.backgroundColor(Styles.Color.red),
                                                tl: {
                                                  hd: Css.color(Styles.Color.white),
                                                  tl: {
                                                    hd: Css.margin2(Css.px(0), Css.px(5)),
                                                    tl: {
                                                      hd: Css.padding2(Css.px(5), Css.px(10)),
                                                      tl: {
                                                        hd: Css.borderRadius(Styles.Border.radius),
                                                        tl: {
                                                          hd: Css.hover({
                                                                hd: Css.backgroundColor(Styles.Color.darkRed),
                                                                tl: /* [] */0
                                                              }),
                                                          tl: /* [] */0
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }),
                                      tl: /* [] */0
                                    }
                                  }),
                              onClick: (function (param) {
                                  FirebaseUtils.archiveTemplate(template, (function (eta) {
                                          return Curry.app(sendActions, [
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      eta
                                                    ]);
                                        }));
                                  return AnalyticsRe.exampleInteraction(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "RemoveAllExamples", "Property", schemaBundle.branchId, schemaBundle.schemaId);
                                })
                            }, "Remove All Examples")) : null) : null, Belt_Option.mapWithDefault(property.uniqueName, null, (function (uniqueName) {
                    var propertiesWithSameName = Belt_List.keepMapU(model.properties, (function (item) {
                            if (item.TAG === /* PropertyRef */0) {
                              return ;
                            }
                            var item$1 = item._0;
                            if (Case.camel(item$1.name) === Case.camel(property.name) && item$1.id !== property.id) {
                              return item$1;
                            }
                            
                          }));
                    var tmp;
                    if (propertiesWithSameName) {
                      if (propertiesWithSameName.tl) {
                        tmp = React.createElement("div", undefined, React.createElement("span", undefined, "There are " + String(Belt_List.length(propertiesWithSameName)) + " properties with the same or similar name:"), React.createElement("span", undefined, Belt_List.toArray(Belt_List.map(propertiesWithSameName, (function (property) {
                                            return React.createElement("span", {
                                                        key: property.id
                                                      }, React.createElement(Link.make, {
                                                            path: Router.Link.addDrawerItem(undefined, {
                                                                  NAME: "property",
                                                                  VAL: [
                                                                    property.id,
                                                                    undefined
                                                                  ]
                                                                }),
                                                            className: Curry._1(Css.style, {
                                                                  hd: Css.color(Styles.Color.darkBlue),
                                                                  tl: {
                                                                    hd: Css.paddingLeft(Css.px(5)),
                                                                    tl: {
                                                                      hd: Css.hover({
                                                                            hd: Css.color(Styles.Color.deepBlue),
                                                                            tl: /* [] */0
                                                                          }),
                                                                      tl: /* [] */0
                                                                    }
                                                                  }
                                                                }),
                                                            children: property.name
                                                          }), ",");
                                          })))));
                      } else {
                        var property$1 = propertiesWithSameName.hd;
                        tmp = React.createElement("div", undefined, React.createElement("span", undefined, "There is one property with same or similar name:"), React.createElement(Link.make, {
                                  path: Router.Link.addDrawerItem(undefined, {
                                        NAME: "property",
                                        VAL: [
                                          property$1.id,
                                          undefined
                                        ]
                                      }),
                                  className: Curry._1(Css.style, {
                                        hd: Css.color(Styles.Color.darkBlue),
                                        tl: {
                                          hd: Css.paddingLeft(Css.px(5)),
                                          tl: {
                                            hd: Css.hover({
                                                  hd: Css.color(Styles.Color.deepBlue),
                                                  tl: /* [] */0
                                                }),
                                            tl: /* [] */0
                                          }
                                        }
                                      }),
                                  onClick: (function (param) {
                                      return Router.Schema.pushDrawerItem(undefined, undefined, {
                                                  NAME: "property",
                                                  VAL: [
                                                    property$1.id,
                                                    undefined
                                                  ]
                                                });
                                    }),
                                  children: property$1.name
                                }));
                      }
                    } else {
                      tmp = React.createElement("div", undefined, "It looks like the naming conflict has been resolved. Feel free to remove the identifier. Note that removing the identifier will change how the property is represented in the code Avo generates.", React.createElement("button", {
                                className: Curry._1(Css.merge, {
                                      hd: Curry._1(Css.style, Styles.button),
                                      tl: {
                                        hd: Curry._1(Css.style, {
                                              hd: Css.color(Styles.Color.red),
                                              tl: {
                                                hd: Css.fontWeight(Styles.FontWeight.semi),
                                                tl: {
                                                  hd: Css.marginLeft(Css.px(2)),
                                                  tl: {
                                                    hd: Css.fontSize(Styles.FontSize.regular),
                                                    tl: {
                                                      hd: Css.hover({
                                                            hd: Css.color(Styles.Color.darkRed),
                                                            tl: /* [] */0
                                                          }),
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                }
                                              }
                                            }),
                                        tl: /* [] */0
                                      }
                                    }),
                                onClick: (function (param) {
                                    return Curry.app(sendActions, [
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                [[
                                                    {
                                                      NAME: "UpdatePropertyUniqueName",
                                                      VAL: [
                                                        property.id,
                                                        ""
                                                      ]
                                                    },
                                                    {
                                                      propertyId: property.id,
                                                      propertyQuery: property.id
                                                    }
                                                  ]]
                                              ]);
                                  })
                              }, "Remove"));
                    }
                    return React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.display("flex"),
                                      tl: {
                                        hd: Css.alignItems("flexStart"),
                                        tl: {
                                          hd: Css.margin4(Css.px(5), Css.px(0), Css.px(15), Css.px(0)),
                                          tl: {
                                            hd: Css.border(Css.px(1), "solid", Styles.Color.light02),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    })
                              }, React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.width(Css.pct(100)),
                                          tl: {
                                            hd: Css.paddingTop(Css.px(15)),
                                            tl: {
                                              hd: Css.paddingRight(Css.px(20)),
                                              tl: {
                                                hd: Css.paddingBottom(Css.px(15)),
                                                tl: {
                                                  hd: Css.paddingLeft(Css.px(60)),
                                                  tl: /* [] */0
                                                }
                                              }
                                            }
                                          }
                                        })
                                  }, React.createElement("div", {
                                        className: Curry._1(Css.style, {
                                              hd: Css.display("flex"),
                                              tl: {
                                                hd: Css.alignItems("center"),
                                                tl: {
                                                  hd: Css.fontWeight(Styles.FontWeight.semi),
                                                  tl: {
                                                    hd: Css.color(Styles.Color.light12),
                                                    tl: {
                                                      hd: Css.fontSize(Styles.FontSize.regular),
                                                      tl: {
                                                        hd: Css.marginTop(Css.px(1)),
                                                        tl: /* [] */0
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            })
                                      }, "Property Identifier"), React.createElement("div", {
                                        className: Curry._1(Css.style, {
                                              hd: Css.fontWeight(Styles.FontWeight.regular),
                                              tl: {
                                                hd: Css.fontSize(Styles.FontSize.regular),
                                                tl: {
                                                  hd: Css.color(Styles.Color.light10),
                                                  tl: {
                                                    hd: Css.marginTop(Css.px(5)),
                                                    tl: {
                                                      hd: Css.marginBottom(Css.px(15)),
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                }
                                              }
                                            })
                                      }, React.createElement("div", undefined, "The property identifier is used to differentiate between properties that have the same name. It does not affect how the property is sent but does change how the property is represented in the code Avo generates. We recommend changing the property identifier to something that describes how this property is different from other properties with the same name."), React.createElement("div", {
                                            className: Curry._1(Css.style, {
                                                  hd: Css.fontWeight(Styles.FontWeight.semi),
                                                  tl: {
                                                    hd: Css.marginTop(Css.px(4)),
                                                    tl: /* [] */0
                                                  }
                                                })
                                          }, tmp)), React.createElement("div", {
                                        className: Curry._1(Css.style, {
                                              hd: Css.position("relative"),
                                              tl: {
                                                hd: Css.margin4(Css.px(5), Css.px(0), Css.px(0), Css.px(0)),
                                                tl: {
                                                  hd: Css.height(Css.px(50)),
                                                  tl: {
                                                    hd: Css.width(Css.pct(100)),
                                                    tl: {
                                                      hd: Css.borderRadius(Styles.Border.radius),
                                                      tl: {
                                                        hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                                                        tl: /* [] */0
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            })
                                      }, React.createElement(NameInput.make, {
                                            name: uniqueName,
                                            existingNames: ModelUtils.getPropertyNamespace__mapped(mappedModel),
                                            expectedCase: expectedPropertyCase,
                                            forceCase: Curry._1(ValidationConfigV2.Rule.isEnforced, config.properties.case),
                                            onChange: (function (value, param, param$1, _onReset) {
                                                if (value !== uniqueName) {
                                                  Curry.app(sendActions, [
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        [[
                                                            {
                                                              NAME: "UpdatePropertyUniqueName",
                                                              VAL: [
                                                                property.id,
                                                                value
                                                              ]
                                                            },
                                                            {
                                                              propertyId: property.id,
                                                              propertyQuery: property.id
                                                            }
                                                          ]]
                                                      ]);
                                                  return ;
                                                }
                                                
                                              }),
                                            disabled: !RoleUtils.canEdit(role),
                                            inputStyles: {
                                              hd: Css.fontSize(Styles.FontSize.regular),
                                              tl: {
                                                hd: Css.fontWeight(Styles.FontWeight.semi),
                                                tl: /* [] */0
                                              }
                                            },
                                            itemType: "Property",
                                            actionType: "Rename",
                                            getPossibleItemLink: (function (propertyName) {
                                                return Belt_Option.map(ModelUtils.getPropertyByName(propertyName, model), (function (param) {
                                                              return Router.Link.addDrawerItem(undefined, {
                                                                          NAME: "property",
                                                                          VAL: [
                                                                            param.id,
                                                                            undefined
                                                                          ]
                                                                        });
                                                            }));
                                              })
                                          }))));
                  })), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.padding4(Css.px(0), Css.px(20), Css.px(0), Css.px(60)),
                        tl: {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.justifyContent("center"),
                            tl: {
                              hd: Css.flexDirection("column"),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }, React.createElement("label", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.flexDirection("row"),
                              tl: {
                                hd: Css.alignItems("center"),
                                tl: {
                                  hd: Css.fontWeight(Styles.FontWeight.semi),
                                  tl: {
                                    hd: Css.color(Styles.Color.light12),
                                    tl: {
                                      hd: Css.fontSize(Styles.FontSize.regular),
                                      tl: {
                                        hd: Css.padding4(Css.px(11), Css.px(0), Css.px(5), Css.px(10)),
                                        tl: {
                                          hd: Css.marginLeft(Css.px(-39)),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }),
                      htmlFor: property.name + "-description"
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.marginTop(Css.px(3)),
                                tl: {
                                  hd: Css.marginRight(Css.px(5)),
                                  tl: /* [] */0
                                }
                              })
                        }, React.createElement(IconDescription.make, {})), React.createElement(Spacer.make, {
                          width: 8
                        }), "Description", React.createElement(Tooltip.WithAnalytics.make, {
                          fromItem: fromItem,
                          title: "Description",
                          children: React.createElement(Tooltip.Content.make, {
                                children: React.createElement(Markdown.make, {
                                      source: Tooltips.propertyDescription
                                    })
                              })
                        })), tmp$3, React.createElement(OpsWithState.make, {
                      value: property.description,
                      onFlush: (function (description) {
                          return Curry.app(sendActions, [
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      [[
                                          {
                                            NAME: "UpdatePropertyDescription",
                                            VAL: [
                                              property.id,
                                              description
                                            ]
                                          },
                                          context
                                        ]]
                                    ]);
                        }),
                      onChangeCompleted: (function (value, valueBefore) {
                          AnalyticsRe.descriptionUpdated(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, valueBefore, "Property", value.split(" ").length, value, value.length, property.name, schemaBundle.branchId, schemaBundle.schemaId);
                          
                        }),
                      children: (function (value, onChange, onBlur, onFocus) {
                          return React.createElement(ReactTextareaAutosize, {
                                      className: description(property.builtIn),
                                      maxRows: 15,
                                      minRows: 1,
                                      onChange: (function (domEvent) {
                                          return Curry._1(onChange, domEvent.target.value);
                                        }),
                                      placeholder: "Describe the property...",
                                      onFocus: (function (domEvent) {
                                          return Curry._1(onFocus, domEvent.target.value);
                                        }),
                                      onBlur: (function (domEvent) {
                                          return Curry._1(onBlur, domEvent.target.value);
                                        }),
                                      value: value,
                                      id: property.name + "-description",
                                      disabled: property.builtIn !== "NotBuiltIn" || !RoleUtils.canEdit(role)
                                    });
                        })
                    })), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.width(Css.pct(100)),
                        tl: {
                          hd: Css.height(Css.px(1)),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.light02),
                            tl: {
                              hd: Css.margin2(Css.px(15), Css.px(0)),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.flexDirection("column"),
                          tl: {
                            hd: Css.padding4(Css.px(0), Css.px(20), Css.px(0), Css.px(60)),
                            tl: /* [] */0
                          }
                        }
                      })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: {
                                hd: Css.marginLeft(Css.px(-29)),
                                tl: {
                                  hd: Css.marginBottom(Css.px(8)),
                                  tl: /* [] */0
                                }
                              }
                            }
                          })
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.marginTop(Css.px(3)),
                                tl: {
                                  hd: Css.marginRight(Css.px(5)),
                                  tl: /* [] */0
                                }
                              })
                        }, React.createElement(Icon.make, {
                              type_: "braces",
                              color: Styles.Color.light07
                            })), React.createElement(Spacer.make, {
                          width: 8
                        }), React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Semi",
                          children: "Property value type"
                        }), React.createElement(Tooltip.WithAnalytics.make, {
                          fromItem: fromItem,
                          title: "Property value type",
                          children: React.createElement(Tooltip.Content.make, {
                                children: React.createElement(Markdown.make, {
                                      source: Tooltips.propertyValueType
                                    })
                              })
                        })), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: /* [] */0
                            }
                          })
                    }, React.createElement(Select.make, {
                          disabled: !RoleUtils.canEdit(role),
                          onSelect: (function (nextType) {
                              var lastType = property.type_;
                              return Curry.app(sendActions, [
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          (function (branch) {
                                              if (lastType === nextType) {
                                                return ;
                                              }
                                              var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                              return AnalyticsRe.typeChanged(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, "N/A", Belt_Option.getExn(AnalyticsRe.to_FromJs(nextType)), Belt_Option.getExn(AnalyticsRe.fromFromJs(lastType)), property.name, NamedBranch.getId(branch), schemaBundle$1.schemaId);
                                            }),
                                          undefined,
                                          [[
                                              {
                                                NAME: "UpdatePropertyType",
                                                VAL: [
                                                  property.id,
                                                  nextType
                                                ]
                                              },
                                              context
                                            ]]
                                        ]);
                            }),
                          options: Belt_Array.map(PropertyUtils.getPropertyTypes(hasAnyValuesFeatureFlag, hasLongValuesFeatureFlag, property), (function (type_) {
                                  return [
                                          {
                                            NAME: "Label",
                                            VAL: type_
                                          },
                                          type_
                                        ];
                                })),
                          value: property.type_
                        }), React.createElement(Spacer.make, {
                          width: 16
                        }), React.createElement(Mantine.Checkbox.make, {
                          checked: property.list,
                          disabled: !RoleUtils.canEdit(role),
                          onChange: (function ($$event) {
                              var isList = $$event.target.checked;
                              return Curry.app(sendActions, [
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          (function (branch) {
                                              var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                              return AnalyticsRe.listToggled(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, isList, "N/A", property.name, NamedBranch.getId(branch), schemaBundle$1.schemaId);
                                            }),
                                          undefined,
                                          [[
                                              {
                                                NAME: "UpdatePropertyList",
                                                VAL: [
                                                  property.id,
                                                  isList
                                                ]
                                              },
                                              context
                                            ]]
                                        ]);
                            }),
                          size: "xs",
                          id: "list"
                        }), React.createElement(Spacer.make, {
                          width: 8
                        }), React.createElement($$Text.make, {
                          element: "Label",
                          size: "Medium",
                          weight: "Semi",
                          color: Styles.Color.light11,
                          htmlFor: "list",
                          children: "List"
                        })), tmp$6, property.sendAs === /* UserProperty */2 && FeatureFlag.featureEnabled(schema, "UserPropertyOperations") ? React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.marginRight(Css.px(15)),
                              tl: {
                                hd: Css.fontWeight(Styles.FontWeight.semi),
                                tl: {
                                  hd: Css.fontSize(Styles.FontSize.regular),
                                  tl: {
                                    hd: Css.color(Styles.Color.light11),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            })
                      }, React.createElement(Select.make, {
                            onSelect: (function (nextOperation) {
                                var operation = TrackingPlanModel.operationFromJs(nextOperation);
                                if (operation !== undefined) {
                                  return Curry.app(sendActions, [
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              [[
                                                  {
                                                    NAME: "UpdatePropertyOperation",
                                                    VAL: [
                                                      property.id,
                                                      operation
                                                    ]
                                                  },
                                                  context
                                                ]]
                                            ]);
                                }
                                
                              }),
                            options: Belt_Array.map(AvoConfig.supportedOperations, (function (operation) {
                                    return [
                                            {
                                              NAME: "Label",
                                              VAL: AvoConfig.operationLabel(operation)
                                            },
                                            TrackingPlanModel.operationToJs(operation)
                                          ];
                                  })),
                            value: TrackingPlanModel.operationToJs(property.operation)
                          })) : null), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.marginTop(Css.px(12)),
                        tl: /* [] */0
                      })
                }), tmp$7, FeatureFlag.featureEnabled(schema, "PropertySources") && !hasMigratedToAbsence ? React.createElement(React.Fragment, undefined, React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.width(Css.pct(100)),
                              tl: {
                                hd: Css.height(Css.px(1)),
                                tl: {
                                  hd: Css.backgroundColor(Styles.Color.light02),
                                  tl: {
                                    hd: Css.margin2(Css.px(15), Css.px(0)),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            })
                      }), React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.padding4(Css.px(0), Css.px(20), Css.px(0), Css.px(60)),
                              tl: /* [] */0
                            })
                      }, React.createElement(PropertyModalSources.make, {
                            model: model,
                            property: property,
                            sendActions: sendActions
                          }))) : null, hasMigratedToAbsence && inObjectProperties !== /* [] */0 ? React.createElement(React.Fragment, undefined, React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.width(Css.pct(100)),
                              tl: {
                                hd: Css.height(Css.px(1)),
                                tl: {
                                  hd: Css.backgroundColor(Styles.Color.light02),
                                  tl: {
                                    hd: Css.margin2(Css.px(15), Css.px(0)),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            })
                      }), React.createElement(PropertyDetails$SentInObjectProperties, {
                        model: model,
                        property: property,
                        inProperties: inObjectProperties
                      })) : null, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.width(Css.pct(100)),
                        tl: {
                          hd: Css.height(Css.px(1)),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.light02),
                            tl: {
                              hd: Css.margin2(Css.px(15), Css.px(0)),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.padding4(Css.px(0), Css.px(20), Css.px(0), Css.px(60)),
                        tl: /* [] */0
                      })
                }, shouldShowEventSpecificPropertyValuesFeature ? React.createElement(PropertyDetailsValueRules.make, {
                        property: property,
                        viewerCanEdit: RoleUtils.canEdit(role),
                        propertyEvents: propertyEvents$1
                      }) : React.createElement(PropertyDetailsConstraints.make, {
                        property: property,
                        eventsSendingProperty: propertyEvents$1,
                        sendActions: sendActions,
                        role: role,
                        addToast: addToast,
                        model: model,
                        schemaBundle: schemaBundle,
                        currentFilters: currentFilters,
                        filters: filters
                      })), React.createElement("div", undefined, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.width(Css.pct(100)),
                            tl: {
                              hd: Css.height(Css.px(1)),
                              tl: {
                                hd: Css.backgroundColor(Styles.Color.light02),
                                tl: {
                                  hd: Css.margin2(Css.px(15), Css.px(0)),
                                  tl: /* [] */0
                                }
                              }
                            }
                          })
                    }), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.paddingLeft(Css.px(60)),
                            tl: {
                              hd: Css.paddingRight(Css.px(20)),
                              tl: /* [] */0
                            }
                          })
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.fontWeight(Styles.FontWeight.semi),
                                tl: {
                                  hd: Css.fontSize(Styles.FontSize.regular),
                                  tl: {
                                    hd: Css.paddingBottom(Css.px(5)),
                                    tl: {
                                      hd: Css.display("flex"),
                                      tl: {
                                        hd: Css.alignItems("center"),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              })
                        }, "Name Mappings", React.createElement(Tooltip.WithAnalytics.make, {
                              fromItem: fromItem,
                              title: "Name Mappings",
                              children: React.createElement(Tooltip.Content.make, {
                                    children: React.createElement(Markdown.make, {
                                          source: Tooltips.propertySendAs
                                        })
                                  })
                            })), React.createElement("div", undefined, Belt_List.toArray(Belt_List.map(ModelUtils.getPropertyNameMappingsOnAllEvents(model, property.id, false, undefined), (function (rule) {
                                    return React.createElement(PropertyRule.make, {
                                                rule: rule,
                                                sendActions: sendActions,
                                                property: property,
                                                context: context,
                                                viewerCanEdit: RoleUtils.canEdit(role),
                                                key: rule.id
                                              });
                                  })))), tmp$8)), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.width(Css.pct(100)),
                        tl: {
                          hd: Css.height(Css.px(1)),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.light02),
                            tl: {
                              hd: Css.margin2(Css.px(15), Css.px(0)),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.paddingLeft(Css.px(16)),
                        tl: {
                          hd: Css.paddingRight(Css.px(10)),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement(ObjectActivityLog.make, tmp$9), React.createElement(CommentInput.make, tmp$10))));
  } else {
    tmp = "Property not found";
  }
  return React.createElement("div", {
              key: propertyId,
              className: Curry._1(Css.style, {
                    hd: Css.cursor("default"),
                    tl: /* [] */0
                  }),
              onClick: (function ($$event) {
                  $$event.stopPropagation();
                  
                })
            }, tmp);
}

var make = PropertyDetails;

export {
  make ,
  
}
/* rootStyles Not a pure module */
