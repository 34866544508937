// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Models from "./Models.mjs";
import * as Actions from "./actions.mjs";
import * as Printer from "../../model/src/Printer.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as FetchModel from "./FetchModel.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as BranchStatusModel from "../../shared/models/BranchStatusModel.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";
import * as ActionDiffWorkerInstance from "./workers/ActionDiffWorkerInstance.mjs";

function calculateRebasedBranchModel(branchModel, masterModelBeforeBranch, currentMasterOrMasterAtBranchMergeOrClose, masterModelBeforeBranchJson, currentMasterOrMasterAtBranchMergeOrCloseJson) {
  return ActionDiffWorkerInstance.diff(masterModelBeforeBranch, masterModelBeforeBranchJson, currentMasterOrMasterAtBranchMergeOrClose, currentMasterOrMasterAtBranchMergeOrCloseJson).then(function (mainBranchChanges) {
              return [
                      mainBranchChanges,
                      Belt_List.reduceU(mainBranchChanges, branchModel, Actions.reduce)
                    ];
            });
}

function getFromAndToModels(branchStatus, masterModel, branchModel, maybeBranchModelJson, rebasedBranchModel, masterModelBeforeBranch, maybeMasterModelBeforeBranchJson) {
  if (typeof branchStatus !== "number" && branchStatus.TAG === /* Merged */1) {
    return {
            from: {
              model: masterModelBeforeBranch,
              json: maybeMasterModelBeforeBranchJson
            },
            to: {
              model: branchModel,
              json: maybeBranchModelJson
            }
          };
  }
  return {
          from: {
            model: masterModel,
            json: undefined
          },
          to: {
            model: rebasedBranchModel,
            json: undefined
          }
        };
}

function calculateDiff(masterModelBeforeBranch, currentMasterOrMasterAtBranchMergeOrClose, masterModel, branchModel, branchStatus, calculationId) {
  var masterModelBeforeBranchJson = Printer.printModel(masterModelBeforeBranch);
  var currentMasterOrMasterAtBranchMergeOrCloseJson = Printer.printModel(currentMasterOrMasterAtBranchMergeOrClose);
  return calculateRebasedBranchModel(branchModel, masterModelBeforeBranch, currentMasterOrMasterAtBranchMergeOrClose, masterModelBeforeBranchJson, currentMasterOrMasterAtBranchMergeOrCloseJson).then(function (param) {
              var rebasedBranchModel = param[1];
              var mainBranchChanges = param[0];
              var branchModelJson = Printer.printModel(branchModel);
              var match = getFromAndToModels(branchStatus, masterModel, branchModel, Caml_option.some(branchModelJson), rebasedBranchModel, masterModelBeforeBranch, Caml_option.some(masterModelBeforeBranchJson));
              var to = match.to;
              var from = match.from;
              var fromModelJson = Belt_Option.getWithDefault(from.json, Printer.printModel(from.model));
              var toModelJson = Belt_Option.getWithDefault(to.json, Printer.printModel(to.model));
              return Promise.all([
                            ActionDiffWorkerInstance.diff(from.model, fromModelJson, to.model, toModelJson),
                            ActionDiffWorkerInstance.diff(masterModelBeforeBranch, masterModelBeforeBranchJson, branchModel, branchModelJson)
                          ]).then(function (param) {
                          return {
                                  calculationId: calculationId,
                                  fromModel: from.model,
                                  toModel: to.model,
                                  rebasedBranchModel: rebasedBranchModel,
                                  mainBranchChanges: mainBranchChanges,
                                  rawBranchChanges: param[1],
                                  rebasedBranchChanges: param[0]
                                };
                        });
            });
}

function useToAndFromModels(mode, schemaId, branchId, branchModel, latestBranchAction, masterModel, latestMasterAction, branchStatus) {
  var match = FirebaseFetcherHooks.useBranchLifecycleActions(schemaId, branchId, mode, undefined);
  var match$1 = branchModel.branchPointer;
  var match$2 = branchModel.branchPointer;
  var fetchStateMainBeforeBranch = FetchModel.use(match$1 !== undefined ? undefined : Belt_Option.flatMap(match[0], (function (branchOpenAction) {
                return Models.toDateOption(Models.Action.getCreatedAt(branchOpenAction));
              })), match$2 !== undefined ? Caml_option.some(new Date(match$2[1])) : undefined, mode, "master");
  var match$3;
  switch (fetchStateMainBeforeBranch.TAG | 0) {
    case /* Loading */0 :
    case /* Error */1 :
        match$3 = [
          undefined,
          undefined
        ];
        break;
    case /* Result */2 :
        match$3 = [
          fetchStateMainBeforeBranch._0,
          fetchStateMainBeforeBranch._2
        ];
        break;
    
  }
  var maybeMainModelBeforeBranchLatestAction = match$3[1];
  var maybeMainModelBeforeBranch = match$3[0];
  var branchMergeOrCloseTimestamp = BranchStatusModel.getBranchMergeOrCloseTimestamp(match[1], match[2]);
  var fetchStateMainAtMergeOrClose = FetchModel.use(undefined, branchMergeOrCloseTimestamp, Belt_Option.mapWithDefault(branchMergeOrCloseTimestamp, "Skip", (function (param) {
              return "Fetch";
            })), "master");
  var match$4;
  switch (fetchStateMainAtMergeOrClose.TAG | 0) {
    case /* Loading */0 :
    case /* Error */1 :
        match$4 = [
          undefined,
          undefined
        ];
        break;
    case /* Result */2 :
        match$4 = [
          fetchStateMainAtMergeOrClose._0,
          fetchStateMainAtMergeOrClose._2
        ];
        break;
    
  }
  var match$5 = branchMergeOrCloseTimestamp !== undefined ? [
      match$4[0],
      match$4[1]
    ] : [
      masterModel,
      latestMasterAction
    ];
  var maybeCurrentMasterOrMasterAtBranchMergeOrCloseLatestAction = match$5[1];
  var maybeCurrentMasterOrMasterAtBranchMergeOrClose = match$5[0];
  var match$6 = React.useState(function () {
        
      });
  var setRebasedBranchModel = match$6[1];
  var maybeRebasedBranchModel = match$6[0];
  var getMaybeActionId = function (action) {
    return Belt_Option.getWithDefault(Belt_Option.map(action, Models.Action.getId), "");
  };
  var maybeCurrentMasterOrMasterAtBranchMergeOrCloseJson = React.useMemo((function () {
          return Belt_Option.map(maybeCurrentMasterOrMasterAtBranchMergeOrClose, Printer.printModel);
        }), [getMaybeActionId(maybeCurrentMasterOrMasterAtBranchMergeOrCloseLatestAction)]);
  var maybeMainModelBeforeBranchJson = React.useMemo((function () {
          return Belt_Option.map(maybeMainModelBeforeBranch, Printer.printModel);
        }), [getMaybeActionId(maybeMainModelBeforeBranchLatestAction)]);
  var getRequestId = function (param) {
    return getMaybeActionId(maybeMainModelBeforeBranchLatestAction) + "-" + getMaybeActionId(maybeCurrentMasterOrMasterAtBranchMergeOrCloseLatestAction) + "-" + getMaybeActionId(latestBranchAction);
  };
  var currentRequestId = React.useRef("");
  React.useEffect((function () {
          if (maybeMainModelBeforeBranch !== undefined && maybeMainModelBeforeBranchJson !== undefined && maybeCurrentMasterOrMasterAtBranchMergeOrClose !== undefined && maybeCurrentMasterOrMasterAtBranchMergeOrCloseJson !== undefined) {
            var thisRequestId = getRequestId(undefined);
            currentRequestId.current = thisRequestId;
            calculateRebasedBranchModel(branchModel, maybeMainModelBeforeBranch, maybeCurrentMasterOrMasterAtBranchMergeOrClose, Caml_option.valFromOption(maybeMainModelBeforeBranchJson), Caml_option.valFromOption(maybeCurrentMasterOrMasterAtBranchMergeOrCloseJson)).then(function (param) {
                  if (thisRequestId !== currentRequestId.current) {
                    return ;
                  }
                  var rebasedBranchModel = param[1];
                  return Curry._1(setRebasedBranchModel, (function (param) {
                                return rebasedBranchModel;
                              }));
                });
          }
          
        }), [getRequestId(undefined)]);
  if (maybeRebasedBranchModel !== undefined && maybeMainModelBeforeBranch !== undefined) {
    return getFromAndToModels(branchStatus, masterModel, branchModel, undefined, maybeRebasedBranchModel, maybeMainModelBeforeBranch, undefined);
  }
  
}

export {
  calculateRebasedBranchModel ,
  getFromAndToModels ,
  calculateDiff ,
  useToAndFromModels ,
  
}
/* react Not a pure module */
