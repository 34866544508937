// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "./Hooks.mjs";
import * as Toast from "./Toast.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Styles from "./styles.mjs";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as RoleUtils from "./RoleUtils.mjs";
import * as Timestamp from "./Timestamp.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as TextButton from "./TextButton.mjs";
import * as IconLightning from "./IconLightning.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as AppVersionsStore from "./AppVersionsStore.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as DiffViewActionBox from "./DiffViewActionBox.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as DiscrepancyContext from "./DiscrepancyContext.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as BranchDiscrepancies from "./BranchDiscrepancies.mjs";
import * as OrganizationContext from "./OrganizationContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";
import * as BranchActionBox__Utils from "./BranchActionBox__Utils.mjs";
import * as BranchActionBox__Components from "./BranchActionBox__Components.mjs";
import * as ImplementationStatusContext from "./ImplementationStatusContext.mjs";
import * as FetchImplementationStatusHooks from "./implementationStatus/FetchImplementationStatusHooks.mjs";

var upgradeModal = {
  NAME: "BillingPrompt",
  VAL: "BranchApprovalWorkflows"
};

function BranchActionBox__NoApprovalWorkflow$OpenState(Props) {
  var actionDiff = Props.actionDiff;
  var actions = Props.actions;
  var branchId = Props.branchId;
  var branchModel = Props.branchModel;
  var branchModelWithPulledMaster = Props.branchModelWithPulledMaster;
  var branchName = Props.branchName;
  var branchDiffStatus = Props.branchDiffStatus;
  var branchStatus = Props.branchStatus;
  var changes = Props.changes;
  var masterModel = Props.masterModel;
  var masterModelBeforeBranch = Props.masterModelBeforeBranch;
  var mergeConflicts = Props.mergeConflicts;
  var role = Props.role;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var workspace = WorkspaceContext.use(undefined);
  var openModal = GlobalSendContext.useOpenModal(undefined);
  var addToast = Toast.useAddToast(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var globalSend = GlobalSendContext.use(undefined);
  var isGlobalWorkspace = OrganizationContext.useIsGlobalWorkspace(undefined);
  var match = AppVersionsStore.use(undefined, 2, undefined);
  var appVersions = match[0];
  var match$1 = BranchDiscrepancies.use(undefined);
  var branchAuditWarningCount = match$1.branchAuditWarningCount;
  var breakingChanges = Hooks.useBreakingChangesReport(changes);
  var match$2 = React.useContext(DiscrepancyContext.context);
  var eventComponentsWithNames = match$2.eventComponentsWithNames;
  var discrepancyConfig = match$2.config;
  var invocationsContext = React.useContext(ImplementationStatusContext.context).codegen;
  var sourceInitStatus = invocationsContext[2];
  var invocationsPromise = FetchImplementationStatusHooks.useInvocationsWithPromise(invocationsContext);
  var maybeSharingLink = FirebaseFetcherHooks.useSharingLink(schemaBundle.schemaId, branchId);
  var match$3 = FirebaseFetcherHooks.useValidationIntents(schemaBundle.schemaId, branchId);
  var validationIntent = match$3[0];
  var shouldDeleteSharingLink = React.useRef(false);
  var match$4 = React.useState(function () {
        return false;
      });
  var setLoading = match$4[1];
  var hasUpToDateDiff = branchDiffStatus === "loaded";
  var canMerge = mergeConflicts === /* [] */0 && hasUpToDateDiff;
  var numApprovals;
  numApprovals = typeof branchStatus === "number" || branchStatus.TAG !== /* Approved */6 ? 0 : Belt_List.length(branchStatus._0);
  return React.createElement(BranchActionBox__Components.Container.make, {
              children: null
            }, React.createElement(BranchActionBox__Components.MainContent.make, {
                  children: null
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Semi",
                      color: Styles.Color.light12,
                      children: "Merge these changes into the main branch"
                    }), React.createElement(Button.make, {
                      disabled: !RoleUtils.canEdit(role),
                      label: "Delete branch",
                      onClick: (function (param) {
                          return BranchActionBox__Utils.handleDelete(addToast, branchId, branchName, globalSend, schemaBundle, sendActions);
                        }),
                      size: "tiny",
                      style: "ghost"
                    }), mergeConflicts === /* [] */0 ? null : React.createElement("div", undefined, React.createElement($$Text.make, {
                            size: "Small",
                            weight: "Semi",
                            textAlign: "right",
                            color: Styles.Color.light08,
                            children: "Resolve the conflicts before merging"
                          })), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.flexShrink(0.0),
                            tl: {
                              hd: Css.marginRight(Css.px(16)),
                              tl: /* [] */0
                            }
                          })
                    }, React.createElement(IconLightning.make, {})), React.createElement($$Text.make, {
                      size: "Small",
                      color: Styles.Color.light10,
                      children: null
                    }, "Want a better branch workflow? Unlock review requests and approvals by upgrading to Team or Enterprise. ", React.createElement(TextButton.make, {
                          onClick: (function (param) {
                              return Curry._1(openModal, upgradeModal);
                            }),
                          style: "Blue",
                          children: "Learn more"
                        }))), React.createElement(BranchActionBox__Components.MergeButtonSection.make, {
                  children: React.createElement(Button.make, {
                        disabled: !RoleUtils.canEdit(role) || !canMerge,
                        label: "Merge branch",
                        loading: match$4[0],
                        onClick: (function (param) {
                            return BranchActionBox__Utils.handleMerge(actionDiff, actions, addToast, false, appVersions, branchAuditWarningCount, branchId, branchModel, branchModelWithPulledMaster, branchName, breakingChanges, discrepancyConfig, eventComponentsWithNames, globalSend, invocationsPromise, masterModel, masterModelBeforeBranch, maybeSharingLink, numApprovals, role, schemaBundle, sendActions, setLoading, sourceInitStatus, workspace, shouldDeleteSharingLink, validationIntent, isGlobalWorkspace);
                          }),
                        size: "large"
                      })
                }));
}

var OpenState = {
  make: BranchActionBox__NoApprovalWorkflow$OpenState
};

function BranchActionBox__NoApprovalWorkflow$ClosedState(Props) {
  var action = Props.action;
  var title = Props.title;
  var actionLabel = Props.actionLabel;
  var userId = action.createdBy;
  var user = FirebaseFetcherHooks.useUser(userId);
  return React.createElement(BranchActionBox__Components.Container.make, {
              children: React.createElement(BranchActionBox__Components.MainContent.make, {
                    children: null
                  }, React.createElement($$Text.make, {
                        weight: "Semi",
                        children: title
                      }), React.createElement($$Text.make, {
                        element: "Span",
                        size: "Small",
                        weight: "Regular",
                        color: Styles.Color.light10,
                        children: null
                      }, actionLabel, " ", React.createElement($$Text.make, {
                            element: "Span",
                            weight: "Semi",
                            children: React.createElement(Timestamp.make, {
                                  date: action.createdAt
                                })
                          }), typeof user === "object" ? React.createElement(React.Fragment, undefined, " by ", React.createElement($$Text.make, {
                                  element: "Span",
                                  weight: "Semi",
                                  children: AvoConfig.getUserDisplayName(user.VAL)
                                })) : null))
            });
}

var ClosedState = {
  make: BranchActionBox__NoApprovalWorkflow$ClosedState
};

function BranchActionBox__NoApprovalWorkflow$MergedState(Props) {
  var action = Props.action;
  var branchId = Props.branchId;
  var branchModel = Props.branchModel;
  var branchName = Props.branchName;
  var branchStatus = Props.branchStatus;
  var changes = Props.changes;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var match = AppVersionsStore.use(undefined, 2, undefined);
  var breakingChanges = Hooks.useBreakingChangesReport(changes);
  var match$1 = FirebaseFetcherHooks.useValidationIntents(schemaBundle.schemaId, branchId);
  var sourceIdsWithVersionsSet = Belt_SetString.fromArray(Belt_MapString.keysToArray(match[0]));
  var sourceIds = breakingChanges.breakingSources;
  var hasSourcesToConfig = Belt_Array.some(sourceIds, (function (sourceId) {
          return Belt_SetString.has(sourceIdsWithVersionsSet, sourceId);
        }));
  var showVersionValidation = breakingChanges.breakingSources.length !== 0 && hasSourcesToConfig;
  return React.createElement(React.Fragment, undefined, React.createElement(BranchActionBox__NoApprovalWorkflow$ClosedState, {
                  action: action,
                  title: "Branch has been merged",
                  actionLabel: branchName + " was merged into main"
                }), showVersionValidation ? React.createElement(DiffViewActionBox.InspectorValidation.make, {
                    branchStatus: branchStatus,
                    branchModel: branchModel,
                    breakingChanges: breakingChanges,
                    validationIntent: match$1[0]
                  }) : null);
}

var MergedState = {
  make: BranchActionBox__NoApprovalWorkflow$MergedState
};

function BranchActionBox__NoApprovalWorkflow(Props) {
  var actionDiff = Props.actionDiff;
  var actions = Props.actions;
  var branchDiffStatus = Props.branchDiffStatus;
  var branchId = Props.branchId;
  var branchModel = Props.branchModel;
  var branchModelWithPulledMaster = Props.branchModelWithPulledMaster;
  var branchName = Props.branchName;
  var branchStatus = Props.branchStatus;
  var changes = Props.changes;
  var masterModel = Props.masterModel;
  var masterModelBeforeBranch = Props.masterModelBeforeBranch;
  var mergeConflicts = Props.mergeConflicts;
  var role = Props.role;
  if (typeof branchStatus === "number") {
    if (branchStatus === /* Master */0) {
      return null;
    } else {
      return React.createElement($$Text.make, {
                  children: "Loading"
                });
    }
  }
  switch (branchStatus.TAG | 0) {
    case /* Merged */1 :
        return React.createElement(BranchActionBox__NoApprovalWorkflow$MergedState, {
                    action: branchStatus._0,
                    branchId: branchId,
                    branchModel: branchModel,
                    branchName: branchName,
                    branchStatus: branchStatus,
                    changes: changes
                  });
    case /* Closed */2 :
        return React.createElement(BranchActionBox__NoApprovalWorkflow$ClosedState, {
                    action: branchStatus._0,
                    title: "Branch has been deleted",
                    actionLabel: branchName + " was was deleted"
                  });
    default:
      return React.createElement(BranchActionBox__NoApprovalWorkflow$OpenState, {
                  actionDiff: actionDiff,
                  actions: actions,
                  branchId: branchId,
                  branchModel: branchModel,
                  branchModelWithPulledMaster: branchModelWithPulledMaster,
                  branchName: branchName,
                  branchDiffStatus: branchDiffStatus,
                  branchStatus: branchStatus,
                  changes: changes,
                  masterModel: masterModel,
                  masterModelBeforeBranch: masterModelBeforeBranch,
                  mergeConflicts: mergeConflicts,
                  role: role
                });
  }
}

var make = BranchActionBox__NoApprovalWorkflow;

export {
  upgradeModal ,
  OpenState ,
  ClosedState ,
  MergedState ,
  make ,
  
}
/* Css Not a pure module */
