// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "rescript/lib/es6/list.js";
import * as $$Text from "./Text.mjs";
import * as Toast from "./Toast.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Styles from "./styles.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as RoleUtils from "./RoleUtils.mjs";
import * as DiscrepancyUtils from "./DiscrepancyUtils.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as DiscrepancyContext from "./DiscrepancyContext.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as ValidationConfigV2 from "./guardrails/ValidationConfigV2.mjs";
import * as BranchDiscrepancies from "./BranchDiscrepancies.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as BranchActionBox__Utils from "./BranchActionBox__Utils.mjs";
import * as BranchActionBox__Components from "./BranchActionBox__Components.mjs";

function BranchActionBox__ApprovalWorkflow$DraftState(Props) {
  var branchId = Props.branchId;
  var branchName = Props.branchName;
  var branchModelWithPulledMaster = Props.branchModelWithPulledMaster;
  var branchStatus = Props.branchStatus;
  var masterModel = Props.masterModel;
  var role = Props.role;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var workspace = WorkspaceContext.use(undefined);
  var match = BranchDiscrepancies.use(undefined);
  var branchAuditChecks = match.branchAuditChecks;
  var addToast = Toast.useAddToast(undefined);
  var globalSend = GlobalSendContext.use(undefined);
  var match$1 = React.useContext(DiscrepancyContext.context);
  var discrepancyConfig = match$1.config;
  var match$2 = ValidationConfigV2.EventsV2.getCase(discrepancyConfig.events);
  var hasSimpleNameCasing = typeof match$2 === "object" ? true : false;
  var hasValueChecking = ValidationConfigV2.EventsV2.hasValueChecking(discrepancyConfig.events);
  var blockBranchwithEnforcement = React.useMemo((function () {
          if (DiscrepancyUtils.hasDiscrepencyErrors(hasSimpleNameCasing, hasValueChecking, branchAuditChecks)) {
            return discrepancyConfig.pointOfBlock;
          }
          
        }), [
        branchModelWithPulledMaster,
        masterModel,
        discrepancyConfig
      ]);
  var numApprovals;
  numApprovals = typeof branchStatus === "number" || branchStatus.TAG !== /* Approved */6 ? 0 : List.length(branchStatus._0);
  var match$3 = React.useState(function () {
        return false;
      });
  var setLoading = match$3[1];
  return React.createElement(BranchActionBox__Components.Container.make, {
              children: null
            }, React.createElement(BranchActionBox__Components.MainContent.make, {
                  children: null
                }, React.createElement($$Text.make, {
                      size: "Large",
                      weight: "Semi",
                      children: "This branch is still a work in progress"
                    }), React.createElement($$Text.make, {
                      size: "Medium",
                      color: Styles.Color.light11,
                      children: "Done making changes? Mark the branch as ready for review and get feedback before merging."
                    }), React.createElement(Button.make, {
                      disabled: !RoleUtils.canEdit(role),
                      label: "Delete branch",
                      onClick: (function (param) {
                          return BranchActionBox__Utils.handleDelete(addToast, branchId, branchName, globalSend, schemaBundle, sendActions);
                        }),
                      size: "tiny",
                      style: "ghost"
                    }), React.createElement(Button.make, {
                      disabled: Caml_obj.caml_equal(blockBranchwithEnforcement, "beforeRequestForReview") || !RoleUtils.canEdit(role),
                      label: "Ready for review",
                      loading: match$3[0],
                      onClick: (function (param) {
                          return BranchActionBox__Utils.handleSetBranchStatus(branchId, branchName, "ReadyForReview", numApprovals, schemaBundle, sendActions, setLoading, branchStatus, workspace);
                        }),
                      size: "large"
                    })), React.createElement(BranchActionBox__Components.MergeButtonSection.make, {
                  children: React.createElement(Button.make, {
                        disabled: true,
                        label: "Merge branch",
                        size: "large"
                      })
                }));
}

var DraftState = {
  make: BranchActionBox__ApprovalWorkflow$DraftState
};

function BranchActionBox__ApprovalWorkflow(Props) {
  var branchId = Props.branchId;
  var branchName = Props.branchName;
  var branchModelWithPulledMaster = Props.branchModelWithPulledMaster;
  var branchStatus = Props.branchStatus;
  var masterModel = Props.masterModel;
  var role = Props.role;
  if (typeof branchStatus === "number") {
    if (branchStatus === /* Master */0) {
      return null;
    } else {
      return React.createElement($$Text.make, {
                  children: "Loading"
                });
    }
  }
  switch (branchStatus.TAG | 0) {
    case /* Merged */1 :
        return React.createElement($$Text.make, {
                    children: "Merged"
                  });
    case /* Closed */2 :
        return React.createElement($$Text.make, {
                    children: "Closed"
                  });
    case /* Open */0 :
    case /* Draft */3 :
        break;
    case /* ReadyForReview */4 :
        return React.createElement($$Text.make, {
                    children: "Ready for review"
                  });
    case /* ChangesRequested */5 :
        return React.createElement($$Text.make, {
                    children: "Changes requested"
                  });
    case /* Approved */6 :
        return React.createElement($$Text.make, {
                    children: "Approved"
                  });
    
  }
  return React.createElement(BranchActionBox__ApprovalWorkflow$DraftState, {
              branchId: branchId,
              branchName: branchName,
              branchModelWithPulledMaster: branchModelWithPulledMaster,
              branchStatus: branchStatus,
              masterModel: masterModel,
              role: role
            });
}

var make = BranchActionBox__ApprovalWorkflow;

export {
  DraftState ,
  make ,
  
}
/* Text Not a pure module */
