// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Case from "../../bs-case/src/case.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Toast from "./Toast.mjs";
import * as React from "react";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import Shortid from "shortid";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as NameUtils from "../../shared/utils/NameUtils.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "./ModelStore.mjs";
import * as ModelUtils from "./ModelUtils.mjs";
import * as Fuzzaldrin from "fuzzaldrin";
import * as NewEventModal from "./NewEventModal.mjs";
import * as AutofillInputNew from "./AutofillInputNew.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as DiscrepancyContext from "./DiscrepancyContext.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as ValidationConfigV2 from "./guardrails/ValidationConfigV2.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as AutoFillCasingDetail from "./AutoFillCasingDetail.mjs";
import * as AutoFillInputCreateItem from "./AutoFillInputCreateItem.mjs";

function getId(item) {
  return item.id;
}

function messageToString(msg) {
  return msg;
}

var EventAutofillInput = AutofillInputNew.Make({
      getId: getId,
      messageToString: messageToString
    });

function EventAutofill(Props) {
  var events = Props.events;
  var selectedEventsOpt = Props.selectedEvents;
  var onSelectSuccess = Props.onSelectSuccess;
  var renderButton = Props.renderButton;
  var currentFilters = Props.currentFilters;
  var eventOrigin = Props.eventOrigin;
  var selectActions = Props.selectActions;
  var selectedEvents = selectedEventsOpt !== undefined ? selectedEventsOpt : [];
  var model = ModelStore.useModel(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var addToast = Toast.useAddToast(undefined);
  var match = React.useContext(DiscrepancyContext.context);
  var expectedEventCase = match.expectedEventCase;
  var config = match.config;
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var globalSend = GlobalSendContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var hasBasicNameCasing = ValidationConfigV2.EventsV2.hasBasicNameCasing(config.events);
  return React.createElement(EventAutofillInput.make, {
              onSelect: (function (item, param, param$1) {
                  return Curry.app(sendActions, [
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              (function (branch) {
                                  return Curry._3(onSelectSuccess, item.id, item.name, branch);
                                }),
                              undefined,
                              Curry._1(selectActions, item.id)
                            ]);
                }),
              onCreate: (function (name, _var) {
                  if (!hasBasicNameCasing) {
                    return Curry._1(globalSend, {
                                TAG: /* OpenModal */4,
                                _0: {
                                  NAME: "NewEvent",
                                  VAL: [
                                    name,
                                    undefined,
                                    Curry.__3(onSelectSuccess),
                                    selectActions,
                                    "MetricDetails"
                                  ]
                                }
                              });
                  }
                  var name$1 = NameUtils.getCorrectCase(expectedEventCase, name);
                  var eventId = Shortid();
                  return NewEventModal.createEvent(eventId, name$1, Case.toAnalytics(expectedEventCase), true, workspace, model, sendActions, addToast, currentFilters, undefined, (function (branch) {
                                return Curry._3(onSelectSuccess, eventId, name$1, branch);
                              }), selectActions, schemaBundle, eventOrigin, config, undefined, undefined, undefined);
                }),
              placeholder: "Add event...",
              search: (function (query) {
                  var availableEvents = Belt_List.keep(events, (function ($$event) {
                          return !Belt_Array.some(selectedEvents, (function (selectedEventId) {
                                        return selectedEventId === $$event.id;
                                      }));
                        }));
                  var results = Belt_Array.map(Fuzzaldrin.filter(Belt_Array.map(Belt_List.toArray(availableEvents), (function ($$event) {
                                  return {
                                          id: $$event.id,
                                          name: $$event.name
                                        };
                                })), query, {
                            key: "name",
                            maxResults: 100
                          }), (function ($$event) {
                          return {
                                  NAME: "Item",
                                  VAL: $$event
                                };
                        }));
                  var nameToCreate = Case.to_(expectedEventCase, query);
                  var canCreate = !Belt_List.some(events, (function ($$event) {
                          return Case.to_("Camel", $$event.name) === Case.to_("Camel", query);
                        })) && Case.to_("Camel", query) !== "" && NewEventModal.isValid(nameToCreate, expectedEventCase, events);
                  return [[
                            undefined,
                            Belt_Array.concat(results, canCreate ? [{
                                      NAME: "CreateNew",
                                      VAL: query
                                    }] : [])
                          ]];
                }),
              renderItem: (function (item, isActive) {
                  var variant = item.NAME;
                  if (variant === "CreateNew") {
                    return React.createElement(AutoFillInputCreateItem.make, {
                                name: item.VAL,
                                autoCorrect: hasBasicNameCasing,
                                correctCasing: expectedEventCase,
                                isActive: isActive
                              });
                  } else if (variant === "CreateNewWithGrouping") {
                    return null;
                  } else if (variant === "Item") {
                    return React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.padding(Css.px(15)),
                                      tl: {
                                        hd: Css.backgroundColor(isActive ? Styles.Color.light01 : Styles.Color.white),
                                        tl: {
                                          hd: Css.hover({
                                                hd: Css.backgroundColor(Styles.Color.light02),
                                                tl: /* [] */0
                                              }),
                                          tl: {
                                            hd: Css.color(Styles.Color.light11),
                                            tl: {
                                              hd: Css.borderTop(Css.px(1), "solid", Styles.Color.light04),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    })
                              }, React.createElement($$Text.make, {
                                    size: "Small",
                                    weight: "Semi",
                                    children: item.VAL.name
                                  }));
                  } else {
                    return React.createElement("div", undefined, item.VAL);
                  }
                }),
              renderDetails: (function (x) {
                  var variant = x.NAME;
                  if (variant === "CreateNew") {
                    var name = x.VAL;
                    if (hasBasicNameCasing) {
                      if (NameUtils.isCorrectCase(expectedEventCase, name)) {
                        return null;
                      } else {
                        return React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.padding(Css.px(16)),
                                          tl: /* [] */0
                                        })
                                  }, React.createElement(AutoFillCasingDetail.make, {
                                        autoCorrect: true,
                                        toggleAutoCorrect: (function (param) {
                                            
                                          }),
                                        canDisableAutoCorrect: false,
                                        expectedCase: expectedEventCase,
                                        name: name,
                                        kind: /* Event */1
                                      }));
                      }
                    } else {
                      return React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.padding(Css.px(16)),
                                        tl: /* [] */0
                                      })
                                }, React.createElement($$Text.make, {
                                      size: "Medium",
                                      weight: "Semi",
                                      color: Styles.Color.light11,
                                      children: "\"" + name + "\" not found"
                                    }), React.createElement(Spacer.make, {
                                      height: 8
                                    }), React.createElement($$Text.make, {
                                      size: "Small",
                                      color: Styles.Color.light11,
                                      children: "naming convention: \"" + ValidationConfigV2.EventsV2.getNamesAsStructuredString(config.events) + "\""
                                    }));
                    }
                  }
                  if (variant !== "Item") {
                    return null;
                  }
                  var $$event = x.VAL;
                  var maybeEvent = Belt_List.getBy(events, (function (item) {
                          return $$event.id === item.id;
                        }));
                  var tmp;
                  if (maybeEvent !== undefined) {
                    var sources = maybeEvent.includeSources;
                    tmp = React.createElement("div", undefined, React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.fontWeight(Styles.FontWeight.semi),
                                    tl: {
                                      hd: Css.fontSize(Styles.FontSize.regular),
                                      tl: /* [] */0
                                    }
                                  })
                            }, maybeEvent.name), React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.fontSize(Styles.FontSize.small),
                                    tl: {
                                      hd: Css.padding2(Css.px(10), Css.px(0)),
                                      tl: /* [] */0
                                    }
                                  })
                            }, maybeEvent.description), sources ? React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.fontWeight(Styles.FontWeight.semi),
                                      tl: {
                                        hd: Css.fontSize(Styles.FontSize.small),
                                        tl: {
                                          hd: Css.wordWrap("breakWord"),
                                          tl: /* [] */0
                                        }
                                      }
                                    })
                              }, "Sent from " + Belt_Array.map(Belt_Array.keepMap(Belt_List.toArray(sources), (function (source) {
                                            return ModelUtils.getSourceById(source.id, model);
                                          })), AvoConfig.getSourceName).join(", ")) : React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.fontWeight(Styles.FontWeight.semi),
                                      tl: {
                                        hd: Css.color(Styles.Color.red),
                                        tl: {
                                          hd: Css.fontSize(Styles.FontSize.small),
                                          tl: /* [] */0
                                        }
                                      }
                                    })
                              }, "Not sent from any source"));
                  } else {
                    tmp = React.createElement("div", undefined, $$event.name);
                  }
                  return React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.padding(Css.px(15)),
                                    tl: {
                                      hd: Css.overflow("auto"),
                                      tl: /* [] */0
                                    }
                                  })
                            }, tmp);
                }),
              refreshKey: [
                events,
                selectedEvents
              ],
              renderButton: renderButton,
              resultsContainerStyles: {
                hd: Css.left(Css.px(0)),
                tl: {
                  hd: Css.width("auto"),
                  tl: {
                    hd: Css.right(Css.px(0)),
                    tl: /* [] */0
                  }
                }
              }
            });
}

var make = EventAutofill;

export {
  EventAutofillInput ,
  make ,
  
}
/* EventAutofillInput Not a pure module */
