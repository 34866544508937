// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function BranchActionBox__Components$Container(Props) {
  var children = Props.children;
  return React.createElement("div", undefined, children);
}

var Container = {
  make: BranchActionBox__Components$Container
};

function BranchActionBox__Components$MainContent(Props) {
  var children = Props.children;
  return React.createElement("div", undefined, children);
}

var MainContent = {
  make: BranchActionBox__Components$MainContent
};

function BranchActionBox__Components$MergeButtonSection(Props) {
  var children = Props.children;
  return React.createElement("div", undefined, children);
}

var MergeButtonSection = {
  make: BranchActionBox__Components$MergeButtonSection
};

function BranchActionBox__Components$ReviewersSection(Props) {
  var children = Props.children;
  return React.createElement("div", undefined, children);
}

var ReviewersSection = {
  make: BranchActionBox__Components$ReviewersSection
};

export {
  Container ,
  MainContent ,
  MergeButtonSection ,
  ReviewersSection ,
  
}
/* react Not a pure module */
